import React, { useState,useEffect } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib;
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,fmtMSS,fmtHMSS, currentDateTime,getDateTimeFromISODT} from '../../../lib/functions';

let format = commaNumber.bindWith(',', '.');

const EmpEfficiency = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    // Invoice body
        
          // ChartJS.register(
          //   CategoryScale,
          //   LinearScale,
          //   PointElement,
          //   LineElement,
          //   Title,
          //   Tooltip,
          //   Legend
          // );

        const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
        const [selectedOption, setSelectedOption] = useState('secs');
        let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
        {label:'Night Shift',value:'night_shift'}]);
        let [shift_id, shift_id_set] = useState('');
        let [divFactor, setDivFactor] = useState(1.0);
        let [selectedShift,selectedShiftSet] = useState(null)
        let [invoiceNo, setInvoiceNo] = useState('');
        let [row, rowSet] = useState([]);
        let [totalTimeWorking, setTotalTimeWorking] = useState([])
        let [actualQty, setActualQty] = useState([])
        let [shortQty,setShortQty] = useState([])
        let [targetQty, setTargetQty] = useState([])
        let [selectedEmployee,selectedEmployeeSet] = useState(null)
        let [employees,employeesSet] = useState([]);
        let [machines, machinesSet] = useState([]);
        let [products, setProducts] = useState([]);
        let [selectedProduct, setSelectedProduct] = useState([]);
        let [steps,setSteps] = useState([]);
        let [selectedStep, setSelectedStep] = useState([])
        let [cycleTime, setCycleTime] = useState([])
        let [idealTime, setIdealTime] = useState([]);
        let [timeFactor, setTimeFactor] = useState(1.0);  
        let [saveAction, saveActionSet] = useState(false);
        let [result, setResult] = useState([]);
        let [no_of_days, set_no_of_days] = useState(0);
        let [total_scrap, total_scrap_set] = useState(0);
    
        let monthOptions = ['Janurary','Feburary','March','April','May','June','July','August','September','October','November','December'];
        let [selectedMonth, setSelectedMonth] = useState(monthOptions[new Date().getMonth()]);
        const [keyValuePairs, setKeyValuePairs] = useState([]);
        const [keyValuePairsCat, setKeyValuePairsCat] = useState([]);
         const machineMapHrs = new Map();
         const machineCatMap = new Map();
        // const [chartData, setChartData] = useState({
        //   labels: ['A','B','C','D','E'],
        //   datasets: [
        //     {
        //       label: "Users Gained ",
        //       // data: Data.map((data) => data.userGain),
        //       data: ['1', '2', '3','4','5'],
        //       backgroundColor: [
        //         "rgba(75,192,192,1)",
               
        //       ],
        //       borderColor: "black",
        //       borderWidth: 2
        //     }
        //   ]
        // });

        const currYear = new Date().getFullYear();
          const daysInMonth = async () => {
          if(selectedMonth === 'Janurary'){
           set_no_of_days(new Date(currYear, 1, 0).getDate());
          }else if(selectedMonth === 'Feburary'){
            set_no_of_days(new Date(currYear, 2, 0).getDate());
          }else if(selectedMonth === 'March'){
            set_no_of_days(new Date(currYear, 3, 0).getDate());
          }else if(selectedMonth === 'April'){
             set_no_of_days(new Date(currYear, 4, 0).getDate());
          }else if(selectedMonth === 'May'){
            set_no_of_days(new Date(currYear, 5, 0).getDate());
          }else if(selectedMonth === 'June'){
            set_no_of_days(new Date(currYear, 6, 0).getDate());
          }else if(selectedMonth === 'July'){
             set_no_of_days(new Date(currYear, 7, 0).getDate());
          }else if(selectedMonth === 'August'){
            set_no_of_days(new Date(currYear, 8, 0).getDate());
          }else if(selectedMonth === 'September'){
            set_no_of_days(new Date(currYear, 9, 0).getDate());
          }else if(selectedMonth === 'October'){
             set_no_of_days(new Date(currYear, 10, 0).getDate());
          }else if(selectedMonth === 'November'){
            set_no_of_days(new Date(currYear, 11, 0).getDate());
          }else if(selectedMonth === 'December'){
            set_no_of_days(new Date(currYear, 12, 0).getDate());
           }
       }

      
       useEffect(()=>{
           daysInMonth();
           console.log(no_of_days, 'No of days in month');
      
          getDailyProdEntryInvoice();
        },[selectedMonth])
      
       

       
       const getDailyProdEntryInvoice = async () => {
        await axios.post(`${API_URL}/api/get-emp-efficiency-monthly`,{selectedMonth,selectedEmployee}, {headers:{'auth-token':authInfo.token}}).then(res=>{
            console.log(res.data.message,'emp eff');
            res.data.message.sort((a,b) => (a.employee_name > b.employee_name) ? 1 : ((b.employee_name > a.employee_name) ? -1 : 0))
            setResult(res.data.message);
            
        })
      }

       useEffect(()=>{
        getDailyProdEntryInvoice();
        getEmployees();
       },[selectedEmployee])
           
         /// Methods 
       

       

        const getEmployees = async ()=>{
            await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
              employeesSet(res.data.message);
              console.log(employees,'table page');
          })
        }


          useEffect(()=>{
              getEmployees();
            
               daysInMonth();
             
                // if(products.length>0) getMachineCycleTimeIDList();
          },[])     
       
        

      


      return( 
        <div className={classes.root}>
        <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                marginBottom: '2px'}}>Employee Efficiency Monthly</h4>
         <Grid container spacing={3} > 
                   <Grid item xs={2} />
                    <Grid item  xs={6}  sm={3} style={{marginBottom: '-9px'}} > 
                            <Autocomplete
                            style={{ width: '100%' }}
                            options={monthOptions} 
                            size="small"
                            classes={{
                                option: classes.option,
                            }}
                            value={selectedMonth}
                            openOnFocus={true}
                            autoHighlight
                            getOptionLabel={(option) => (option?option:'')}
                            //  getOptionSelected={(option, value) => {
                            //     return option.id === value.id;
                            // }}
                            onChange={(event,selectedObj)=>{
                               setSelectedMonth(selectedObj)
                            }}
                            loading={monthOptions.length==0?true:false}
                            renderInput={(params) => (
                                <TextField
                                
                                {...params}
                                label="Select Month"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {monthOptions.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    )
                                }}
                            />
                            )}
                            />
                    </Grid>
                    <Grid item  xs={6}  sm={3} style={{marginBottom: '-9px'}} > 
                            <Autocomplete
                            style={{ width: '100%' }}
                            options={employees} 
                            size="small"
                            classes={{
                                option: classes.option,
                            }}
                            value={selectedEmployee}
                            openOnFocus={true}
                            autoHighlight
                            getOptionLabel={(option) => (option.employee_name?option.employee_name:'')}
                            //  getOptionSelected={(option, value) => {
                            //     return option.id === value.id;
                            // }}
                            onChange={(event,selectedObj)=>{
                               selectedEmployeeSet(selectedObj)
                            }}
                            loading={employees.length==0?true:false}
                            renderInput={(params) => (
                                <TextField
                                
                                {...params}
                                label="Select Employee"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {employees.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    )
                                }}
                            />
                            )}
                            />
                    </Grid>
                    {/* <Grid item  xs={12}   sm={3} > 
                      <Select
                      value={selectedShift}
                      onChange={(shift)=>{
                          if(shift == null){
                              return false;
                          }
                          selectedShiftSet(shift);
                          shift_id_set(shift.value)
                      }}
                      options={shifts}

                      />
                    </Grid> */}
        </Grid>
        
        </Paper>

      <Grid container spacing={3} >
        
   

        <Grid item xs={1} />

         <Grid item xs={12} sm={12} >
         <Paper className={classes.paper}> 
           
              <TableContainer style={{ maxWidth: 1270, maxHeight: 650}}>
                {/**mct table starts */}
                <Table stickyHeader className={classes.table} style={{borderCollapse:'separate', borderSpacing:"20px 27px" }}  size="small" aria-label="a dense table">
                
                <TableHead >
                  <TableRow className={classes.tableRightBorder} >
                    <TableCell  align="right"  className={classes.tableRightBorder}>SL</TableCell>
             
                    <TableCell  align="right"  className={classes.tableRightBorder} style={{ zIndex: 900 }} >Employee</TableCell>
                    <TableCell  align="right"  className={classes.tableRightBorder} > Shift </TableCell>
                    {
                       [...Array(no_of_days)].map((e, i) => <TableCell align="right" fixed="null"  className={classes.tableRightBorder}> {i+1} </TableCell>)
                    }
                     <TableCell  align="right"  className={classes.tableRightBorder} > Total </TableCell>
                    
                     <TableCell  align="right"  className={classes.tableRightBorder} >Average Employee Efficiency </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {!result || result.length  == 0 && (
                  <h3>No Records!!!</h3>
                )}
              
                {
                  
                  result.map((data,idx)=>{
                    return (
                       data.employee_id != 0  && (
                       <>
                       
                      <TableRow key={idx}  className={classes.tableRightBorder} style={{ background: 'white' }}>
                           
                          <TableCell rowSpan={2} colSpan={1}  style={{textAlign:'center'}}  className={classes.tableRightBorder}>{idx+parseFloat(1)}</TableCell>
                        
                          <TableCell rowSpan={2} colSpan={1} style={{textAlign:'center',position: 'sticky',
                        left: 0,
                        background: 'white',
                        zIndex: 800,}}  className={classes.tableRightBorder}>{data.employee_name}</TableCell>
                          <TableCell rowspan={1} colspan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>Day</TableCell>
                          {
                            data.day_shift_hrs.map((hrs,itr)=>{
                              return (<TableCell  rowspan={1} colspan={1} align="center"  className={classes.tableRightBorder}> {parseFloat(hrs).toFixed(2)} % </TableCell>)
                            })
                          }
                          
                         
                         
                          { data.no_of_days_present ? <TableCell rowspan={2} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>{(parseFloat(data.shift_total)/parseFloat(data.no_of_days_present)).toFixed(2)}  % </TableCell> : 
                          <TableCell rowspan={2} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>{(parseFloat(data.shift_total)).toFixed(2)}  % </TableCell>}
                      </TableRow>

                      <TableRow  className={classes.tableRightBorder}>
                      <TableCell rowspan={1} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>Night</TableCell>
                        {
                            data.night_shift_hrs.map((hrs,itr)=>{
                              return (<TableCell  rowspan={1} colSpan={1} align="center" className={classes.tableRightBorder}> {parseFloat(hrs).toFixed(2)} % </TableCell>)
                            })
                          }
                      </TableRow>
                      
                      </>
                      ) 


                    )
                    })
                }   
                </TableBody>
                </Table>
                </TableContainer>          
        </Paper>
        </Grid>

        <Grid item xs ={1} />

       

        {/* graphs for above */}
        <Grid>
            {/* <Line
            data={chartData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Users Gained between 2016-2020"
                },
                legend: {
                  display: false
                }
              }
            }}
          /> */}
        </Grid>

        </Grid>
      </div>
      )
}


const useStyles = makeStyles((theme) => ({
  
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: "4px",
    textAlign:"left",
    
},

   
  }));
  const mapStateToPops = (state)=>{
    return {
      
      authInfo:state.authInfoReducer
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(EmpEfficiency);