import React, { useState,useEffect } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib;
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker
} from '@material-ui/pickers';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,fmtMSS,fmtHMSS,currentDateTime,getDateTimeFromISODT} from '../../../lib/functions'

let format = commaNumber.bindWith(',', '.')
const DailyProductionEntryInvoice = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    // Invoice body
        
        const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
        const [selectedOption, setSelectedOption] = useState('secs');
        let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
        {label:'Night Shift',value:'night_shift'}]);
        let [shift_id, shift_id_set] = useState('');
        let [divFactor, setDivFactor] = useState(1.0);
        let [selectedShift,selectedShiftSet] = useState(null)
        let [invoiceNo, setInvoiceNo] = useState('');
        let [row, rowSet] = useState([]);
        let [totalTimeWorking, setTotalTimeWorking] = useState([])
        let [actualQty, setActualQty] = useState([])
        let [shortQty,setShortQty] = useState([])
        let [targetQty, setTargetQty] = useState([])
        let [selectedEmployee,selectedEmployeeSet] = useState([])
        let [employees,employeesSet] = useState([]);
        let [machines, machinesSet] = useState([]);
        let [products, setProducts] = useState([]);
        let [selectedProduct, setSelectedProduct] = useState([]);
        let [steps,setSteps] = useState([]);
        let [selectedStep, setSelectedStep] = useState([])
        let [cycleTime, setCycleTime] = useState([])
        let [idealTime, setIdealTime] = useState([]);
        let [timeFactor, setTimeFactor] = useState(1.0);  
        let [saveAction, saveActionSet] = useState(false);
        let [result, setResult] = useState([]);
        let slCounter=1; 
         /// Methods 
        const getInvoice = async ()=>{ 
            await axios.get(`${API_URL}/api/get-daily-mct-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            setInvoiceNo(res.data.message)
          })
        }

        const handleChange = (e) => {
          let prevValue = selectedOption;
          if(e.target.value === 'mins' && prevValue === 'secs')
          {
            setDivFactor(60.0);
            setTimeFactor(60.0);
          }else if(e.target.value === 'secs' && prevValue === 'mins'){
            setDivFactor(1.0);
            setTimeFactor(1/60.0);
          } 
           setSelectedOption(e.target.value);
        } 

        const getEmployees = async ()=>{
            await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
              employeesSet(res.data.message);
              console.log(employees,'table page');
          })
        }

        const getMachines = async ()=>{
          await axios.get(`${API_URL}/api/get-all-machine-names-production`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            machinesSet(res.data.message)
          })
        }

      const getProducts = async ()=>{
        await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setProducts(res.data.message);
              // ( async()=>{
              //   await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:res.data.message ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
              //     setCycleTime(res.data);
              //     console.log(res.data, 'MCT in tabble');
              //   });
              // })();
  
        });

       
      }

     

      const getStepName = async ()=>{
        await axios.get(`${API_URL}/api/get-step-names`,{headers:{'auth-token':authInfo.token}}).then(res=>{
          setSteps(res.data.message);
        })
      }

      const getMachineCycleTimeIDList = async()=>{
        await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:selectedProduct ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setCycleTime(res.data);
          console.log(res.data, 'MCT in tabble');
        });
      }
       
      const calculateTime =  () => {
        console.log(selectedProduct, selectedStep);
        let timeCopy = [...idealTime];
        machines.map((data, idx)=>{
            let found = 0;
            selectedStep[idx] && selectedProduct[idx] && cycleTime[idx] != null && cycleTime[idx].cycleTime  && cycleTime[idx].cycleTime.length>0 && 
            cycleTime[idx].cycleTime[0].data.length>0 && (
              
               cycleTime[idx].cycleTime[0].data.map((ele,index)=>{
                if(ele.step_name_id === selectedStep[idx].step_name_id && cycleTime[idx].prod_id === selectedProduct[idx].prod_id){
                  
                    console.log(idx, selectedProduct[idx],selectedStep[idx], found);
                     found = 1;
                    
                     
                      timeCopy[idx] = ( (parseFloat(ele.load_time) + parseFloat(ele.unload_time) + parseFloat(ele.process_time)) / (  parseFloat(divFactor)) ).toFixed(3)
                     
                      
                }else{
                  if(found === 0){
                    
                    timeCopy[idx] = 0
                    
                  }
                }
                
              })
               
            )
            found = 0; 
            
        });
        setIdealTime(timeCopy)
      }
      useEffect( ()=>{
        if(selectedProduct.length!=0)
         getMachineCycleTimeIDList();
      },[selectedProduct, selectedStep])

      // useEffect(()=>{
      //   console.log(idealTime, 'separate');
      //   setIdealTime(timeCopy);
      // },[timeCopy]) 


      useEffect( ()=>{
         calculateTime();
         console.log(idealTime);
        let newArr = totalTimeWorking.map((data, idx)=>{
          return data/timeFactor;
        })
        setTotalTimeWorking(newArr)
        
      },[selectedProduct,selectedStep,selectedOption]); 
      
      
          
      useEffect(()=>{
        console.log("Updated state", idealTime);
      },[idealTime, selectedOption])
          
          useEffect(()=>{
            console.log(selectedEmployee);
            console.log(totalTimeWorking);
            console.log(targetQty);
            console.log(actualQty);
            console.log(shortQty);
            console.log(idealTime,"common")
          },[selectedEmployee, totalTimeWorking, targetQty,actualQty,shortQty,idealTime])

          useEffect(()=>{       
            
          },[divFactor]);

          useEffect(()=>{
              getEmployees();
               getMachines();
               getProducts();
                getStepName();
                getInvoice();
               
                // if(products.length>0) getMachineCycleTimeIDList();
          },[])     
       
      const getDailyProdEntryInvoice = async () => {
        await axios.post(`${API_URL}/api/get-daily-production-entry`,{selectedShift: shift_id, selectedDate}, {headers:{'auth-token':authInfo.token}}).then(res=>{
            console.log(res.data.message,'invoice');
            setResult(res.data.message);
        })
      }

      useEffect(()=>{
        if(selectedShift)
        getDailyProdEntryInvoice();
      },[selectedDate, shift_id]);

      const removeRow = async (rowId) => {
         swal({title:'Are you sure want to delete this record.',icon:'warning'}).then(async (confirm)=>{
            if(confirm){
               await axios.post(`${API_URL}/api/delete-daily-production-record`,{rowId}, {headers:{'auth-token':authInfo.token}}).then(res=>{
                  if(res.error){
                    swal({title:'Unable to do the action...', icon:'warning'});
                  }else{
                    swal({title:'Successfully deleted', icon:'success'});
                     window.location.reload();
                  }
               })
            }else{
              swal({title:'Unable to do the action...', icon:'warning'});
            }
         })
      }


      return( 
        <div className={classes.root}>
        <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                marginBottom: '2px'}}>Daily Production Entry Invoice</h4>
         <Grid container spacing={3} > 
                 
                    <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
                        <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
                        <KeyboardDatePicker
                        style={{ width: '100%',marginTop: '' }}
                        value={selectedDate}
                        onChange={handleDateChangeSet}
                        label="Select Date"
                        format="yyyy/MM/dd"
                        />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item  xs={12}   sm={3} > 
                      <Select
                      value={selectedShift}
                      onChange={(shift)=>{
                          if(shift == null){
                              return false;
                          }
                          selectedShiftSet(shift);
                          shift_id_set(shift.value)
                      }}
                      options={shifts}

                      />
                    </Grid>
        </Grid>
        </Paper>

      <Grid container spacing={3} >
         <Grid item xs={12} sm={12} >
         <Paper className={classes.paper}> 
            {/* <Grid item xs={12} sm={12}>
                      <p>Time in 
                         <label>
                       <input type="radio" value="mins" checked={selectedOption === 'mins'} onChange={handleChange} />
                          Minutes
                        </label>
                        <label>
                        <input type="radio" value="secs" checked={selectedOption === 'secs'} onChange={handleChange} />
                          Seconds
                        </label>
                        </p>
            </Grid>  */}

              <TableContainer >
                {/**mct table starts */}
                <Table className={classes.table} style={{borderCollapse:'separate', borderSpacing:"0px 14px"}} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">SL</TableCell>
                    <TableCell align="center">Category</TableCell>
                    <TableCell align="center">Machine</TableCell>
                    <TableCell align="center">Operator Name</TableCell>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Operation</TableCell>
                    <TableCell align="center">Cycle Time</TableCell>
                    
                    <TableCell align="center">Total Time</TableCell>
                    <TableCell align="center">Target Qty</TableCell>
                    <TableCell align="center">Actual Qty</TableCell>
                    <TableCell align="center">Short Qty</TableCell>
                    <TableCell align="center">Emp Efficiency</TableCell>
                    {/* <TableCell align="center">Edit</TableCell> */}
                    <TableCell align="center">Remove</TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
                {!result || result.length  == 0 && (
                  <h3>No Records!!!</h3>
                )}
              
                {
               
                  result.map((data,idx)=>{
                    
                       if(data.daily_prod_product_id != 0)  {
                     return ( <TableRow key={idx}>
                          
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{slCounter++}</TableCell>
                          <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center', padding:"4px"}}>{data.machine_cat_name}</TableCell>
                          <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center', padding:"4px"}}>{data.machine_names_name}</TableCell>
                          {/* emp select */}
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                                  {data.employee_name}
                          </TableCell>

                          {/* product select */}
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                                  {data.prod_name}
                        </TableCell>

                        {/* step select */}
                        <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                               {data.step_name_names}              
                        </TableCell>

                        {/* ideal cycle time*/}  
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                          { fmtHMSS((data.daily_prod_cycle_time).toString()) }
                      </TableCell>  

                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                      { fmtHMSS((data.daily_prod_total_time).toString()) } 
                    </TableCell>

                    <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                          {data.daily_prod_target_qty}
                      </TableCell>
                                            
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                        {data.daily_prod_actual_qty}
                      </TableCell>

                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                         {data.daily_prod_short_qty}
                      </TableCell>
                      
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                        {data.daily_prod_emp_efficiency} %
                      </TableCell>

                      <TableCell align="right" >
                      <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{removeRow(data.dmct_id)}}>
                      </ RemoveCircleIcon></TableCell>

                      {/* <TableCell align="right">
                      <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>editRow(props.rowData[0],props.rowIndex)}/>
                      </TableCell> */}
                     
                      </TableRow>
                  )
                    }
                      return "";
                    
                    })
                }
                


                  
                </TableBody>
                </Table>
                </TableContainer>
                {/* <Button 
                onClick={saveEntry}
                style={{marginTop: '5px',marginLeft: 'auto',fontSize:'12px'}} 
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled = {saveAction ? true: false}
                      className={classes.button}
                      startIcon={<SaveIcon/>}
                  >
                  Save Entry
              </Button> */}
        </Paper>
        </Grid>
        </Grid>
      </div>
      )
}


const useStyles = makeStyles((theme) => ({
    
   
  }));
  const mapStateToPops = (state)=>{
    return {
      
      authInfo:state.authInfoReducer
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(DailyProductionEntryInvoice);