import React, { useState,useEffect } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import TableGenerator from './TableGenerator';
import { fmtHMSS } from '../../../lib/functions';
let format = commaNumber.bindWith(',', '.')
const InvoiceBody = ({purchaseData, authInfo})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(purchase)
         let [purchase,purchaseSet] = useState([])
          let [materials,materialsSet] = useState([])
          let [products,productsSet] = useState([]);
          let [cycleTimeList, cycleTimeListSet] = useState([]);
          let [row, rowSet] = useState([]);
          

          const getMachineCycleTimeIDList = async()=>{
            console.log(purchaseData.products);
            if(purchaseData.products!=null && purchaseData.products.length>0)
            await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:purchaseData.products,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
              cycleTimeListSet(res.data);
              console.log(res.data, 'MCT in front');
            });
            
          }

        useEffect( ()=>{
            purchaseSet(purchaseData)
            if(purchaseData.materials!=undefined && purchaseData.products!=undefined){
              materialsSet(purchaseData.materials)
              productsSet(purchaseData.products)
            }
             getMachineCycleTimeIDList();
            },[purchaseData]);

            useEffect(()=>{
              let rows = cycleTimeList.map((obj,id)=>{
                let sum = 0;
                obj.cycleTime.map((mct,indx)=>{
                 sum = sum + mct.data.length;
                });
                // console.log(sum);
                return sum;
                
          }); 
          rowSet(rows);
            },[cycleTimeList])

           
         

      return(
          <>
       
           
        

        
       

        {/**mct table starts */}
        <table  className={'invoice-table'}  > 
        <thead>
                <td> SL </td>
                <td>Product</td>
                <td>Product Qty</td>
                <td>MCT</td>
                <td>Step No.</td>
                <td>Machine</td>
                <td>Step</td>
                <td>Ideal Load Time</td>
                <td>Ideal UnLoad Time</td>
                <td>Ideal Process Time</td>
                <td>Ideal Total Time</td>    
        </thead>
        <tbody>
         


          {
            cycleTimeList.map((detail,ind) =>(
                   
              

                   
                        detail.cycleTime.map((mct,id) =>(


                          id === 0 ? (
                            <>

                            <tr >
                              <td  rowSpan={ row[ind]}>{ind+parseFloat(1)}</td>
                     <td  rowSpan={row[ind]} style={{textAlign:'center'}}>{detail.prod_name}</td>
                     <td  rowSpan={row[ind]} style={{textAlign:'center'}}>{detail.prod_qty}</td>
                               {
                              mct.data.map((data, idx)=>{
                              return (
                               idx === 0 ?  (
                                <>
                                   <td  rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{id+parseFloat(1)}</td>
                                   <td colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</td>
                                   <td colSpan={1} style={{textAlign:'center'}}>{data.machine_cat_name}</td>
                                   <td colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</td>
                                   <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_load_time)*parseFloat(detail.prod_qty) ).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_unload_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_process_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                                </>

                               ) : (
                              //   <tr>
                              //   <td rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</td>
                              //     <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.machine_names_name}</td>
                              //     <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</td>
                              //  </tr>
                              <></>
                               )
                            
                                
                                 
                              )})
                            }
                            </tr>
                            { mct.data.map((data, idx)=>{
                              return (
                               idx === 0 ?  (
                                <>    
                                </>
                               ) : (
                                <tr>
                                <td rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</td>
                                  <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.machine_cat_name}</td>
                                  <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</td>
                               </tr>
                              
                               )   
                              )})}
                              </>
                          ) : (
                        <>
                           <tr >
                           
                            
                             {/* <td  rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{id+parseFloat(1)}</td> */}
                           
                             {/* <tr  style={{textAlign:'center'}}> */}
                           
                            {
                              mct.data.map((data, idx)=>{
                              return (
                               idx === 0 ?  (
                                <>
                                   <td  rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{id+parseFloat(1)}</td>
                                   <td colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</td>
                                   <td colSpan={1} style={{textAlign:'center'}}>{data.machine_cat_name}</td>
                                   <td colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</td>
                                   <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_load_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_unload_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_process_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                            <td rowSpan={mct.data.length} colSpan={1}  style={{textAlign:'center'}}>{fmtHMSS(((parseFloat(mct.total_time)*parseFloat(detail.prod_qty)).toFixed(3)).toString())}</td>
                                </>

                               ) : (
                                <></>
                               )
                            
                                
                                 
                              )})
                            }
                          
                            {/* </tr> */}
                            </tr>
                            { mct.data.map((data, idx)=>{
                              return (
                               idx === 0 ?  (
                                <>    
                                </>
                               ) : (
                                <tr>
                                <td rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</td>
                                  <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.machine_cat_name}</td>
                                  <td rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</td>
                               </tr>
                              
                               )   
                              )})}
                              </>
                            
                          ) 
                          
                        ))
                    
              
             ))
          }
        </tbody>
        </table>
        </>
      )
}


const useStyles = makeStyles((theme) => ({
    
   
  }));
  const mapStateToPops = (state)=>{
    return {
      
      authInfo:state.authInfoReducer
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(InvoiceBody);