import moment from 'moment';
const pathSpliter = (path,index)=>{
     let divs =  path.split('/')
     return divs[index];
}
const authInfoSeter = JSON.parse(sessionStorage.getItem('auth_info'));

const accessChecker = (accessName)=>{
      let auth =  JSON.parse(sessionStorage.getItem('auth_info'));
      //console.log(auth);
      if(auth.access != undefined || auth.access.length != 0){
           let access = JSON.parse(auth.access)
           return access.indexOf(accessName);
      }else{
            return false
      }
      
}
const checkAuthBranchWare = (branchID)=>{
      if(authInfoSeter.userInfo.user_branch_id==branchID ){
            return true
      }else{
            return false
      }
}

const checkIntNum = (number)=>{
      return !Number.isInteger(parseFloat(number))?true:false
}

let getDateTimeFromISODT = (isoString)=>{
      
      var date = moment(isoString);
      var dateComponent = date.utc().format('Y-MM-DD');
      var timeComponent = date.utc().format('hh:mm');
      return dateComponent+'T'+timeComponent
      
}
const dateFormat  =  'DD MMM Y';
const dateTimeFormat  =  'DD MMM Y hh:mm zz a';
const currentDateTime = ()=>{
      let dateObj =  new Date();
      return dateObj.toISOString();
}
const currentMonthStartDay = () =>{
 return moment().startOf('month').toISOString();
}
const currentDateStartTime = ()=>{
      return moment().startOf('day').toISOString()
}
const currentDateEndTime = ()=>{
      return moment().endOf('day').toISOString()
}
function fmtMSS(s){return(s-(s%=60))/60+(9<s?':':':0')+s }
function fmtHMSS(seconds)
{ // day, h, m and s
  
  var days     = Math.floor(seconds / (24*60*60));
      seconds -= days    * (24*60*60);
  var hours    = Math.floor(seconds / (60*60));
      seconds -= hours   * (60*60);
  var minutes  = Math.floor(seconds / (60));
      seconds -= minutes * (60);
//   return ((0<days)?(days+" day, "):"")+hours+"h, "+minutes+"m and "+seconds+"s";
  return ((0<days)?(days+" day, "):"")+hours+"h:"+minutes+"m:"+seconds + "s";
}


export {pathSpliter,authInfoSeter,accessChecker,fmtMSS,fmtHMSS,checkAuthBranchWare,dateTimeFormat,dateFormat,checkIntNum,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime, currentMonthStartDay}
