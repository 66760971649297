import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat, currentDateTime} from '../../lib/functions'
import moment from 'moment';
import { Modal } from 'react-responsive-modal';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import { parse } from 'date-fns';

import {currentRouteSet,createdScrapPaySet,updatedScrapPaySet,scrapPayDisableRestoreSet} from '../../actions/actions';


const TotalScrapMonth = ({location,currentRoute,currentRouteSet,authInfo,createdScrapPaySet,updatedScrapPaySet,scrapPayDisableRestoreSet, createdScrapPay,updatedScrapPay,scrapPayDisableRestore})=>{
    const classes = useStyles();
      
        //let monthOptions = ['Janurary','Feburary','March','April','May','June','July','August','September','October','November','December'];
      
        // let [selectedMonth, setSelectedMonth] = useState(monthOptions[new Date().getMonth()]);
        const [successMsg, setSuccessMsg] = useState({
            open: false,
            vertical: 'top',
            horizontal: 'center',
            msg:''
          });
      
          const { vertical, horizontal, open,msg } = successMsg;
          const handleClose = () => {
            setSuccessMsg({ ...successMsg, open: false });
          };
        let [loadingList,loadingListSet] = useState(false)
        let [action,actionSet] = useState('create');
        const [selectedDate, handleDateChange] = useState(currentDateTime);
        let [scrap_qty, scrap_qty_set] = useState(0);
        let [scrap_rate, scrap_rate_set] = useState(0);
        let [scrap_amt_expected, scrap_amt_expected_set] = useState(0);
        let [payment_amt, payment_amt_set] = useState(0);
        let[note, note_set] = useState('');
        let [loadingSave, loadingSaveSet] = useState(false);
        let [scrap_payments, scrap_payments_set] = useState([]);
        let [scrap_details_id, scrap_details_id_set] = useState(0);

        useEffect(()=>{
            getScrapPayments();
        },[]);

        useEffect(()=>{
            console.log(createdScrapPay)
            if(createdScrapPay){
              if(checkAuthBranchWare(createdScrapPay.user_branch_id)){
                scrap_payments_set(createdScrapPay.createdRow.concat(scrap_payments));
                setSuccessMsg({...successMsg,msg:`${createdScrapPay.msg}`,open:true });
              }
             }
        },[createdScrapPay]); 

        useEffect(()=>{
          console.log(updatedScrapPay)
             
            if(updatedScrapPay){
              if(checkAuthBranchWare(updatedScrapPay.user_branch_id)){          
                scrap_payments[updatedScrapPay.index] = updatedScrapPay.updatedRow[0]
                setSuccessMsg({...successMsg,msg:`${updatedScrapPay.msg}`,open:true });
                
              }
             }
          },[updatedScrapPay]);

          useEffect(()=>{
            if(scrapPayDisableRestore){
              if(checkAuthBranchWare(scrapPayDisableRestore.user_branch_id)){
                scrap_payments[scrapPayDisableRestore.index] = scrapPayDisableRestore.disableRestoreRow[0];
              setSuccessMsg({...successMsg,msg:`${scrapPayDisableRestore.msg}`,open:true });
              }
             }
          },[scrapPayDisableRestore]);
      


        useEffect(()=>{
            scrap_amt_expected_set((parseFloat(scrap_qty)*parseFloat(scrap_rate)).toFixed(2));
        },[scrap_rate, scrap_qty]);

        const getScrapPayments = async ()=>{
            loadingListSet(true)
            await axios.post(`${API_URL}/api/get-scrap-payments`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
                loadingListSet(false)
                scrap_payments_set(res.data.message)
                console.log(res.data.message);
            })
        };

        const saveFormAction = async () => {
            let payload = {
                scrap_qty,
                scrap_rate,
                scrap_amt_expected,
                payment_amt,
                note,
                selectedDate,
                action,
                scrap_details_id,
        
            }
            loadingSaveSet(true)
            await axios.post(`${API_URL}/api/save-scrap-payment`,{payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
                loadingSaveSet(false);
                scrap_amt_expected_set(0);
                scrap_qty_set(0);
                scrap_rate_set(0);
                payment_amt_set(0);
                note_set('');
                handleDateChange(currentDateTime)
                actionSet('create')
                scrap_details_id_set(0);

                   if(!res.data.error){
                   swal({title:res.data.message,icon:'success'});
                 }else{
                  swal({title:'Network problem.'})
                }

            });
            window.location.reload();
        }

        const scrapPayEdit = (row,index)=>{
      
            scrap_amt_expected_set(scrap_payments[index].scrap_amt_expected);
            scrap_qty_set(scrap_payments[index].scrap_qty);
            scrap_rate_set(scrap_payments[index].scrap_rate);
            payment_amt_set(scrap_payments[index].payment_amt);
            note_set(scrap_payments[index].note);
            actionSet('update')
            scrap_details_id_set(scrap_payments[index].scrap_details_id)
            handleDateChange(scrap_payments[index].created_iso_dt)
            
        }

          const cusomerDisableRestore = async (supplierPayId,actionCond,index)=>{
            await axios.post(`${API_URL}/api/scrap-payment-disable-restore`,{scrap_details_id:supplierPayId,action:actionCond,index},{headers:{'auth-token':authInfo.token}}).then(res=>{
              if(!res.data.error){
                swal({title:res.data.message,icon:'success'});
              }else{
               swal({title:'Network problem.'})
              }
            })
           
           window.location.reload();
          }

        const ActionOptions = (props)=>{
            return(<div style={{textAlign:'right'}}>
                {
                  authInfo.role !='user'?(
                  <>
                  <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>scrapPayEdit(props.rowData,props.rowIndex)}/>
                    {props.rowData[1]=='a'?(
                    <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>cusomerDisableRestore(props.rowData[0],'d',props.rowIndex)}/>
                   ):(
                    <SettingsBackupRestoreIcon style={{cursor:'pointer',color: 'rgb(60, 178, 194)',fontSize: '28px',}} onClick={()=>cusomerDisableRestore(props.rowData[0],'a',props.rowIndex)}/>
                )}        
                </>):''
                }
            </div>)}
        
       const columns = [
        {name: "scrap_details_id",options: { display: 'excluded' }},
        {name: "status",options: { display: 'excluded' }},
        {name:"SL",options: {filter: false,sort: false,
            customBodyRender:(value,tableMeta)=>{
            return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
          }
          },headerStyle: {
            textAlign:'left'
          }},
          {name: "scrap_qty",label: "Scrap Qty",options: {filter: true,sort: true}},
          {name: "scrap_rate",label: "Scrap Rate",options: {filter: true,sort: true}},
          {name: "scrap_amt_expected",label: "Amt Expected",options: {filter: true,sort: true}},
          {name: "payment_amt",label: "Payment Received",options: {filter: true,sort: true}},
          {name: "created_iso_dt",label: "date time",options: {filter: true,sort: true,
            customBodyRender:(value,tableMeta)=>{
              return(
                <>{ console.log(tableMeta)}
              <p>{ moment(tableMeta.rowData[7]).format(dateTimeFormat) }</p>
              </>
              ) 
            }
          }},
          {name:"actions",options: {filter: false,sort: false,
            customBodyRender:(value,tableMeta)=>{
              return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
                 /> ); 
          }
          },headerStyle: {
            textAlign:'right'
          }}
       ];

       const options = {
        filterType: 'checkbox',
        selectableRows: 'none',
        display: "excluded"
       }
       
       


        return( 
            <div className={classes.root}>
            {/* Success message */}
              <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  {successMsg.msg}
                </Alert>
              </Snackbar>

                <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
                     <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                     marginBottom: '2px'}}>Total Scrap</h4>
                     <Grid container spacing={3} > 
                        <Grid item xs={2} />
                            <Grid item xs={12} sm={3}  > 
                               <MuiPickersUtilsProvider  utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                style={{ width: '100%',marginTop: '-8px' }}
                                value={selectedDate}
                                onChange={handleDateChange}
                                label="Entry date  time"
                                format="yyyy/MM/dd hh:mm a"
                                name = "created_iso_dt"
                                />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={3}  > 
                                <TextField type="number"  autoComplete='off'  className={classes.fullWidth}  value={scrap_qty} 
                                label="scrap_qty" name="scrap_qty" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>scrap_qty_set(e.target.value)} 
                                />
                           </Grid>
                           <Grid item xs={12} sm={3}  > 
                                <TextField type="number"  autoComplete='off'  className={classes.fullWidth}  value={scrap_rate} 
                                label="scrap_rate" name="scrap_rate" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>scrap_rate_set(e.target.value)} 
                                />
                           </Grid>
                           <Grid item xs={12} sm={3}  > 
                                <TextField type="number"  autoComplete='off'  className={classes.fullWidth}  value={scrap_amt_expected} disabled={true}
                                label="scrap_amt_expected" name="scrap_amt_expected" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>scrap_amt_expected_set(e.target.value)} 
                                />
                           </Grid>
                           <Grid item xs={12} sm={3}  > 
                                <TextField type="number"  autoComplete='off'  className={classes.fullWidth}  value={payment_amt} 
                                label="payment_amt" name="payment_amt" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>payment_amt_set(e.target.value)} 
                                />
                           </Grid>
                           <Grid item xs={12} sm={3}  > 
                                <TextField type="text"  autoComplete='off'  className={classes.fullWidth}  value={note} 
                                label="note" name="note" style={{color:'#222'}}  variant="outlined" size="small"  onChange={(e)=>note_set(e.target.value)} 
                                />
                           </Grid>
                     </Grid>
                   
                     <Grid item xs={12}>
                        <Button style={{marginTop: '25px'}}
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            disabled={loadingSave}
                            onClick={saveFormAction}
                        >
                        Save
                       </Button>
                       </Grid>
                </Paper>

           {
                loadingList==true?(<b>Loading...</b>):(
                    <Paper className={classes.paper} style={{marginTop:'20px'}}>
                    <MUIDataTable
                title={"Scrap Details List"}
                data={scrap_payments}
                columns={columns}
                options={options}
                adjustForCheckbox={false} 
                displaySelectAll={false}
                />
                </Paper>
                )
            }
            </div>
        )
}

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    root: {},
    '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageEntryLabel:{
        color: '#7754cc',
        margin: '0px',
        padding: '0px',
        marginTop: '-11px',
        textAlign: 'left',
        marginLeft: '0px',
        marginBottom: '5px',
        textAlign:'left'
    },
    fullWidth:{
        width:'100%'
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
        },
        plusLinkDiv:{
            position:'relative'  
        },
        plusLink:{
        margin: 0,
        padding: 0,
        marginTop: '-21px',
        fontSize: '29px',
        height: '21px',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        color: '#3e8d54'
        }
    }));
      
const mapStateToPops = (state)=>{
    return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer,
        createdScrapPay:state.createdScrapPayReducer,
        updatedScrapPay:state.updatedScrapPayReducer,
        scrapPayDisableRestore:state.scrapPayDisableRestoreReducer,
    }
}

export default connect(mapStateToPops,{currentRouteSet,createdScrapPaySet,updatedScrapPaySet,scrapPayDisableRestoreSet})(TotalScrapMonth);       