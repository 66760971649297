import React, { useState,useEffect, useRef } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib;
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import _ from 'lodash';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from "react-to-print";
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,fmtMSS,fmtHMSS, currentDateTime,getDateTimeFromISODT} from '../../../lib/functions'
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import { Line } from "react-chartjs-2";




let format = commaNumber.bindWith(',', '.');

const MachineEfficiency = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    // Invoice body
        
          // ChartJS.register(
          //   CategoryScale,
          //   LinearScale,
          //   PointElement,
          //   LineElement,
          //   Title,
          //   Tooltip,
          //   Legend
          // );

        const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
        const [selectedOption, setSelectedOption] = useState('secs');
        let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
        {label:'Night Shift',value:'night_shift'}]);
        let [shift_id, shift_id_set] = useState('');
        let [divFactor, setDivFactor] = useState(1.0);
        let [selectedShift,selectedShiftSet] = useState(null)
        let [invoiceNo, setInvoiceNo] = useState('');
        let [customerDue, setCustomerDue] = useState(null)
        let [supplierDue, setSupplierDue] = useState(null)
        let [row, rowSet] = useState([]);
        let [totalTimeWorking, setTotalTimeWorking] = useState([])
        let [actualQty, setActualQty] = useState([])
        let [shortQty,setShortQty] = useState([])
        let [targetQty, setTargetQty] = useState([])
        let [selectedEmployee,selectedEmployeeSet] = useState([])
        let [employees,employeesSet] = useState([]);
        let [machines, machinesSet] = useState([]);
        let [products, setProducts] = useState([]);
        let [selectedProduct, setSelectedProduct] = useState([]);
        let [steps,setSteps] = useState([]);
        let [selectedStep, setSelectedStep] = useState([])
        let [cycleTime, setCycleTime] = useState([])
        let [idealTime, setIdealTime] = useState([]);
        let [timeFactor, setTimeFactor] = useState(1.0);  
        let [saveAction, saveActionSet] = useState(false);
        let [result, setResult] = useState([]);
        let [no_of_days, set_no_of_days] = useState(0);
        let [total_scrap, total_scrap_set] = useState(0);
       let [dailyEntryStatus, setDailyEntryStatus] = useState([]);
        let monthOptions = ['Janurary','Feburary','March','April','May','June','July','August','September','October','November','December'];
        let [selectedMonth, setSelectedMonth] = useState(monthOptions[new Date().getMonth()]);
        const [keyValuePairs, setKeyValuePairs] = useState([]);
        const [keyValuePairsCat, setKeyValuePairsCat] = useState([]);
         const machineMapHrs = new Map();
         const machineCatMap = new Map();
         let [totalVal, setTotalVal] = useState({
          totalProduceVal : 0,
          totalSaleVal : 0,
          totalPurchaseVal : 0,
        });
         // const [chartData, setChartData] = useState({
        //   labels: ['A','B','C','D','E'],
        //   datasets: [
        //     {
        //       label: "Users Gained ",
        //       // data: Data.map((data) => data.userGain),
        //       data: ['1', '2', '3','4','5'],
        //       backgroundColor: [
        //         "rgba(75,192,192,1)",
               
        //       ],
        //       borderColor: "black",
        //       borderWidth: 2
        //     }
        //   ]
        // });

        const currYear = new Date().getFullYear();
          const daysInMonth = async () => {
          if(selectedMonth === 'Janurary'){
           set_no_of_days(new Date(currYear, 1, 0).getDate());
          }else if(selectedMonth === 'Feburary'){
            set_no_of_days(new Date(currYear, 2, 0).getDate());
          }else if(selectedMonth === 'March'){
            set_no_of_days(new Date(currYear, 3, 0).getDate());
          }else if(selectedMonth === 'April'){
             set_no_of_days(new Date(currYear, 4, 0).getDate());
          }else if(selectedMonth === 'May'){
            set_no_of_days(new Date(currYear, 5, 0).getDate());
          }else if(selectedMonth === 'June'){
            set_no_of_days(new Date(currYear, 6, 0).getDate());
          }else if(selectedMonth === 'July'){
             set_no_of_days(new Date(currYear, 7, 0).getDate());
          }else if(selectedMonth === 'August'){
            set_no_of_days(new Date(currYear, 8, 0).getDate());
          }else if(selectedMonth === 'September'){
            set_no_of_days(new Date(currYear, 9, 0).getDate());
          }else if(selectedMonth === 'October'){
             set_no_of_days(new Date(currYear, 10, 0).getDate());
          }else if(selectedMonth === 'November'){
            set_no_of_days(new Date(currYear, 11, 0).getDate());
          }else if(selectedMonth === 'December'){
            set_no_of_days(new Date(currYear, 12, 0).getDate());
           }
       }

       function areObjectsEqual(obj1, obj2) {
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
      
        if (keys1.length !== keys2.length) {
          return false;
        }
      
        for (const key of keys1) {
          if (obj1[key] !== obj2[key]) {
            return false;
          }
        }
      
        return true;
      }

       useEffect(()=>{
           daysInMonth();
           console.log(no_of_days, 'No of days in month');
          machineMapHrs.clear();
          setKeyValuePairs([]);
          setKeyValuePairsCat([]);
          machineCatMap.clear();
          getDailyProdEntryStatus();
      
        },[selectedMonth])
      
        const getRefObj = (key) =>{
          let existingKey = null;
          
          for (const existingKeyObj of machineCatMap.keys()) {
            if (areObjectsEqual(existingKeyObj, key)) {
              existingKey = existingKeyObj;
              break;
            }
          }
          console.log(key, existingKey)
          return existingKey;
        }

        useEffect(()=>{
          
           result.map((data, idx)=>{
              let value = {machineName: data.machine_names_name, days:((parseFloat(data.shift_total)/3600)/24).toFixed(2)};
              let key = {machineCatId: data.machine_usage_id, machineCatName: data.machine_usage_name};
              let existingKey = null;
              for (const existingKeyObj of machineMapHrs.keys()) {
                if (areObjectsEqual(existingKeyObj, key)) {
                  existingKey = existingKeyObj;
                  break;
                }
              }

              if(existingKey)
              {
                 const retrievedValue = machineMapHrs.get(existingKey);
                 let oldSum = parseFloat(machineCatMap.get(existingKey)) + parseFloat(value.days);
                 
                 const newValue = [...retrievedValue, value];

                 machineMapHrs.set(existingKey, newValue);
                 machineCatMap.set(existingKey, oldSum);
              }else{
                 const newValue = [value];
                 machineMapHrs.set(key, newValue);
                 machineCatMap.set(key, parseFloat(value.days) );
              }

           });
           console.log(machineMapHrs, machineCatMap, 'machine Map');
           setKeyValuePairs(Array.from(machineMapHrs.entries()));
           setKeyValuePairsCat(Array.from(machineCatMap.entries()));
      
         },[result])

       const getDailyProdEntryInvoice = async () => {
        await axios.post(`${API_URL}/api/daily-production-machine-efficiency`,{selectedMonth}, {headers:{'auth-token':authInfo.token}}).then(res=>{
            console.log(res.data.message,'mch eff');
            res.data.message.sort((a,b) => (a.machine_names_name > b.machine_names_name) ? 1 : ((b.machine_names_name > a.machine_names_name) ? -1 : 0))
            setResult(res.data.message);
            //console.log(result);
            
        })
      }

      const getDailyProdEntryStatus = async () => {
        await axios.post(`${API_URL}/api/daily-production-machine-entry-status`,{selectedMonth}, {headers:{'auth-token':authInfo.token}}).then(res=>{
            setDailyEntryStatus(res.data.message);
        })
      }


      
    const getCustomerDue = async () => {
      let payload = {
        customerId:   null,
        areaId:   null,
        customerType: null
        }
       await axios.post(`${API_URL}/api/get-customer-due`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
        setCustomerDue(res.data)
        })}


        const getSupplierDue=async ()=>{
                let payload = {
                  supplierId:   null,
                  supplierFor:'Purchase'
              }
              
              await axios.post(`${API_URL}/api/get-supplier-due`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
               setSupplierDue(res.data)
              })
        }
           
         /// Methods 
        const getInvoice = async ()=>{ 
            await axios.get(`${API_URL}/api/get-daily-mct-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            setInvoiceNo(res.data.message)
          })
        }

        const handleChange = (e) => {
          let prevValue = selectedOption;
          if(e.target.value === 'mins' && prevValue === 'secs')
          {
            setDivFactor(60.0);
            setTimeFactor(60.0);
          }else if(e.target.value === 'secs' && prevValue === 'mins'){
            setDivFactor(1.0);
            setTimeFactor(1/60.0);
          } 
           setSelectedOption(e.target.value);
        } 

        const getEmployees = async ()=>{
            await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
              employeesSet(res.data.message);
              console.log(employees,'table page');
          })
        }

        const getMachines = async ()=>{
          await axios.get(`${API_URL}/api/get-all-machine-names-production`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            machinesSet(res.data.message)
          })
        }

      const getProducts = async ()=>{
        await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setProducts(res.data.message);
              // ( async()=>{
              //   await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:res.data.message ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
              //     setCycleTime(res.data);
              //     console.log(res.data, 'MCT in tabble');
              //   });
              // })();
  
        });  
      }

      const getStepName = async ()=>{
        await axios.get(`${API_URL}/api/get-step-names`,{headers:{'auth-token':authInfo.token}}).then(res=>{
          setSteps(res.data.message);
        })
      }

      const getMachineCycleTimeIDList = async()=>{
        await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:selectedProduct ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setCycleTime(res.data);
          console.log(res.data, 'MCT in tabble');
        });
      }
       
      const calculateTime =  () => {
        console.log(selectedProduct, selectedStep);
        let timeCopy = [...idealTime];
        machines.map((data, idx)=>{
            let found = 0;
            selectedStep[idx] && selectedProduct[idx] && cycleTime[idx] != null && cycleTime[idx].cycleTime  && cycleTime[idx].cycleTime.length>0 && 
            cycleTime[idx].cycleTime[0].data.length>0 && (
              
               cycleTime[idx].cycleTime[0].data.map((ele,index)=>{
                if(ele.step_name_id === selectedStep[idx].step_name_id && cycleTime[idx].prod_id === selectedProduct[idx].prod_id){
                  
                    console.log(idx, selectedProduct[idx],selectedStep[idx], found);
                     found = 1;
                    
                     
                      timeCopy[idx] = ( (parseFloat(ele.load_time) + parseFloat(ele.unload_time) + parseFloat(ele.process_time)) / (  parseFloat(divFactor)) ).toFixed(3)
                     
                      
                }else{
                  if(found === 0){
                    
                    timeCopy[idx] = 0
                    
                  }
                }
                
              })
               
            )
            found = 0; 
            
        });
        setIdealTime(timeCopy)
      }
      useEffect( ()=>{
        if(selectedProduct.length!=0)
         getMachineCycleTimeIDList();
      },[selectedProduct, selectedStep])

      // useEffect(()=>{
      //   console.log(idealTime, 'separate');
      //   setIdealTime(timeCopy);
      // },[timeCopy]) 


      useEffect( ()=>{
         calculateTime();
         console.log(idealTime);
        let newArr = totalTimeWorking.map((data, idx)=>{
          return data/timeFactor;
        })
        setTotalTimeWorking(newArr)
        
      },[selectedProduct,selectedStep,selectedOption]); 
      
      
          
      useEffect(()=>{
        console.log("Updated state", idealTime);
      },[idealTime, selectedOption])
          
          useEffect(()=>{
            console.log(selectedEmployee);
            console.log(totalTimeWorking);
            console.log(targetQty);
            console.log(actualQty);
            console.log(shortQty);
            console.log(idealTime,"common")
          },[selectedEmployee, totalTimeWorking, targetQty,actualQty,shortQty,idealTime])

          useEffect(()=>{       
            
          },[divFactor]);

          useEffect(()=>{
              getEmployees();
               getMachines();
               getProducts();
                getStepName();
                getInvoice();
               daysInMonth();
               totalMonthScrap();
                totalValue();
                getCustomerDue();
                getSupplierDue();
                // if(products.length>0) getMachineCycleTimeIDList();
          },[])     
       
          const totalMonthScrap = async ()=>{
            await axios.post(`${API_URL}/api/total-scrap-report`,{selectedMonth},{headers:{'auth-token':authInfo.token}}).then(res=>{
                total_scrap_set(res.data.message);
            });
        }

        const totalValue = async () => {
          await axios.post(`${API_URL}/api/get-monthly-sales-and-production-value`, {selectedMonth},{headers:{'auth-token':authInfo.token}}).then(res=>{
            setTotalVal(res.data.message);
        });
        }
      useEffect(()=>{
       
        getDailyProdEntryInvoice();
        totalMonthScrap();
        getDailyProdEntryStatus();
        totalValue();
      },[selectedMonth]);

      

      const removeRow = async (rowId) => {
         swal({title:'Are you sure want to delete this record.',icon:'warning'}).then(async (confirm)=>{
            if(confirm){
               await axios.post(`${API_URL}/api/delete-daily-production-record`,{rowId}, {headers:{'auth-token':authInfo.token}}).then(res=>{
                  if(res.error){
                    swal({title:'Unable to do the action...', icon:'warning'});
                  }else{
                    swal({title:'Successfully deleted', icon:'success'});
                     window.location.reload();
                  }
               })
            }else{
              swal({title:'Unable to do the action...', icon:'warning'});
            }
         })
      }


      return( 
        <div className={classes.root}>
        <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                marginBottom: '2px'}}>Machine Efficiency</h4>
         <Grid container spacing={3} > 
                   <Grid item xs={2} />
                    <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
                            <Autocomplete
                            style={{ width: '100%' }}
                            options={monthOptions} 
                            size="small"
                            classes={{
                                option: classes.option,
                            }}
                            value={selectedMonth}
                            openOnFocus={true}
                            autoHighlight
                            getOptionLabel={(option) => (option?option:'')}
                            //  getOptionSelected={(option, value) => {
                            //     return option.id === value.id;
                            // }}
                            onChange={(event,selectedObj)=>{
                               setSelectedMonth(selectedObj)
                            }}
                            loading={monthOptions.length==0?true:false}
                            renderInput={(params) => (
                                <TextField
                                
                                {...params}
                                label="Select Month"
                                variant="outlined"
                                inputProps={{
                                    ...params.inputProps,
                                    endAdornment: (
                                    <React.Fragment>
                                        {monthOptions.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                    )
                                }}
                            />
                            )}
                            />
                    </Grid>
                    {/* <Grid item  xs={12}   sm={3} > 
                      <Select
                      value={selectedShift}
                      onChange={(shift)=>{
                          if(shift == null){
                              return false;
                          }
                          selectedShiftSet(shift);
                          shift_id_set(shift.value)
                      }}
                      options={shifts}

                      />
                    </Grid> */}
        </Grid>
          <Grid>
            <h3>Total Scrap(as per Production): {(parseFloat(total_scrap.produce)/1000).toFixed(2)} tonnes</h3>
            <h3>Total Scrap(as per Sales Invoice): {(parseFloat(total_scrap.sold)/1000).toFixed(2)} tonnes</h3>
            <h3>Total Production Value: {parseFloat(totalVal.totalProduceVal).toFixed(2)} </h3>
            <h3>Total Sales Value: {parseFloat(totalVal.totalSaleVal).toFixed(2)} </h3>
            <h3>Total Purchase Value: {parseFloat(totalVal.totalPurchaseVal).toFixed(2)} </h3>
            <h3>Total Credit Due Value:Rs {format(_.sumBy(customerDue,curr => Number(curr.dueAmount)).toFixed(2))} </h3>
            <h3>Total Debit Due Value:Rs {format(_.sumBy(supplierDue,curr => Number(curr.dueAmount)).toFixed(2))} </h3>
            <h3>Total Ready Material Stock In Value of Month: {(parseFloat(totalVal.totalProduceVal)-parseFloat(totalVal.totalSaleVal)).toFixed(2)} </h3>
            <h3>Total Ready Material Stock In Value of Company Till Date: {parseFloat(totalVal.totalStockInValOfCompany).toFixed(2)} </h3>
            <h3>Total Received Scrap Value(as per Data Entered): {parseFloat(total_scrap.scrapValue).toFixed(2)}</h3>
            <h3>Total Expected Scrap Value(as per Data Entered): {parseFloat(total_scrap.expectedScrapVal).toFixed(2)}</h3>
            <h3>Total Grand Value (Sales Value + Scrap Value): {(parseFloat(total_scrap.expectedScrapVal) + parseFloat(totalVal.totalSaleVal)).toFixed(2)} </h3>
          </Grid>
        </Paper>

      <Grid container spacing={3} >
        
      <Grid item xs={12} sm={12} >
          <Paper className={classes.paper}>
             <TableContainer>
             <Table  style={{borderCollapse:'separate', borderSpacing:"14px 10px" }} className={classes.table}  size="small" aria-label="a dense table">
              <TableHead >
                  <TableRow >
                  
                    <TableCell style={{textAlign:"center"}}> Category</TableCell>
                    <TableCell style={{textAlign:"center"}}> Machine </TableCell>
                     <TableCell style={{textAlign:"center"}}> Efficiency </TableCell>
                     <TableCell style={{textAlign:"left"}}>Machine Category Efficiency </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                   {keyValuePairs.length === 0 && (
                     <h2>No Records !!!</h2>
                   )}
                   {
                      keyValuePairs.map(([key,value])=>{
                      return( ( <>
                         <TableRow >
                           <TableCell style={{textAlign:"center"}} rowSpan={value.length} colSpan={1}>{key.machineCatName}</TableCell>
                           
                            {
                               value.map((data,idx)=>{
                                 return( idx === 0 ? (
                                     <>
                                        <TableCell style={{textAlign:"center"}} rowSpan={1} colSpan={1}>{data.machineName}</TableCell>
                                        <TableCell style={{textAlign:"center"}} rowSpan={1} colSpan={1}>{data.days} days</TableCell>
                                        <TableCell style={{textAlign:"left"}} rowSpan={value.length} colSpan={1} > { (parseFloat( keyValuePairsCat.find(([key2]) => key2.machineCatId === key.machineCatId)[1])/ parseInt(value.length)).toFixed(2) } days</TableCell>
                                     </>
                                    
                                  ) :
                                  (
                                    <></>
                                  )
                                 )
                               })
                            }
                         </TableRow> 
                          {
                             value.map((data,idx)=>{
                              return( idx === 0 ? (
                                  <>
                                  </>
                                
                              ) :
                              (
                                <>
                                <TableRow>
                                    <TableCell style={{textAlign:"center"}} rowSpan={1} colSpan={1}>{data.machineName}</TableCell>
                                    <TableCell style={{textAlign:"center"}} rowSpan={1} colSpan={1}>{data.days} days</TableCell>
                                </TableRow>
                                </>
                              )
                              )
                             })
                          } 
                       </>
                      )
                      );
                     })

                   }
                </TableBody>
             </Table>
             </TableContainer>
          </Paper>
        </Grid>

        <Grid item xs={1} />

         <Grid item xs={12} sm={12} >
         <Paper className={classes.paper}> 
           
              <TableContainer style={{ maxWidth: 1270, maxHeight: 650}}>
                {/**mct table starts */}
                <Table stickyHeader className={classes.table} style={{borderCollapse:'separate', borderSpacing:"20px 27px" }}  size="small" aria-label="a dense table">
                
                <TableHead >
                  <TableRow className={classes.tableRightBorder} >
                    <TableCell  align="right"  className={classes.tableRightBorder}>SL</TableCell>
                    <TableCell  align="right"  className={classes.tableRightBorder}  >Category</TableCell>
                    <TableCell  align="right"  className={classes.tableRightBorder} style={{ zIndex: 900 }} >Machine</TableCell>
                    <TableCell  align="right"  className={classes.tableRightBorder} > Shift </TableCell>
                    {
                       [...Array(no_of_days)].map((e, i) => <TableCell align="right" fixed="null"  className={classes.tableRightBorder}> {i+1}  </TableCell>)
                    }
                    
                     <TableCell  align="right"  className={classes.tableRightBorder} > Total </TableCell>
                     <TableCell  align="right"  className={classes.tableRightBorder} > Both Shift </TableCell>
                     <TableCell  align="right"  className={classes.tableRightBorder} > Machine Efficiency </TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
               <TableCell></TableCell> <TableCell></TableCell> <TableCell></TableCell> <TableCell></TableCell>
                {
                  dailyEntryStatus &&  dailyEntryStatus.total_shift_hrs && dailyEntryStatus.total_shift_hrs.map((ele, idx)=>{
                   return (
                     <TableCell>{ele == 0 ? <span style={{color:'#fff', background:'red'}}>No Data Entry</span> : <span style={{color:'#fff', background:'green'}}>Done</span>}</TableCell>
                  )})
                }

                 {!result || result.length  == 0 && (
                  <h3>No Records!!!</h3>
                )}
                {
                  result.map((data,idx)=>{
                    return (
                       data.daily_prod_product_id != 0  && (
                       <>
                       
                      <TableRow key={idx}  className={classes.tableRightBorder} style={{ background: 'white' }}>
                           
                          <TableCell rowSpan={2} colSpan={1}  style={{textAlign:'center'}}  className={classes.tableRightBorder}>{idx+parseFloat(1)}</TableCell>
                          <TableCell rowSpan={2} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>{data.machine_usage_name}</TableCell>
                          <TableCell rowSpan={2} colSpan={1} style={{textAlign:'center',position: 'sticky',
                        left: 0,
                        background: 'white',
                        zIndex: 800,}}  className={classes.tableRightBorder}>{data.machine_names_name}</TableCell>
                          <TableCell rowspan={1} colspan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>Day</TableCell>
                          {
                            data.day_shift_hrs.map((hrs,itr)=>{
                              return (<TableCell  rowspan={1} colspan={1} align="center"  className={classes.tableRightBorder}> {fmtHMSS(hrs.toString())} </TableCell>)
                            })
                          }
                          
                          <TableCell rowspan={2} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>{fmtHMSS((data.shift_total).toString())}</TableCell>
                          <TableCell rowspan={2} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>{((parseFloat(data.shift_total)/3600)/24).toFixed(2)}  days </TableCell>
                      </TableRow>

                      <TableRow  className={classes.tableRightBorder}>
                      <TableCell rowspan={1} colSpan={1} style={{textAlign:'center'}}  className={classes.tableRightBorder}>Night</TableCell>
                        {
                            data.night_shift_hrs.map((hrs,itr)=>{
                              return (<TableCell  rowspan={1} colSpan={1} align="center" className={classes.tableRightBorder}> {fmtHMSS(hrs.toString())} </TableCell>)
                            })
                          }
                      </TableRow>
                      
                      </>
                      ) 


                    )
                    })
                }   
                </TableBody>
                </Table>
                </TableContainer>          
        </Paper>
        </Grid>

        <Grid item xs ={1} />

       

        {/* graphs for above */}
        <Grid>
            {/* <Line
            data={chartData}
            options={{
              plugins: {
                title: {
                  display: true,
                  text: "Users Gained between 2016-2020"
                },
                legend: {
                  display: false
                }
              }
            }}
          /> */}
        </Grid>

        </Grid>
      </div>
      )
}


const useStyles = makeStyles((theme) => ({
  
  tableRightBorder: {
    borderWidth: 0,
    borderRightWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
    padding: "4px",
    textAlign:"left",
    
},

   
  }));



  // class ComponentToPrint extends React.Component {
   
  //   constructor(props) {
  //     super(props);  
  //   }
  //   state = {
  //     authInfo:this.props.authInfo
  //   }
  
  
  //   render() {
  //       let institution =   this.props.institution;
  //     let a4css = `
  //     .a4 {
  //       font-size: 12.5px;
        
  //   }
  //   .a4 body, table{
  //       font-size: 12.5px;
  //   }
  //     `
  
  //     let hafa4css = `
  //     .hafa4 { 
  //       width:500px !important;
  //   }
  //   .hafa4 body, table{
  //       font-size: 12.5px;
  //   }
  //     `
  
  //     let poscss = `
  //      body{
  //       font-size: 12.5px;
  //       font-weight: bold;
  //       font-family: cursive;
  //   }
  //    body, table{
  //       font-size: 12.5px;
  //       font-weight: bold;
  //   }
  
  //   .pos  table{
  //     font-size: 12.5px;
  //     font-weight: bold;
  // }
  //     `
  
  
     
  //     return (
  //       <div className='print-source' >
  //            {/* Print  DOCUMENT */}
  
  //                  {/* {   A4 Print */
  //                     institution != null &&  institution.pro_print_type == 'a4'?(
  //                       <html lang="en">
  //                     <head>
  //                        <meta charset="UTF-8" />
  //                         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //                         <meta http-equiv="X-UA-Compatible" content="ie=edge" />
  //                         <title>Machine Efficiency</title>
  //                         <style >
  //                            {a4css}
  //                         </style>
  //                     </head>
  //                     <body className="a4" style={{padding:'2px'}}>
  
  //                     <PrintAbleSection   authInfo={this.state.authInfo}  />
  //                     </body>
  //                     </html>
  //                      ):''
  //                  }
                      
  
  
  //                   {/* {  1/2 - A4   Print */
  //                     institution != null &&  institution.pro_print_type == '1/2a4'?(
  //                       <html lang="en">
  //                     <head>
  //                        <meta charset="UTF-8" />
  //                         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //                         <meta http-equiv="X-UA-Compatible" content="ie=edge" />
  //                         <title>Machine Efficiency</title>
  //                         <style > 
  //                            {hafa4css}
  //                         </style>
  //                     </head>
  //                     <body className="hafa4" style={{padding:'2px'}}>
  //                      <PrintAbleSection   authInfo={this.state.authInfo}  />
  //                     </body>
  //                     </html>
  //                      ):''
  //                  }
  
  
  //                   {/* {   A4 Print */
  //                     institution != null &&  institution.pro_print_type == 'pos'?(
  //                       <html lang="en">
  //                     <head>
  //                        <meta charset="UTF-8" />
  //                         <meta name="viewport" content="width=device-width, initial-scale=1.0" />
  //                         <meta http-equiv="X-UA-Compatible" content="ie=edge" />
  //                         <title>Machine Efficiency</title>
  //                         <style >
  //                            {poscss}
  //                         </style>
  //                     </head>
  //                     <body className="pos" style={{padding:'2px',width:'302.36px'}}>
  //                     <PrintAbleSection   authInfo={this.state.authInfo}  />
  //                     </body>
  //                     </html>
  //                      ):''
  //                  }
                      
             
  //       </div>
  //     );
  //   }
  // }
  
  // let SalesInvoice = ({authInfo,institution})=>{
  //   let componentRef = useRef()
  
  //   return (
  //     <>
  //             <Grid container>
  //                   <Grid item xs={12} sm={8} className={"invoice-section"}>
  //                     {/* Main Grid Start */}
  //                     <div>
  //                     <ReactToPrint
  //                       trigger={() => <PrintIcon  style={{cursor:'pointer'}} />}
  //                       content={() => componentRef}
  //                     />
   
  //                    <ComponentToPrint ref={el => (componentRef = el)} authInfo={authInfo}  institution={institution} />
  
  
  //                     <PrintAbleSection  authInfo={authInfo} />
  
  //                   </div>
  //                   </Grid>
  //             </Grid>
  //     </>
  //   )
  // }



  const mapStateToPops = (state)=>{
    return {
      
      authInfo:state.authInfoReducer
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(MachineEfficiency);