import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const InvoiceBody = ({saleData})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(sale)
         let [sale,saleSet] = useState([])
          let [saleDetails,saleDetailsSet] = useState([])

        useEffect(()=>{
            saleSet(saleData)
            console.log(saleData)
            if(saleData.details!=undefined){
                saleDetailsSet(saleData.details)
            }
            },[saleData])


      return(
          <>
        <table className={'invoice-table'}> 
          <thead>
              
                <td  style={{textAlign:'center'}} rowSpan={7}>SL</td>
                <td style={{textAlign:'center'}}>Description</td>
                <td>HSN/SAC</td>
                <td >Qty</td>
                <td >Rate</td>
                <td >per</td>
                <td >Amount</td>
             
          </thead>
          <tbody>
        

            {
              saleDetails.map((detail,ind) =>(
                  <>
                   <tr>
                       <td rowSpan={7} style={{textAlign:'center'}}>{ind+parseFloat(1)}</td>
                        
                       <td style={{textAlign:'center', border:'none'}}>  {detail.prod_name} </td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}> {detail.prod_whole_sale_rate}</td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                           {detail.sale_qty} {detail.prod_unit_name}
                       </td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                          {format(parseFloat(detail.sale_rate).toFixed(2))} 
                       </td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                         {detail.prod_unit_name}</td>
                       <td  style={{textAlign:'center', borderBottomStyle: 'none',borderTopStyle: 'none'}}>
                           {format(parseFloat(detail.sale_prod_total).toFixed(2))}
                       </td>
                   </tr>
                  
                  <tr>
                     <td style={{textAlign:'center', border:'none'}}>{saleData.sale_note != "" ?  saleData.sale_note : 'Machine Operation Done'}<br/>
                     { saleData.challanDetail && saleData.challanDetail.length>0 && (
                      <> your challan no {saleData.challanDetail.map((ele,id)=>(
                                <>{ele.m_purchase_invoice} &nbsp;</>
                      ))}
                      </>
                     )
                     }
                   
                     </td>
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                     <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                   </tr>
                 
                   <tr>
                      <td style={{textAlign:'center', border:'none'}}>
                       {
                          detail.prod_po_code_id == 1 ? " Item Code: " + detail.prod_item_code_1 :
                          (
                            detail.prod_po_code_id == 2 ?   " Item Code: " + detail.prod_item_code_2 :
                            detail.prod_po_code_id == 3 ?  " Item Code: " + detail.prod_item_code_3 :
                            detail.prod_po_code_id == 4 ?   " Item Code: " + detail.prod_item_code_4 :
                            detail.prod_po_code_id == 5 ?   " Item Code: " + detail.prod_item_code_5 :
                            "Item Code :"
                          ) 
                       }
                       </td>
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>  
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{color:"#FFF",borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                  </tr>
                  
                  <tr>
                      <td style={{textAlign:'center', border:'none'}}>Vendor Code: {sale.vendor_code_name}</td>
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                      <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                  </tr>
               
                  <tr>
                       <td style={{textAlign:'right', border:'none'}}>SGST {format(parseFloat(sale.pur_sgst_percent).toFixed(2))} %</td>
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                       <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_sgst_percent).toFixed(2))}</td> 
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>%</td> 
                       <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_sgst_amount).toFixed(2))}</td>
                  </tr>
                 
                   <tr>
                        <td style={{textAlign:'right', border:'none'}}>CGST {format(parseFloat(sale.pur_cgst_percent).toFixed(2))} %</td>
                        <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                        <td style={{color:"#FFF", borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td>
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_cgst_percent).toFixed(2))}</td>  
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>%</td> 
                        <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_cgst_amount).toFixed(2))}</td>
                   </tr>
                
                   <tr>
                      <td style={{textAlign:'right', border:'none'}}>IGST {format(parseFloat(sale.pur_igst_percent).toFixed(2))} %</td>
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{color:"#FFF",  borderBottomStyle: 'none',borderTopStyle: 'none'}}>-</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_igst_percent).toFixed(2))}</td>
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>%</td> 
                      <td style={{borderBottomStyle: 'none',borderTopStyle: 'none'}}>{format(parseFloat(sale.pur_igst_amount).toFixed(2))}</td>
                   </tr>

                  </>
               ))
            } 

                  <tr>
                      <td></td>
                       <td  style={{textAlign:'right'}}>Total</td>
                       <td  style={{textAlign:'left'}}></td>
                       <td  >{saleDetails.reduce((prev,curr)=>{
                         return prev +parseFloat(curr.sale_qty)
                       },0).toFixed(2)}</td>
                       <td></td>
                       <td  style={{textAlign:'right'}}></td>
                       <td  style={{textAlign:'left'}}>Rs. {format(parseFloat(sale.sale_total_amount).toFixed(2))}</td>
                   </tr>
              

              
          </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default InvoiceBody;