import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import {pathSpliter} from '../../lib/functions';
import SalesInvoice from './components/sales_invoice';
import {API_URL} from '../../config.json';
import axios from 'axios';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Grid from '@material-ui/core/Grid';

let ViewSalesInvoice = ({location,currentRouteSet,authInfo})=>{

  let [invoices,invoicesSet] = useState([]);
  let [selectedInvoice,selectedInvoiceSet] = useState(null);
  let [saleId,saleIdSet] = useState(null);
  let [institution,institutionSet] = useState(null);
  let [selectedInvoiceType, setSelectedInvoiceType] = useState('Extra Copy');
  let invoiceTypes = ['Original For Recipient','Duplicate For Transporter','Triplicate For Supplier','Extra Copy','Extra Copy'];


  useEffect(()=>{
    currentRouteSet(pathSpliter(location.pathname,1));
    getSalesInvoices();
    getInstitution();
},[]);

let getSalesInvoices = async ()=>{
    await axios.get(`${API_URL}/api/sales-invoices`,{headers:{'auth-token':authInfo.token}}).then(res=>{
        invoicesSet(res.data);
    })
}

let getInstitution = async ()=>{
    await  axios.get(`${API_URL}/api/get-institution`,{headers:{'auth-token':authInfo.token}}).then(res=>{
        institutionSet(res.data)
 })
}

  return(
          
    <>
        <Grid container>

            <Grid item xs={12} sm={6}>
                  <Grid xs={12} sm={6} style={{width:'250px',margin: '0px auto'}}>
                  <Autocomplete 
                  openOnFocus={true}
                     autoHighlight={true}
                     style={{width:'100%',height:'20px'}}
                     options={invoices}
                     value={selectedInvoice}
                     size="small"
                     getOptionLabel={(option) => option.sale_invoice}
                     onChange={(e,invoice)=>{

                      selectedInvoiceSet(invoice)
                        if(invoice != null){
                            saleIdSet(invoice.sale_id)
                        }
                     }}
                     renderInput={(params) => <TextField 
             
                 {...params} 
                 label="Choose Sales Invoice" 
                 variant="outlined"
                 
                 />} />                 
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={6}>
              <Grid xs={12} sm={6} style={{width:'250px',marginBottom:'20px'}}>
                <Autocomplete 
                     openOnFocus={true}
                     autoHighlight={true}
                     style={{width:'100%',height:'20px'}}
                     options={invoiceTypes}
                     value={selectedInvoiceType}
                     size="small"
                     getOptionLabel={(option) => option}
                     onChange={(e,invoice)=>{

                      setSelectedInvoiceType(invoice)
                     }}
                     renderInput={(params) => <TextField 
             
                 {...params} 
                 label="Choose Sales Invoice Type" 
                 variant="outlined"
                 
                 />} />
               </Grid>
               </Grid>

          
         

      </Grid>       
        
        {
             
              <SalesInvoice saleId={saleId} institution={institution} invoiceType={selectedInvoiceType} />
            
        }
            
      
    </>
)
 
}


  const mapStateToPops = (state)=>{
    return {
      currentRoute:state.currentRouteReducer,
      authInfo:state.authInfoReducer
    }
}

export default connect(mapStateToPops,{currentRouteSet})(ViewSalesInvoice);