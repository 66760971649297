import React,{Fragment, useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import axios from 'axios';
import { Modal } from 'react-responsive-modal';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import {checkIntNum,currentDateTime,getDateTimeFromISODT} from '../../lib/functions'
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat} from '../../lib/functions'
import {currentRouteSet,createdMachineSet,updatedMachineSet,disableRestoreMachineSet} from '../../actions/actions';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

const MachineNamesManage = ({location,currentRouteSet,authInfo,createdMachine, updatedMachine, disableRestoreMachine,
  createdMachineSet,updatedMachineSet,disableRestoreMachineSet})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    let [selectedMachineType,setSelectedMachineType] = useState('New');
    let [formValues,formSetValues] = useState({machine_names_name:'',machine_names_id:0,action:'create',amount:0, machine_names_created_isodt:selectedDate, machine_type: 'New', depreciation_amount:0, depreciation_months:0,});
    let [loadingSave,loadingSaveSet] = useState(false)
    let [loadingList,loadingListSet] = useState(false)
    let [units,unitsSet] = useState([])
    let [unitUpdateIndex,unitUpdateIndexSet] = useState('0')
    let [machine_categories_names, machine_categories_names_set] = useState([]);
    let [selectedMachineCategoryName, setSelectedMachineCategoryName] = useState(null);
    let [machineCategoryM, machineCategoryMSet] = useState(false)
    let [category_name, category_name_set] = useState('')
    let [categoryAction, categoryActionSet] = useState(false)
    let [machine_usage_names, machine_usage_names_set] = useState([]);
    let [selectedMachineUsageName, setSelectedMachineUsageName] = useState(null);
    let [machineUsageM, machineUsageMSet] = useState(false)
    let [usage_name, usage_name_set] = useState('')
    let [usageAction, usageActionSet] = useState(false)
    const [successMsg, setSuccessMsg] = useState({
      open: false,
      vertical: 'top',
      horizontal: 'center',
      msg:''
    });
   
    const { vertical, horizontal, open,msg } = successMsg;
    const handleClose = () => {
      setSuccessMsg({ ...successMsg, open: false });
    };
    createdMachineSet(null)
    updatedMachineSet(null)
    disableRestoreMachineSet(null)
    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getUnits();
        getMachineCategoriesName();
        getMachineUsageName();
    },[]);

    useEffect(()=>{
      if(createdMachine){
        console.log("-createdMachine--");
        if(checkAuthBranchWare(createdMachine.user_branch_id)){          
          unitsSet(createdMachine.createdRow.concat(units));
          setSuccessMsg({...successMsg,msg:`${createdMachine.msg}`,open:true });
          formSetValues({machine_names_name:'',machine_names_id:0,action:'create',amount:0, machine_names_created_isodt: selectedDate, machine_type:'New'})
        }
       }
    },[createdMachine])

    useEffect(()=>{
      if(updatedMachine){
        console.log("-updatedMachine--");
        if(checkAuthBranchWare(updatedMachine.user_branch_id)){          
          units[updatedMachine.index] = updatedMachine.updatedRow[0]
          setSuccessMsg({...successMsg,msg:`${updatedMachine.msg}`,open:true });
          formSetValues({machine_names_name:'',machine_names_id:0,action:'create',amount:0, machine_names_created_isodt:selectedDate, machine_type:'New'})
        }
       }
    },[updatedMachine])

    useEffect(()=>{
      if(disableRestoreMachine){
        if(checkAuthBranchWare(disableRestoreMachine.user_branch_id)){
        units[disableRestoreMachine.index] = disableRestoreMachine.disableRestoreRow[0];
        setSuccessMsg({...successMsg,msg:`${disableRestoreMachine.msg}`,open:true });
        }
       }
    },[disableRestoreMachine]);

    const handleFromInput = (e)=>{
        const {name,value} = e.target;
        formSetValues({...formValues,[name]:value}) 
      }
    const saveFormAction = async ()=>{
        if(formValues.machine_names_name.trim()==''){
            swal({
              title:'Machine name is required',
              icon:'warning'
            })
          }
          if(!selectedMachineCategoryName){
            swal({
              title:'Machine Category name is required',
              icon:'warning'
            })
          }if(!selectedMachineUsageName){
            swal({
              title:'Machine Usage name is required',
              icon:'warning'
            })
          }else{
            formValues.unitUpdateIndex = unitUpdateIndex
            // formValues.machine_names_name = formValues.machine_names_name.trim()
            formValues.machine_names_created_isodt = selectedDate
          
            loadingSaveSet(true)
            await axios.post(`${API_URL}/api/machine-cu`,{formValues,machine_category: selectedMachineCategoryName, machine_usage:selectedMachineUsageName},{headers:{'auth-token':authInfo.token}}).then( res=>{
            loadingSaveSet(false)
            console.log("----res--", res);
            if(res.data.error){
              swal({
                title:res.data.message,
                icon:'warning'
              });
              return false;
            }else {
              const f2 =  async () => {
              let pur_machine_id = [];
              let machineId = res.data.id;
              // console.log(dt, "date");
              // console.log(new Date(dt.setMonth(dt.getMonth() + (1))), "date saving");
              for (let i=0;i<formValues.depreciation_months;i++){
                 let dt = new Date(getDateTimeFromISODT(selectedDate));
                 let purchase={
                  pur_supplier_id : 0,
                  pur_emp_id:0,
                  pur_pay_method: 'cash',
                  pur_bank_id :0,
                  depreciated_machine_id : res.data.id,
                  pur_created_isodt: new Date(dt.setMonth(dt.getMonth() + (i+1))),
                 }
                 const formData = new FormData();
                 formData.append('fileData', null);
                 formData.append('purchase', JSON.stringify(purchase));
                 formData.append('purchaseCart',JSON.stringify( null));
              
                 await axios.post(`${API_URL}/api/save-purchase`,
                      formData,
                      {
                        headers:{
                          'Content-Type': 'multipart/form-data',
                          'auth-token':authInfo.token
                        },
                      }
                      ).then(res=>{
                            if(!res.data.error){
                              console.log('adding machine monthly');
                              pur_machine_id.push(res.data.message.purchaseId);
                            }
                            else{
                              swal({title:`Your Network problem....${res.data.message}`,icon:'warning'})
                            }
                          })
                  console.log(pur_machine_id);
                }
                await axios.post(`${API_URL}/api/save-machine-purchase-id`, {machineId, pur_machine_id},{headers:{'auth-token':authInfo.token}}).then(res =>{
                    //
                    if(res.data.error){
                      //retrace 
                    }
                });
              };
              f2();
          
            }
            getUnits();
            formSetValues({...formValues, machine_names_name:'',machine_names_id:0,action:'create',amount:0, machine_names_created_isodt:selectedDate, machine_type: 'New', depreciation_amount:0, depreciation_months:0})
          })

          }
    }


    const unitEdit = (row,index)=>{
      //formValues.machine_names_name =  units[index].machine_names_name
      formValues.action =  'update'
      
      formSetValues({...formValues,machine_names_name:units[index].machine_names_name,
        machine_names_id:units[index].machine_names_id, amount: units[index].amount,
         machine_names_created_isodt: units[index].machine_names_created_isodt, machine_type: units[index].machine_type,
         depreciation_amount:units[index].depreciation_amount, depreciation_months: units[index].depreciation_months,
      });
       handleDateChangeSet(units[index].machine_names_created_isodt);
        unitUpdateIndexSet(index)
        setSelectedMachineCategoryName({machine_cat_id: units[index].machine_cat_id, machine_cat_name:units[index].machine_cat_name,
        machine_cat_created_isodt : units[index].machine_cat_created_isodt, machine_cat_updated_isodt: units[index].machine_cat_updated_isodt,
      machine_cat_branch_id: units[index].machine_cat_branch_id, machine_cat_created_by: units[index].machine_cat_created_by,
    machine_cat_updated_by:units[index].machine_cat_updated_by, machine_cat_status: units[index].machine_cat_status})
    setSelectedMachineUsageName({machine_usage_id: units[index].machine_usage_id, machine_usage_name:units[index].machine_usage_name,
      machine_usage_created_isodt : units[index].machine_usage_created_isodt, machine_usage_updated_isodt: units[index].machine_usage_updated_isodt,
    machine_usage_branch_id: units[index].machine_usage_branch_id, machine_usage_created_by: units[index].machine_usage_created_by,
  machine_usage_updated_by:units[index].machine_usage_updated_by, machine_usage_status: units[index].machine_usage_status})
    }
    
    const unitDisableRestore = async (unitId,actionCond,index)=>{
      await axios.post(`${API_URL}/api/machine-disable-restore`,{machine_names_id:unitId,action:actionCond,index},{headers:{'auth-token':authInfo.token}})
    }

    const getUnits = async ()=>{
          loadingListSet(true)
          await axios.post(`${API_URL}/api/get-machinenames`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            loadingListSet(false)
            unitsSet(res.data.message)

          })
    }
    
    const getMachineCategoriesName = async ()=>{
      await axios.post(`${API_URL}/api/get-machine-categories-names`,{'select-type':'a'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        machine_categories_names_set(res.data.message);
      })
   }

   const getMachineUsageName = async ()=>{
    await axios.post(`${API_URL}/api/get-machine-usage-names`,{'select-type':'a'},{headers:{'auth-token':authInfo.token}}).then(res=>{
      machine_usage_names_set(res.data.message);
    })
 }

   const saveCategoryAction = async ()=>{
    console.log('save func front called');
    if(category_name.trim()==''){
      swal({
        title:'Category  name is required',
        icon:'warning'
      })
    }else{
     categoryActionSet(true)
      await axios.post(`${API_URL}/api/machine-category-name-cu`,{category_name:category_name.trim(),action:'create'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        categoryActionSet(false)
        console.log(res.data,"Save Step in Modal front");
        if(res.data.error){
          swal({
            title:res.data.message,
            icon:'warning'
          });
          return false;
        }
        getMachineCategoriesName();
        machineCategoryMSet(false)
        category_name_set('')
     })
    }
    
    }
    
    const saveUsageAction = async ()=>{
      console.log('save func front called');
      if(usage_name.trim()==''){
        swal({
          title:'Category  name is required',
          icon:'warning'
        })
      }else{
       usageActionSet(true)
        await axios.post(`${API_URL}/api/machine-usage-name-cu`,{usage_name:usage_name.trim(),action:'create'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          usageActionSet(false)
          console.log(res.data,"Save Step in Modal front");
          if(res.data.error){
            swal({
              title:res.data.message,
              icon:'warning'
            });
            return false;
          }
          getMachineUsageName();
          machineUsageMSet(false)
          usage_name_set('')

       })
      }
      
      }

    const ActionOptions = (props)=>{
        return(<div style={{textAlign:'right'}}>
       {
    authInfo.role !='user'?(
    <>
         <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>unitEdit(props.rowData[0],props.rowIndex)}/>
          {props.rowData[1]=='active'?(
                <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>unitDisableRestore(props.rowData[0],'disable',props.rowIndex)}/>
          ):(
            <SettingsBackupRestoreIcon style={{cursor:'pointer',color: 'rgb(60, 178, 194)',fontSize: '28px',}} onClick={()=>unitDisableRestore(props.rowData[0],'restore',props.rowIndex)}/>
          )}        
   </>):''
 }
         
        </div>)
      
      }
    
    const columns = [
        {name: "machine_names_id",options: { display: 'excluded' }},
        {name: "machine_names_status",options: { display: 'excluded' }},
        {name: "machine_cat_name",label: "Machine Category",options: {filter: true,sort: true}},
        {name: "machine_usage_name",label: "Machine Usage",options: {filter: true,sort: true}},
        {name: "machine_names_name",label: "Machine name",options: {filter: true,sort: true}},
        {name: "amount",label: "Principal amount",options: {filter: true,sort: true}},
        {name: "machine_names_created_isodt",label: "machine purchase date & time",
        options: 
        {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<p>{moment(tableMeta.rowData[6]).format(dateTimeFormat)}</p>)
          }
        }
      },
        {name: "machine_names_updated_isodt",label: "updated date & time",options: 
        {filter: true,sort: true,
          customBodyRender:(value,tableMeta)=>{
            return(<p>{ moment(tableMeta.rowData[7]).format(dateTimeFormat)}</p>) 
          }
        }},
        {name: "machine_type",label: "Machine Type",options: {filter: true,sort: true}},
        {name: "depreciation_amount",label: "Depreciation Amt",options: {filter: true,sort: true}},
        {name: "depreciation_months",label: "Depreciation months",options: {filter: true,sort: true}},
        {name:"actions",options: {filter: false,sort: false,
          customBodyRender:(value,tableMeta)=>{
            return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
               /> ); 
        }
        },headerStyle: {
          textAlign:'right'
        }}
       ];
              
       const options = {
         filterType: 'checkbox',
         selectableRows: 'none',
         display: "excluded"
        }

        let machineType = ['New', 'Refurbished'];

    return (
        <div className={classes.root}>
             {/* Success message */}
              <Snackbar open={open} autoHideDuration={10000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success">
                  {successMsg.msg}
                </Alert>
              </Snackbar>  
              {/* End of message */}
            <Paper className={classes.paper} style={{marginTop:'-15px'}}>
            <h2 className={classes.pageEntryLabel}>Machine name Entry</h2>
             
            <Grid container spacing={2}>
            {/**machine category**/ }
            <Grid item xs={12} sm={3} className={classes.plusLinkDiv} style={{margin:'0px auto'}} >
                  {
                    authInfo.role !='user'?(
                    <>
                    <a className={classes.plusLink} style={{cursor:'pointer'}} onClick={(e)=>machineCategoryMSet(true)} >+</a>      
                    </>):''
                  }         
                    <Autocomplete
                        style={{ width: '100%',height:'20px' }}
                        options={machine_categories_names} 
                        size="small"
                        openOnFocus={true}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.machine_cat_name}
                        getOptionSelected={(option, value) => {
                            return option.machine_cat_id === value.machine_cat_id;
                        }}
                        onChange={(event,selectedObj)=>{
                          setSelectedMachineCategoryName(selectedObj)
                          if(selectedObj==null)return false
                        }}
                        value={selectedMachineCategoryName}
                        loading={machine_categories_names.length==0?true:false}
                        renderInput={(params) => (
                            <TextField
                            // inputRef={materialNameRef}
                            // onKeyDown={event => {
                            //   if (event.key === "Enter") {
                            //     unitRef.current.focus()

                            //   }
                            // }}
                            {...params}
                            label="Choose a Machine Category"
                            variant="outlined"
                            loading={machine_categories_names.length==0?true:false}
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {materialNames.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment} */}
                                  </React.Fragment>
                                )
                            }}
                            />
                        )}
                        />
            </Grid>
                      {/* Steps Add Modal */}
                          <Modal
                              open={machineCategoryM}
                              onClose={() => machineCategoryMSet(false)}
                              center
                              style={{minWidth:'300px',minHeight:'500px'}}
                            >

                          <Grid item xs={12} sm={12}  > 
                                <TextField  autoComplete='off' style={{marginTop:'10px'}}   className={classes.fullWidth}  value={category_name} onChange={(e)=>category_name_set(e.target.value)}
                                label="Create a category"  type="text" name="category_name" variant="outlined" size="small"
                                onKeyDown={(event)=>{
                                  if (event.key === "Enter") {
                                    saveCategoryAction()
                                  }  
                                }}
                          />
                          

                              <Button style={{marginTop: '25px'}}
                              variant="contained"
                              color="primary"
                              disabled={categoryAction?true:false}
                              size="small"
                              className={classes.button}
                              startIcon={<SaveIcon/>}
                              onClick={saveCategoryAction}
                              >
                              Save
                            </Button>
                          </Grid>
                            </Modal>
                  
            {/**machine usage**/ }
            <Grid item xs={12} sm={3} className={classes.plusLinkDiv} style={{margin:'0px auto'}} >
                  {
                    authInfo.role !='user'?(
                    <>
                    <a className={classes.plusLink} style={{cursor:'pointer'}} onClick={(e)=>machineUsageMSet(true)} >+</a>      
                    </>):''
                  }         
                    <Autocomplete
                        style={{ width: '100%',height:'20px' }}
                        options={machine_usage_names} 
                        size="small"
                        openOnFocus={true}
                        classes={{
                            option: classes.option,
                        }}
                        autoHighlight
                        getOptionLabel={(option) => option.machine_usage_name}
                        getOptionSelected={(option, value) => {
                            return option.machine_usage_id === value.machine_usage_id;
                        }}
                        onChange={(event,selectedObj)=>{
                          setSelectedMachineUsageName(selectedObj)
                          if(selectedObj==null)return false
                        }}
                        value={selectedMachineUsageName}
                        loading={machine_usage_names.length==0?true:false}
                        renderInput={(params) => (
                            <TextField
                            // inputRef={materialNameRef}
                            // onKeyDown={event => {
                            //   if (event.key === "Enter") {
                            //     unitRef.current.focus()

                            //   }
                            // }}
                            {...params}
                            label="Choose a Machine Usage"
                            variant="outlined"
                            loading={machine_usage_names.length==0?true:false}
                            inputProps={{
                                ...params.inputProps,
                                endAdornment: (
                                  <React.Fragment>
                                    {/* {materialNames.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment} */}
                                  </React.Fragment>
                                )
                            }}
                            />
                        )}
                        />
            </Grid>
                      {/* Steps Add Modal */}
                          <Modal
                              open={machineUsageM}
                              onClose={() => machineUsageMSet(false)}
                              center
                              style={{minWidth:'300px',minHeight:'500px'}}
                            >

                          <Grid item xs={12} sm={12}  > 
                                <TextField  autoComplete='off' style={{marginTop:'10px'}}   className={classes.fullWidth}  value={usage_name} onChange={(e)=>usage_name_set(e.target.value)}
                                label="Create a usage"  type="text" name="usage_name" variant="outlined" size="small"
                                onKeyDown={(event)=>{
                                  if (event.key === "Enter") {
                                    saveUsageAction()
                                  }  
                                }}
                          />
                          

                              <Button style={{marginTop: '25px'}}
                              variant="contained"
                              color="primary"
                              disabled={usageAction?true:false}
                              size="small"
                              className={classes.button}
                              startIcon={<SaveIcon/>}
                              onClick={saveUsageAction}
                              >
                              Save
                            </Button>
                          </Grid>
                            </Modal>

            <Grid item xs={12} sm={3}  style={{margin:'0px auto'}}> 
            <TextField  autoComplete='off'  className={classes.fullWidth}  value={formValues.machine_names_name} 
            label="machine name" name="machine_names_name" variant="outlined" size="small" onChange={handleFromInput} />
            </Grid>
            <Grid item xs={12} sm={3}  style={{margin:'0px auto'}}> 
            <TextField  autoComplete='off' type="number" className={classes.fullWidth}  value={formValues.amount} 
            label="principal amount" name="amount" variant="outlined" size="small" onChange={handleFromInput} />
            </Grid>
            <Grid item xs={12} sm={3}  style={{margin:'0px auto'}}> 
            <TextField  autoComplete='off' type="number" className={classes.fullWidth}  value={formValues.depreciation_amount} 
            label="Depreciation Amount" name="depreciation_amount" variant="outlined" size="small" onChange={handleFromInput} />
            </Grid>
             <Grid item xs={12} sm={3}  style={{margin:'0px auto'}}> 
            <TextField  autoComplete='off' type="number" className={classes.fullWidth}  value={formValues.depreciation_months} 
            label="Depreciation Months" name="depreciation_months" variant="outlined" size="small" onChange={handleFromInput} />
            </Grid>

            
            {/* Machine type */}
            <Grid item xs={12} sm={3}>
                    {/* <a className={classes.plusLink} href="/administration/departments-manage" target="_blank">+</a>  */}
                <Autocomplete
                style={{ width: '100%' }}
                options={machineType} 
                size="small"
                classes={{
                    option: classes.option,
                }}
                value={selectedMachineType}
                openOnFocus={true}
                autoHighlight
                 getOptionLabel={(option) => (option?option:'')}
                //  getOptionSelected={(option, value) => {
                //     return option.id === value.id;
                // }}
                onChange={(event,selectedObj)=>{
                     selectedObj?(formSetValues({...formValues,machine_type: selectedObj})):
                     (formSetValues({...formValues,machine_type:''}))
                     setSelectedMachineType(selectedObj);
                }}
                loading={machineType.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                   
                    {...params}
                    label="Machine Type"
                    variant="outlined"
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {machineType.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                    }}
                    />
                )}
                />
            </Grid>
            
            <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
            <KeyboardDateTimePicker
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            onChange={handleDateChangeSet}
            label="Machine Purchse date  time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>

           </Grid>
           <Grid item xs={12}>
          <Button style={{marginTop: '25px'}}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SaveIcon/>}
            disabled={loadingSave}
            onClick={saveFormAction}
        >
        Save
      </Button>
  </Grid>
            </Paper>
            {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Machine List"}
      data={units}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }
        </div>
    )
}



const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(1),
    },
  root: {},
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '25ch',
   },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    pageEntryLabel:{
        color: '#7754cc',
        margin: '0px',
        padding: '0px',
        marginTop: '-11px',
        textAlign: 'left',
        marginLeft: '0px',
        marginBottom: '5px',
        textAlign:'center'
    },
    fullWidth:{
        width:'100%'
    },
    option: {
        fontSize: 15,
        '& > span': {
          marginRight: 10,
          fontSize: 18,
        },
      },
      plusLinkDiv:{
        position:'relative'  
      },
      plusLink:{
        margin: 0,
        padding: 0,
        marginTop: '-21px',
        fontSize: '29px',
        height: '21px',
        textAlign: 'right',
        position: 'absolute',
        right: 0,
        color: '#3e8d54'
      },
  }));
  
  const mapStateToPops = (state)=>{
        return {
          currentRoute:state.currentRouteReducer,
          authInfo:state.authInfoReducer,
          createdMachine:state.createdMachineReducer,
          updatedMachine:state.updatedMachineReducer,
          disableRestoreMachine:state.disableRestoreMachineReducer
        }
  }
  export default connect(mapStateToPops,{currentRouteSet,createdMachineSet,updatedMachineSet,disableRestoreMachineSet})(MachineNamesManage);