import React, { useState,useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {currentDateTime, dateTimeFormat, fmtHMSS, fmtMSS} from '../../../lib/functions'
import './invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const MCTInvoiceBody = ({purchaseData})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(purchase)
         let [purchase,purchaseSet] = useState([])
          let [materials,materialsSet] = useState([])
        

        useEffect(()=>{
            purchaseSet(purchaseData)
            if(purchaseData.materials!=undefined){
              materialsSet(purchaseData.materials)
              
            }
            },[purchaseData]);
            console.log(purchaseData, 'Purchase Data Body', materials);


      return(
          <>
        <table className={'invoice-table'}  style={{width:'49%',float:'left'}}> 
        <thead>
              
              <td>SL</td>
              <td style={{textAlign:'left'}}>Product</td>
              <td>Blank Wt(in Kg)</td>
              <td>Final Wt(in Kg)</td>
              <td>Scrap Wt(in Kg)</td>
              <td>Forging Rate</td>
              <td>Machining Rate</td>
           
        </thead>
        <tbody>
      
        
                 <tr>
                     <td>{parseFloat(1)}</td>
                     <td style={{textAlign:'left'}}>{purchaseData.prod_name}</td>
                     <td>{purchaseData.blank_wt}</td>
                     <td>{purchaseData.final_wt}</td>
                     <td>{purchaseData.scrap_wt}</td>
                     <td>{purchaseData.forging_rate}</td>
                     <td>{purchaseData.machining_rate}</td>
                 </tr>
     
         

            
        </tbody>
        </table>


        <table className={'invoice-table'} style={{width:'49%',float:'right',marginLeft:'1%'}}> 
          


          <thead>
              
              <td>SL</td>
              <td style={{textAlign:'left'}}>Machine</td>
              <td style={{textAlign:'left'}}>Step Name</td>
              <td style={{textAlign:'left'}}>Load time</td>
              <td style={{textAlign:'left'}}>Unload time</td>
              <td style={{textAlign:'left'}}>Process Time</td>
              
           
        </thead>
        <tbody>
      

          {
            materials.map((detail,ind) =>(
                 <tr key={detail.machine_usage_id}>
                     <td>{ind+parseFloat(1)}</td>
                     <td style={{textAlign:'left'}}>{detail.machine_usage_name}</td>
                     <td style={{textAlign:'left'}}>{detail.step_name_names}</td>
                     <td>{fmtHMSS(parseFloat(detail.load_time).toString())} </td>
                     <td>{fmtHMSS(parseFloat(detail.unload_time).toString())} </td>
                     <td>{fmtHMSS(parseFloat(detail.process_time).toString())} </td>
                   
                 </tr>
             ))
          } 
            

            
        </tbody>
        </table>
          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
    
  }));

export default MCTInvoiceBody;