import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,currentDateTime,fmtHMSS,getDateTimeFromISODT, fmtMSS} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from 'sweetalert';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Modal } from 'react-responsive-modal';
import { Redirect,generatePath  } from 'react-router'
import {useHistory} from 'react-router-dom'


import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {APP_URL,API_URL} from '../../config.json';

import {BrowserRouter as Router,Route,Link} from 'react-router-dom'


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select';

import socketIOClient from "socket.io-client";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const MachineCycleTime = ({location,currentRoute,currentRouteSet,authInfo})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [saveAction, saveActionSet] = useState(false);
    const [materialStock, materialStockSet] = useState(0);
    const history = useHistory();

    //machine -cycle-time starts
    let [selectedMachine, selectedMachineSet] = useState(null)
    let [machines, setMachines] = useState([]);
    let [stepM, stepMSet] = useState(false);
    let [step_name, step_name_set] = useState(''); 
    let [stepAction, stepActionSet] = useState(false);
    let [stepNames, stepNamesSet] = useState([]);
    let [selectedStepName, selectedStepNameSet] = useState(null);
    let [processTime, setProcessTime] = useState(0);
    let [loadTime, setLoadTime] = useState(15);
    let [unloadTime, setUnloadTime] = useState(15);
    let [totalCost,totalCostSet] = useState(0);

    /// Methods 

    const getMachines = async()=>{
      await axios.get(`${API_URL}/api/get-machine-usage-production`,{headers:{'auth-token':authInfo.token}}).then(res=>{
        setMachines(res.data.message);
      console.log(res.data.message,'Machines Fetch');
      })
   }

    const getStepName = async ()=>{
      await axios.get(`${API_URL}/api/get-step-names`,{headers:{'auth-token':authInfo.token}}).then(res=>{
        stepNamesSet(res.data.message);
      })
   }



   useEffect(()=>{
    getStepName();
    getMachines();
   },[])



    //ends


    let [purchaseData,setPurchaseData] = useState({
              invoice_no:'',
              emp_id:0,
              created_isodt:currentDateTime,
              // material_cost:0,
              // labour_cost:0,
              // others_cost:0,
              // total_cost:0,
              totalLoad :0,
              totalUnload : 0,
              totalProcess:0,
              production_id:0
            })
    let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
    {label:'Night Shift',value:'night_shift'}])
    let [selectedShift,selectedShiftSet] = useState(null)
    let [formAction,formActionSet] = useState('create');
    let [employees,employeesSet] = useState([]);
    let [usedCart,usedCartSet] = useState([]);
    let [productCart,productCartSet] = useState([]);
    let [subTotalProd,subTotalProdSet] = useState(0);
    let [totalLoadTime, setTotalLoadTime] = useState(0);
    let [totalProcessTime, setTotalProcessTime] = useState(0);
    let [totalUnloadTime,setTotalUnloadTime] = useState(0);
    let [totalTime,setTotalTime] = useState(0);

  

    let [cartTotal,cartTotalSet] = useState(0);
    let [cartProductTotal,cartProductTotalSet] = useState(0);
   
    let [selectedEmployee,selectedEmployeeSet] = useState(null)
    let [selectedSupplier,selectedSupplierSet] = useState(null)
    let [selectedMaterial,selectedMaterialSet] = useState(null)
    let [selectedProduct,selectedProductSet] = useState(null)

    let [materials,setMaterials] = useState([])
    let [products,setProducts] = useState([])

    let employeeRef = React.useRef(null)
    let purchaseDateRef = React.useRef(null)
    let supplierRef = React.useRef(null)
    let purchaseRateRef = React.useRef(null)
    let purchaseRateProdRef = React.useRef(null)
    let materialRef = React.useRef(null)
    let productRef = React.useRef(null)
    let quantityRef = React.useRef(null)
    let quantityProdRef = React.useRef(null)
    let totalRef = React.useRef(null)
    let purchaseToCartRef = React.useRef(null)
    let productToCartRef = React.useRef(null)
    let productRateRef = React.useRef(null)
    let saveProductionRef = React.useRef(null)


    let materialCostRef = React.useRef(null)
    let labourCostRef = React.useRef(null)
    let othersCostRef = React.useRef(null)
    let totalCostRef = React.useRef(null)
    let saveRef = React.useRef(null)
    const [selectedOption, setSelectedOption] = useState('secs');
    
    let [product,setProduct] = useState({
      prod_id : 0,
      prod_name:'',
      // prod_purchase_rate: 0,
      blank_wt: '',
      final_wt: '',
      forging_rate: 0,
      machining_rate:0,
      // prod_total: 0,
      });

      const handleChange = (e) => {
          setSelectedOption(e.target.value);
      }

    const handleFromInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }
    
    // const materialHandle = (e)=>{
    //   const {name,value} = e.target;
    //   setMaterial({...material,[name]:value}) 
    // }

    const productHandle = (e)=>{
      const {name,value} = e.target;
      setProduct({...product,[name]:value}) 
    }

    const handleTotalInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }
let [prodQty, setProdQty] = useState(0);
    // useEffect = (()=>{

    // },[usedCart]);
  
   

    // useEffect(()=>{
    //   let totalBill = (parseFloat(purchaseData.material_cost)+parseFloat(purchaseData.labour_cost)+
    //   parseFloat(purchaseData.others_cost))
    //   totalCostSet(totalBill)
    // },[handleTotalInput]);

   

    // useEffect(()=>{
    //   let total = material.material_purchase_rate * material.material_qty;
    //   cartTotalSet(total);
    // },[materialHandle])

    // useEffect(()=>{
    //   let total = product.prod_purchase_rate * product.blank_wt;
    //   cartProductTotalSet(total);
    // },[productHandle])

    let [machineCycle,setMachineCycle] = useState({
      cycle_id : 0,
      machine_usage_name:'',
      step_name:'',
      process_time: 0,
      load_time:0,
      unload_time: 0,
      machine_usage_id:0,
      step_id:0
      });

     //converting time into secs
    //  useEffect(()=>{
    //     if(selectedOption === 'mins'){

    //     }
    //  },[selectedOption])

  
    //calculating total time
    useEffect(()=>{
      let tLoad = 0;
      let tUnload = 0;
      let tProcess = 0;
      usedCart.map((e)=>{
          tLoad = parseFloat(e.load_time) + tLoad;
          tUnload = parseFloat(e.unload_time) + tUnload;
          tProcess = parseFloat( e.process_time) + tProcess;
      });

       setTotalLoadTime(tLoad);
       setTotalProcessTime(tProcess);
       setTotalUnloadTime(tUnload);
       setTotalTime(tLoad +tUnload+tProcess);

    },[usedCart]);
     
    const purchaseToCart = ()=>{
         //console.log('Btn click');
       if(selectedMachine==null){
            swal({title:'Select  machine',icon:'warning'});return false;
          }
          if(selectedStepName==null){
            swal({title:'Select Step',icon:'warning'});return false;
          }
         
          if(processTime==0){
            swal({title:'Enter Process Time',icon:'warning'});return false;
          }
          
          // if(material.material_qty < 0.1){
          //   swal({title:'Quantity is invalid.',icon:'warning'});return false;
          // }
    
          // material.material_total = cartTotal;
          // let checkExit =   usedCart.findIndex((ele)=>{
          //     if(ele.material_id==selectedMaterial.material_id){ 
          //       return true 
          //     }else{
          //       return false
          //     }
                  
          // });
          // if(checkExit>-1){
          //   swal({title:"This material already Exist.",icon:'warning'})
          //   return false;
          // }

          // if(material.material_qty > materialStock){
          //   swal({title:"Stock Unavailable",icon:'warning'})
          //   return false;
          // }
         
          console.log(selectedStepName, 'step save');
          console.log(selectedMachine,'machine save');
          console.log(processTime);
          // let newObj = {
          //   cycle_id:usedCart.length + 1,
          //    load_time:loadTime,
          //    process_time:processTime,
          //    unload_time:unloadTime,
          //    step_name:selectedStepName.step_name_names,
          //    machine_name:selectedMachine.machine_names_name,
          //    machine_id :selectedMachine.machine_names_id,
          //    step_id: selectedStepName.step_name_id
          // }
         //setMachineCycle({...machineCycle,cycle_id:usedCart.length + 1, load_time:loadTime,process_time:processTime,unload_time:unloadTime,step_name:selectedStepName.step_name_names,machine_name:selectedMachine.machine_names_name});
          //console.log(newObj); 
          machineCycle.load_time = (  (selectedOption === 'mins') ? parseFloat(loadTime) : parseFloat(loadTime));
          
          machineCycle.process_time = (  (selectedOption === 'mins') ? (
          ( parseFloat( (processTime).toString().split('.')[0]) * 60) + ( ( (parseFloat(processTime).toFixed(2) ).toString().split('.').length>1) ? parseFloat((parseFloat(processTime).toFixed(2)).toString().split('.')[1]) : 0)

          ) : parseFloat(processTime));
          machineCycle.unload_time = (  (selectedOption === 'mins') ? parseFloat(unloadTime) : parseFloat(unloadTime));;
          machineCycle.machine_usage_id =selectedMachine.machine_usage_id;
          machineCycle.step_id = selectedStepName.step_name_id;
          console.log(machineCycle,'machine cycle obj');
          
         
          usedCartSet([...usedCart,machineCycle]);
          
          //console.log(usedCart);
          //setMaterial({...material,material_purchase_rate:'',material_id:0,material_name:'',material_qty:''}) 
          setMachineCycle({cycle_id:'0',load_time:0,unload_time:0,process_time:0,step_name:'',machine_usage_name:''})
          selectedMachineSet(null);
          setLoadTime(15);
          setProcessTime(0);
          setUnloadTime(15);
          selectedStepNameSet(null);
          console.log(usedCart);
          
    }


    const productToCart = (()=>{
      if(selectedProduct==null){
        swal({title:'Select  product',icon:'warning'});return false;
      }
      // if(product.prod_purchase_rate < 1){
      //   swal({title:'Purchase rate is invalid.',icon:'warning'});return false;
      // }
      // if(product.prod_qty < 1){
      //   swal({title:'Quantity is invalid.',icon:'warning'});return false;
      // }

      // product.prod_total = cartProductTotal;
      let checkExit =   productCart.findIndex((ele)=>{
          if(ele.prod_id==selectedProduct.prod_id){ 
            return true 
          }else{
            return false
          }
              
      });
      if(checkExit>-1){
        swal({title:"This product already Exist.",icon:'warning'})
        return false;
      }
      console.log(product,'Product in front');
      
      productCartSet([...productCart,product]);
      setProduct({...product,prod_id:0,prod_name:'',blank_wt:'', final_wt:''}) 

      selectedProductSet(null);
      productRef.current.focus();
})

    // useEffect(()=>{
    //   let total =  usedCart.reduce((prev,curr)=>{
    //     return prev+parseFloat(curr.material_purchase_rate*curr.material_qty);
    // },0)
    // material_cost_set(total)
    // },[usedCart]) 

    

    // useEffect(()=>{
    //   let total =  productCart.reduce((prev,curr)=>{
    //     return prev+parseFloat(curr.prod_purchase_rate*curr.prod_qty);
    // },0)
    // subTotalProdSet(total)
    // },[productCart]) 
   



    // useEffect(()=>{
    //   setPurchaseData({...purchaseData,material_cost:material_cost})

    // },[material_cost])

    useEffect( ()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
     
      getEmployees();
      getMachines();
   
      if(pathSpliter(location.pathname,3) != undefined){
        formActionSet('update')
        
         axios.post(`${API_URL}/api/get-machine-cycle-time`,{productionId:parseFloat(pathSpliter(location.pathname,3))},{headers:{'auth-token':authInfo.token}}).then(res=>{
          console.log(res.data,'update mct');
       
          let production = res.data[0];
          let machineCycleCart = res.data[0].materials;

            selectedEmployeeSet({
              employee_id: production.employee_id,
              employee_name: production.employee_name
            });

            let shiftName = ``
            if(production.shift=='day_shift'){
              shiftName = `Day Shift`
            }
            if(production.shift=='night_shift'){
              shiftName = `Night Shift`
            }

            selectedShiftSet({
              label:shiftName,
              value:production.shift,
            })
  
       
            setTotalTime(production.total_time);
            setTotalLoadTime(production.total_load_time);
            setTotalUnloadTime(production.total_unload_time);
            setTotalProcessTime(production.total_process_time);
           
            setPurchaseData({...purchaseData,
              invoice_no:production.production_invoice,
              totalLoad:production.total_load_time,
              totalUnload:production.total_unload_time,
              totalProcess:production.total_process_time,
              production_id:production.machine_cycle_prod_id,
              production_note: production.production_note,
              machine_used_note: production.machine_used_note,
              shift_id:production.shift,
              emp_id:production.production_by
              })

              handleDateChangeSet(production.created_isodt)


              usedCartSet([...usedCart,machineCycle]);
              
            

            let usedCartGet =  machineCycleCart.map((ele)=>{ 
              let material = {
                cycle_id:ele.machine_cycle_id,
                machine_usage_name:ele.machine_usage_name,
               step_name:ele.step_name_names,
                process_time: ele.process_time,
                load_time:ele.load_time,
                unload_time: ele.unload_time,
                machine_usage_id:ele.machine_usage_id,
                step_id:ele.step_name_id
              }
              return material;
            });

            usedCartSet(usedCartGet)


            
              let product = {
                prod_id:res.data[0].product_id,
                prod_name:res.data[0].prod_name,
                blank_wt:res.data[0].blank_wt,
                final_wt : res.data[0].final_wt,
                forging_rate: res.data[0].forging_rate,
                machining_rate: res.data[0].machining_rate,
              }
              setProduct(product);
              selectedProductSet({ prod_id:res.data[0].product_id,
                prod_name:res.data[0].prod_name,});
        })
 
      }else{
        getPurchaseInvoice();
      }

      getIndividualProducts()
    },[]) 



    let saveProduction = async ()=>{

      
        if(usedCart.length==0){
          swal({title:'Sorry... Yourstep Cycle Time cart is Empty.',icon:'warning'}) 
          return false;
        }
        if(selectedProduct==null){
          swal({title:'Select  product',icon:'warning'});return false;
        }

        // if(productCart.length==0){
        //   swal({title:'Sorry... Your production product cart is Empty.',icon:'warning'}) 
        //   return false;
        // }
        if(selectedEmployee==null){
          swal({title:'Select Employee',icon:'warning'}) 
          return false;
        }
        if(selectedShift==null){
          swal({title:'Select Shift',icon:'warning'}) 
          return false;
        }


        
       let purchase =  {...purchaseData,
        totalLoad : totalLoadTime,
        totalUnload:totalUnloadTime,
        totalProcess: totalProcessTime,
        created_isodt:selectedDate,
        }

      // setProduct({
      //   prod_id:selectedProduct.prod_id,
      //   prod_name: selectedProduct.prod_name,
         
      // })
      console.log('save fun called ',usedCart," ",purchase," ",product);
       
      
        saveActionSet(true)
        let url = `/save-machine-cycle`;
        if(pathSpliter(location.pathname,3) != undefined){
            url =  `/update-machine-cycle`;
        }
    

        await axios.post(`${API_URL}/api${url}`,{purchase,usedCart,product},{headers:{'auth-token':authInfo.token}}).then(res=>{
         console.log(res.data,'Res from saev');
        
          if(!res.data.error){
            let smg = 'MCT  ';
            if(pathSpliter(location.pathname,3) != undefined){
              smg = 'Updated '
            }
            swal({
              title:`${smg} successfully. Do you want to view details?`,
              icon:'success',
              buttons:true
            }).then((confirm)=>{
              if(confirm){
                 history.push(`/production/machine-cycle-time-invoice/${res.data.message.productionId}`) 
                 saveActionSet(false)
              }else{
                window.location.reload();
              }
            })
          }
          else{
            swal({title:'Your Network problem....',icon:'warning'})
          }
        })

      
    }


    /// Methods 

   


    const getPurchaseInvoice = async ()=>{ 
          await axios.get(`${API_URL}/api/get-machine-cycle-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
                setPurchaseData({...purchaseData,invoice_no:res.data.message})
          })
    }

    

    
    const getIndividualProducts = async()=>{
      await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        setProducts(res.data.message);
      })
}


    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data.message)
    })
    }

    // useEffect(()=>{
    //   if(selectedMaterial != null && selectedMaterial.material_id != null){
    //     axios.post(`${API_URL}/api/get-material-current-stock`,{materialId: selectedMaterial.material_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
    //       materialStockSet(res.data.stock)
    //   })
    //   }else{
    //     materialStockSet(0)
    //   }
    // },[selectedMaterial])

    // useEffect(()=>{
    //   if(selectedMachine != null && selectedMaterial.material_id != null){
    //     axios.post(`${API_URL}/api/get-material-current-stock`,{materialId: selectedMaterial.material_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
    //       materialStockSet(res.data.stock)
    //   })
    //   }else{
    //     materialStockSet(0)
    //   }
    // },[selectedMaterial])

    

   
    const  usedCartRemove = (row,index)=>{
      let hardCopy =  [...usedCart];
          hardCopy.splice(index, 1);
          usedCartSet(hardCopy)
    }

    const  productCartRemove = (row,index)=>{
      let hardCopy =  [...productCart];
          hardCopy.splice(index, 1);
          productCartSet(hardCopy)
    }




    
   const saveStepAction = async ()=>{
    console.log('save func front called');
    if(step_name.trim()==''){
      swal({
        title:'Step  name is required',
        icon:'warning'
      })
    }else{
     stepActionSet(true)
      await axios.post(`${API_URL}/api/step-name-cu`,{step_name:step_name.trim(),action:'create'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        stepActionSet(false)
        console.log(res.data,"Save Step in Modal front");
        if(res.data.error){
          swal({
            title:res.data.message,
            icon:'warning'
          });
          return false;
        }
        getStepName();
        stepMSet(false)
        step_name_set('')
     })
    }
    
    }

      return(
          <div className={classes.root}>
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '2px'}}>Machine Cycle Time</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12} sm={2}>
            <TextField label="ID" variant="outlined"  size="small"  className={classes.fullWidth} value={purchaseData.invoice_no} onChange={handleFromInput} name="invoice_no" disabled />
          </Grid> 

       

          
                <Grid item  xs={0}  sm={7}>

                </Grid>
          <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
          
            <KeyboardDateTimePicker
            inputRef={purchaseDateRef}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                supplierRef.current.focus()
              }
            }}
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            onChange={handleDateChangeSet}
            label="Material Purchse date  time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        </Paper>

<Grid container spacing={3} >
        <Grid item xs={12} sm={9} >
          <Paper className={classes.paper}>
        
          <Grid container spacing={3} >
          
         
          <Grid item xs={12} sm={6} className={classes.plusLinkDiv} style={{border:' 10px solid #e0f7fa',
    padding: '10px',borderRadius: '20px'}}>
                <Link className={classes.plusLink} to="/administration/machine-names-manage" >+</Link>
               
                <p style={{fontWeight: 'bold',margin: '0',padding: '0',marginBottom: '5px',
    marginTop: '-10px',textAlign:'left'}}>Machine Cycle Time</p>
            
             {/* Machine Select*/}
              <Autocomplete 
              autoHighlight
              openOnFocus={true}
              value={selectedMachine}
              style={{width:'100%',height:'20px'}}
              options={machines}
              size="small"
              classes={{
                option: classes.option,
              }}
              getOptionSelected={(option, value) => {
                return option.machine_usage_id === value.machine_usage_id;
            }}
              onChange={(e,obj)=>{

               
                selectedMachineSet(obj);
               if(obj==null)return false
               setMachineCycle({...machineCycle,machine_usage_name:obj.machine_usage_name});
                // setMaterial({...material,material_purchase_rate:parseFloat(obj.material_purchase_rate).toFixed(2),material_id:obj.material_id,material_name:obj.material_name}) 
                //   quantityRef.current.focus()
                
              }}
              getOptionLabel={(option) => option.machine_usage_name}
              renderInput={(params) => <TextField 
                
                // inputRef={materialRef}
                // onKeyDown={event => {
                //   if (event.key === "Enter") {
                //       purchaseRateRef.current.focus()
                //   }
                // }} 
                {...params} 
                label="choose a machine" 
                variant="outlined"
                
                />}
                
          />
          <br />
         
            {/* Steps Entry */}
            <Grid item xs={12} sm={12}  className={classes.plusLinkDiv}>
             {
    authInfo.role !='user'?(
    <>
                      <a className={classes.plusLink} style={{cursor:'pointer'}} onClick={(e)=>stepMSet(true)} >+</a>  
       
   </>):''
 }                 
                <Autocomplete
                style={{ width: '100%',height:'20px' }}
                options={stepNames} 
                size="small"
                openOnFocus={true}
                classes={{
                    option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.step_name_names}
                getOptionSelected={(option, value) => {
                    return option.step_name_id === value.step_name_id;
                }}
                onChange={(event,selectedObj)=>{
                  selectedStepNameSet(selectedObj)
                  if(selectedObj==null)return false
                  setMachineCycle({...machineCycle,step_name:selectedObj.step_name_names,cycle_id:selectedObj.step_name_id})
                }}
                value={selectedStepName}
                loading={stepNames.length==0?true:false}
                renderInput={(params) => (
                    <TextField
                    // inputRef={materialNameRef}
                    // onKeyDown={event => {
                    //   if (event.key === "Enter") {
                    //     unitRef.current.focus()

                    //   }
                    // }}
                    {...params}
                    label="Create a step"
                    variant="outlined"
                    loading={stepNames.length==0?true:false}
                    inputProps={{
                        ...params.inputProps,
                        endAdornment: (
                          <React.Fragment>
                            {/* {materialNames.length==0 ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment} */}
                          </React.Fragment>
                        )
                    }}
                    />
                )}
                />
            </Grid>
              {/* Steps Add Modal */}
     <Modal
        open={stepM}
        onClose={() => stepMSet(false)}
        center
        style={{minWidth:'300px',minHeight:'500px'}}

      >

        <Grid item xs={12} sm={12}  > 
            <TextField  autoComplete='off' style={{marginTop:'10px'}}   className={classes.fullWidth}  value={step_name} onChange={(e)=>step_name_set(e.target.value)}
            label="Create a step"  type="text" name="step_name" variant="outlined" size="small"
            onKeyDown={(event)=>{
              if (event.key === "Enter") {
                saveStepAction()
              }
              
            }}
            />
            

            <Button style={{marginTop: '25px'}}
            variant="contained"
            color="primary"
            disabled={stepAction?true:false}
            size="small"
            className={classes.button}
            startIcon={<SaveIcon/>}
            onClick={saveStepAction}
        >
        Save
      </Button>
        </Grid>
      </Modal>
        <br/>

                <Grid container >
                      
                      <Grid item xs={12} sm={6}>
                      <TextField style={{marginRight:'5px'}}  
                             type="number" label="Loading Time" disabled  size="small" value={loadTime} onChange={(e)=> setLoadTime(e.target.value)} name="loading_time" variant="outlined" className={classes.inputField} />
                      </Grid>
                      
                      <Grid item sm={1} >
                      </Grid>
                      
                      <Grid item xs={12} sm={5}>
                      <TextField style={{marginRight:'5px'}}  
                             type="number" label="Unloading Time" disabled size="small" value={unloadTime} onChange={(e)=> setUnloadTime(e.target.value)} name="processing_time" variant="outlined" className={classes.inputField} />
                      </Grid> 

                      

                </Grid>

                

                <Grid container >
                      
                     
                <Grid item xs={12} sm={4}>
                      <TextField style={{marginRight:'5px'}}  
                             type="number" label="Processing Time"  size="small" placeholder="1" value={processTime} onChange={(e)=> setProcessTime(e.target.value)} name="processing_time" variant="outlined" className={classes.inputField} />
                </Grid>

                

                <Grid item sm={1} >
                </Grid>     

                    <Grid item xs={12} sm={5}>
                      <p>Time in </p>
                    <label>
        <input type="radio" value="mins" checked={selectedOption === 'mins'} onChange={handleChange} />
        Minutes
      </label>
      <br />
      <label>
        <input type="radio" value="secs" checked={selectedOption === 'secs'} onChange={handleChange} />
        Seconds
      </label>
                      </Grid>   
                    
                </Grid> 
                      

                  <Grid item xs={12} sm={5} style={{display: 'grid',fontWeight: 'bold',alignContent: 'center'}} >
                  <Button 
                      buttonRef={purchaseToCartRef}
                      onClick={purchaseToCart}

                      style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                      Add New Step
                      </Button>
                  </Grid>

               
                </Grid>
                <Grid item xs={12} sm={6} className={classes.plusLinkDiv} style={{border:' 10px solid #e0f7fa',
    padding: '10px',borderRadius: '20px'}}>
                <Link className={classes.plusLink} to="/administration/products-manage" >+</Link>  
                <p style={{fontWeight: 'bold',margin: '0',padding: '0',marginBottom: '5px',
    marginTop: '-10px',textAlign:'left'}}>Product Entry</p>
              <Autocomplete 
              autoHighlight
              openOnFocus={true}
              value={selectedProduct}
              style={{width:'100%',height:'20px'}}
              options={products}
              size="small"
              onChange={(e,obj)=>{

                selectedProductSet(obj)
                if(obj==null)return false
                setProduct({...product,
                  prod_id:obj.prod_id,prod_name:obj.prod_name}) 
                  quantityProdRef.current.focus()
                
              }}
              getOptionLabel={(option) => option.prod_name}
              renderInput={(params) => <TextField 
                
                inputRef={productRef}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                      purchaseRateProdRef.current.focus()
                  }
                }} 
                {...params} 
                label="choose a product" 
                variant="outlined"
                
                />}
                
          />
        <br/>

                <Grid container >
                      
                     
                <Grid item xs={12} sm={5} >
                <TextField label="Forging wt(in kg)"  type="number"
                        inputRef={quantityProdRef}
                        onKeyDown={event => {
                          if (event.key === "Enter") {
                            productToCartRef.current.focus()
                          }
                        }}
                        value={product.blank_wt}
                      variant="outlined"  size="small"   onChange={productHandle} name="blank_wt" className={classes.inputField} />
                      </Grid>
                     
                      <Grid item sm={1} >
                      </Grid>
                      

                      <Grid item xs={12} sm={5} >
                           <TextField label="Final wt(in kg)"  type="number"
                              onKeyDown={event => {
                                if (event.key === "Enter") {
                                  productToCartRef.current.focus()
                                }
                              }}
                              value={product.final_wt}
                            variant="outlined"  size="small"   onChange={productHandle} name="final_wt" className={classes.inputField} />
                      </Grid>

                     <br /><br />
               
                          <Grid item xs={12} sm={5} style={{marginTop:"10px" }} >
                           <TextField label="Machining Rate"  type="number"
                              onKeyDown={event => {
                                if (event.key === "Enter") {
                                  productToCartRef.current.focus()
                                }
                              }}
                              value={product.machining_rate}
                            variant="outlined"  size="small"   onChange={productHandle} name="machining_rate" className={classes.inputField} />
                        </Grid>

                      <Grid item sm={1} >
                      </Grid>

                      <Grid item xs={12} sm={5} style={{marginTop:"10px" }}>
                           <TextField label="Forging Rate"  type="number"
                              onKeyDown={event => {
                                if (event.key === "Enter") {
                                  productToCartRef.current.focus()
                                }
                              }}
                              value={product.forging_rate}
                            variant="outlined"  size="small"   onChange={productHandle} name="forging_rate" className={classes.inputField} />
                      </Grid>
               

                      {/* <Button 
                      buttonRef={productToCartRef}
                      onClick={productToCart}

                      style={{marginTop: '5px',marginLeft: 'auto',fontSize:'12px'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                       Add  To Cart 
                      </Button> */}
                      {/* <TextareaAutosize onChange={handleTotalInput} name="production_note" value={purchaseData.production_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Product Production  Note..." rowsMin={3} placeholder="Product Production  Note..." /> */}
            
                </Grid>
               
                </Grid>
                </Grid>
          </Paper> 
          <Grid container>
            
            <Grid item xs={12}  style={{marginTop:'5px',border:' 10px solid #e0f7fa',
    padding: '1px',borderRadius: '20px'}} >
                  <Paper className={classes.paper}  >
                  <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Machine Cycle Time Steps </h4>
                  <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow >
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Machine</TableCell>
            <TableCell align="center"> Steps</TableCell>
            <TableCell align="center">Load </TableCell>
            <TableCell align="center">Unload </TableCell>
            <TableCell align="center">Process </TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
           usedCart.map((row,index) => ( 
            <TableRow key={row.cycle_id}>
              <TableCell  align="left">{parseFloat(index)+1}</TableCell>
              <TableCell align="left">{row.machine_usage_name}</TableCell> 
              <TableCell align="center">{row.step_name}</TableCell> 
              <TableCell align="center">{parseFloat(row.load_time)} s</TableCell>
              <TableCell align="center">{parseFloat(row.unload_time)} s</TableCell>
              <TableCell align="center">{fmtMSS((row.process_time).toString())} mins</TableCell>
              <TableCell align="center">{fmtMSS((parseFloat(row.load_time) + parseFloat(row.process_time) + parseFloat(row.unload_time)).toString())} mins</TableCell>
               <TableCell align="center" >
              <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{usedCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
            </TableRow>
          ))}

        {/* {usedCart.length!=0?
        <TableRow colSpan={4}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{fontWeight:'bold'}} align="right">Total :  </TableCell>
         <TableCell></TableCell>
        </TableRow>
        :''} */}


        </TableBody>
      </Table>
    </TableContainer>

     {/* {console.log(usedCart,'Dis here')} */}
    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
      {usedCart.length!=0?
          <TableRow >
             <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="machine_used_note" value={purchaseData.machine_used_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Machine Used Note..." rowsMin={3} placeholder="Machine Used Note..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
          :''}
      </Table>
    </TableContainer>


                  </Paper>
            </Grid>


            {/*  */}

 
            {/* <Grid item xs={6}  style={{marginTop:'5px',border:' 10px solid #e0f7fa',
    padding: '1px',borderRadius: '20px'}} >
                  <Paper className={classes.paper} >
                  <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Production Products</h4>
                  <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="left">SL</TableCell>
            <TableCell align="left">Product</TableCell>
            <TableCell align="center">Rate</TableCell>
            <TableCell align="center">Qty</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          { 
           productCart.map((row,index) => ( 
            <TableRow key={row.prod_id}>
              <TableCell  align="left">{parseFloat(index)+1}</TableCell>
              <TableCell align="left">{row.prod_name}</TableCell> 
              <TableCell align="center">{format(parseFloat(row.prod_purchase_rate).toFixed(2))}</TableCell> 
              <TableCell align="center">{row.blank_wt}</TableCell>
              <TableCell align="right">{format(parseFloat(row.prod_total).toFixed(2))}</TableCell>
               <TableCell align="right" >
              <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{productCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
            </TableRow>
          ))}

        {productCart.length!=0?
        <TableRow colSpan={4}>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
          <TableCell style={{fontWeight:'bold'}} align="right">Total : {format(parseFloat(subTotalProd).toFixed(2))}</TableCell>
         <TableCell></TableCell>
        </TableRow>
        :''}


        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table">
      {productCart.length!=0?
          <TableRow >
             <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="production_note" value={purchaseData.production_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Product Production  Note..." rowsMin={3} placeholder="Product Production  Note..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
          :''}
      </Table>
    </TableContainer>


                  </Paper>
            </Grid> */}
          
          </Grid>
        
        </Grid>

        <Grid item xs={12} sm={3} >
        
          <Paper className={classes.paper}>
          <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '3px'}}>Summary Details</h4>


          <Grid container style={{paddingRight:'1px'}}>

          <Grid item  xs={12}  sm={12}  style={{marginBottom:'25px',marginTop:'4px'}}> 
          <Autocomplete 
          size="small"

          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={employees}
              value={selectedEmployee}
              getOptionLabel={(option) => option.employee_name}
              onChange={(event,selectedObj)=>{
                purchaseData.emp_id = selectedObj!=null?selectedObj.employee_id:0
                selectedEmployeeSet(selectedObj)
              }}
              renderInput={(params) => <TextField 
              
                inputRef={employeeRef}
                onKeyDown={event => {
                  if (event.key === "Enter") {
                      purchaseDateRef.current.focus()
                  }
                }} 
                
                {...params} 
                label="Production / Incharge By " 
                variant="outlined"
               
                />} 
                
          />

          </Grid>

                <Grid item  xs={12}   sm={12} > 
                      <Select
                      value={selectedShift}
                      onChange={(shift)=>{
                          if(shift == null){
                              return false;
                          }
                          selectedShiftSet(shift);
                          purchaseData.shift_id = shift.value
                      }}
                      options={shifts}

                  />
        </Grid>
                    



                     
                      
                     
                     
          </Grid>

         
          <Grid container style={{paddingRight:'1px'}}>
                    
                      <Grid item xs={12} sm={12} >
                      <TextField type="text" label="Total Load Time"  size="small"  variant="outlined" className={classes.inputField} 
                      disabled
                      value={fmtHMSS(totalLoadTime.toString())}
                      name="load_time"
                      />
                      </Grid>    
                        
          </Grid>
          <Grid container style={{paddingRight:'1px'}}>
                    
                      <Grid item xs={12} sm={12} >
                      <TextField type="text" onChange={handleTotalInput} label="Total Unload Time"  size="small"  variant="outlined" className={classes.inputField} 
                       disabled
                      value={fmtHMSS(totalUnloadTime.toString())}
                      name="unload_time"
                       />
                      </Grid>    
                        
          </Grid>

          <Grid container style={{paddingRight:'1px'}}>
                    
                      <Grid item xs={12} sm={12} >
                      <TextField type="text" onChange={handleTotalInput} label="Total Process Time"  size="small"  variant="outlined" className={classes.inputField} 
                      disabled
                      value={fmtHMSS(totalProcessTime.toString())}
                      name="process_time"
                      inputRef={othersCostRef} />
                      </Grid>    
                        
          </Grid>

          <Grid container style={{paddingRight:'1px'}}>
                    
                      <Grid item xs={12} sm={12} >
                      <TextField type="text" onChange={handleTotalInput} label="Total Time"  size="small"  variant="outlined" className={classes.inputField} 
                      disabled
                      value={fmtHMSS(totalTime.toString())}
                      name="process_time"
                      inputRef={othersCostRef} />
                      </Grid>    
                        
          </Grid>

          <Grid container style={{paddingRight:'1px'}}>
                    
                      <Grid item xs={12} sm={12} >
                      <TextField type="number" onChange={handleTotalInput} label="Scrap Wt(in kg)"  size="small"  variant="outlined" className={classes.inputField} 
                      disabled
                      value={(product.blank_wt-product.final_wt).toFixed(3)}
                      name="scrap_wt"
                      />
                      </Grid>    
                        
          </Grid>


          {/* <Grid container style={{paddingRight:'1px'}}>
          <Grid item xs={12} sm={12}>
                      <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Total" disabled variant="outlined" className={classes.inputField} 
                      value={totalCost}
                      onChange={handleTotalInput}
                      name="totalCost"
                      inputRef={totalCostRef} />
                      </Grid>
                      <Grid item xs={0} sm={1}>
                      </Grid>


          </Grid> */}


      
        




        

          <Grid container style={{paddingRight:'1px'}}>
                      <Grid item xs={12} sm={8}>
                      <Button style={{marginTop: '5px',fontSize:'14px',float:'left'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            buttonRef={saveRef} 
                            onClick={saveProduction} 
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                            disabled={saveAction?true:false}
                        >
                       Save
                      </Button>
                      </Grid>
                  
                      {/* <Grid item xs={12} sm={4} >
                      <Button 
                      style={{marginTop: '5px',fontSize:'10px',float:'right'}} 
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={()=>window.location.reload()}
                            className={classes.button}
                            startIcon={<SaveIcon/>}
                        >
                        New
                      </Button>
                      </Grid>     */}
          </Grid>


          </Paper>
        </Grid>

        
      </Grid>
          </div>
      )
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(MachineCycleTime);