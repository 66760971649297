import React, { useState,useEffect } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Paper from '@material-ui/core/Paper';
import Select from 'react-select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib;
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,fmtMSS,fmtHMSS,currentDateTime,getDateTimeFromISODT} from '../../../lib/functions'

let format = commaNumber.bindWith(',', '.')
const DailyMachineCycleTimeTable = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    // Invoice body
        
        const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
        const [selectedOption, setSelectedOption] = useState('secs');
        let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
        {label:'Night Shift',value:'night_shift'}]);
        let [shift_id, shift_id_set] = useState();
        let [divFactor, setDivFactor] = useState(1.0);
        let [selectedShift,selectedShiftSet] = useState(null)
        let [invoiceNo, setInvoiceNo] = useState('');
        let [row, rowSet] = useState([]);
        let [totalTimeWorking, setTotalTimeWorking] = useState([])
        let [actualQty, setActualQty] = useState([])
        let [shortQty,setShortQty] = useState([])
        let [targetQty, setTargetQty] = useState([])
        let [selectedEmployee,selectedEmployeeSet] = useState([])
        let [employees,employeesSet] = useState([]);
        let [machines, machinesSet] = useState([]);
        let [products, setProducts] = useState([]);
        let [selectedProduct, setSelectedProduct] = useState([]);
        let [steps,setSteps] = useState([]);
        let [stepsPrdWise, setStepsPrdWise] = useState([]);
        let [selectedStep, setSelectedStep] = useState([])
        let [cycleTime, setCycleTime] = useState([])
        let [idealTime, setIdealTime] = useState([]);
        let [timeFactor, setTimeFactor] = useState(1.0);  
        let [saveAction, saveActionSet] = useState(false);
           
         /// Methods 
        const getInvoice = async ()=>{ 
            await axios.get(`${API_URL}/api/get-daily-mct-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            setInvoiceNo(res.data.message)
          })
        }

        const handleChange = (e) => {
          let prevValue = selectedOption;
          if(e.target.value === 'mins' && prevValue === 'secs')
          {
            setDivFactor(60.0);
            setTimeFactor(60.0);
          }else if(e.target.value === 'secs' && prevValue === 'mins'){
            setDivFactor(1.0);
            setTimeFactor(1/60.0);
          } 
           setSelectedOption(e.target.value);
        } 

        const getEmployees = async ()=>{
            await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
              employeesSet(res.data.message);
              console.log(employees,'table page');
          })
        }

        const getMachines = async ()=>{
          await axios.get(`${API_URL}/api/get-all-machine-names-production`,{headers:{'auth-token':authInfo.token}}).then(res=>{
            machinesSet(res.data.message)
          })
        }

      const getProducts = async ()=>{
        await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setProducts(res.data.message);
              // ( async()=>{
              //   await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:res.data.message ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
              //     setCycleTime(res.data);
              //     console.log(res.data, 'MCT in tabble');
              //   });
              // })();
  
        });

       
      }

      const getStepName = async ()=>{
        await axios.get(`${API_URL}/api/get-step-names`,{headers:{'auth-token':authInfo.token}}).then(res=>{
          setSteps(res.data.message);
        })
      }




      const getMachineCycleTimeIDList = async()=>{
        await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart:selectedProduct ,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          setCycleTime(res.data);

          let stepsForPrd = res.data.map((ele, id)=>{
               let dataRes = []
               let data = ele != null && ele.cycleTime.length != 0 && ele.cycleTime.map((tmp, idx)=>{
                let allRes = []    
                let tmpAns = tmp.data && tmp.data.length !=0 && tmp.data.map((obj, ind)=>{
                      let tmpObj = {
                        step_name_id : obj.step_name_id,
                        step_name_names: obj.step_name_names
                      }
                      return tmpObj;
                  })
                  console.log(tmpAns);
                   tmpAns && tmpAns.map(o1=>{
                    allRes.push(o1)
                   })
                   console.log(allRes)
                  return allRes;
               })
              // const distinctEle = new Set(data);
              console.log(data)
              data && data.map(o1=>{
                o1 && o1.map(o2=>{
                  dataRes.push(o2);
                })
               
              })
              return dataRes;
          });
          console.log(stepsForPrd)
         

          let stepOptionsForPrd = stepsForPrd.map((ele, ind)=>{
            const distinctArray = [];
            const idLookup = {};
            ele && ele.map(obj => {
              if (!idLookup[obj.step_name_id]) {
                distinctArray.push(obj);
                idLookup[obj.step_name_id] = true;
              }
            });
            return distinctArray;
          })
          

          setStepsPrdWise(stepOptionsForPrd);
          console.log(steps,stepOptionsForPrd);
          console.log(res.data, 'MCT in tabble');
        });
        //stepwise prod end
      }

      useEffect(()=>{
        if(selectedProduct.length!=0)
          getMachineCycleTimeIDList();
      },[selectedProduct])
       
      const calculateTime =  () => {
        let timeCopy = [...idealTime];
        machines.map((data, idx)=>{
            let found = 0;
            selectedStep[idx] && selectedProduct[idx] && cycleTime[idx] != null && cycleTime[idx].cycleTime  && cycleTime[idx].cycleTime.length>0 && 
            cycleTime[idx].cycleTime.length>0 && (
              cycleTime[idx].cycleTime.map((obj,objid)=>{
                        obj.data.length>0 && (
                      
                          obj.data.map((ele,index)=>{
                          if(ele.step_name_id === selectedStep[idx].step_name_id && cycleTime[idx].prod_id === selectedProduct[idx].prod_id){
                            
                              console.log(idx, selectedProduct[idx],selectedStep[idx], found);
                                found = 1;
                              
                                
                                timeCopy[idx] = ( (parseFloat(ele.load_time) + parseFloat(ele.unload_time) + parseFloat(ele.process_time)) / (  parseFloat(divFactor)) ).toFixed(3)
                                
                                
                          }else{
                            if(found === 0){
                              
                              timeCopy[idx] = 0
                              
                            }
                          }
                          
                        })
                          
                      )
              })
            ) 
            found = 0; 
            
        });
        setIdealTime(timeCopy)
      }
      useEffect( ()=>{
        if(selectedProduct.length!=0)
         getMachineCycleTimeIDList();
      },[selectedProduct, selectedStep])

      // useEffect(()=>{
      //   console.log(idealTime, 'separate');
      //   setIdealTime(timeCopy);
      // },[timeCopy]) 


      useEffect( ()=>{
         calculateTime();
         console.log(idealTime);
        let newArr = totalTimeWorking.map((data, idx)=>{
          return data/timeFactor;
        })
        setTotalTimeWorking(newArr)
        
      },[selectedProduct,selectedStep,selectedOption]); 
      
      
          
      useEffect(()=>{
        console.log("Updated state", idealTime);
      },[idealTime, selectedOption])
          
          useEffect(()=>{
            console.log(selectedEmployee);
            console.log(totalTimeWorking);
            console.log(targetQty);
            console.log(actualQty);
            console.log(shortQty);
            console.log(idealTime,"common")
          },[selectedEmployee, totalTimeWorking, targetQty,actualQty,shortQty,idealTime])

          useEffect(()=>{       
            
          },[divFactor]);

          useEffect(()=>{
              getEmployees();
               getMachines();
               getProducts();
                getStepName();
                getInvoice();
                // if(products.length>0) getMachineCycleTimeIDList();
          },[])
          
         
          

          

          //   useEffect(()=>{
          //     let rows = cycleTimeList.map((obj,id)=>{
          //       let sum = 0;
          //       obj.cycleTime.map((mct,indx)=>{
          //        sum = sum + mct.data.length;
          //       });
          //       // console.log(sum);
          //       return sum;
                
          // }); 
          // rowSet(rows);
          //   },[cycleTimeList])

      const saveEntry = async()=>{
        if(selectedShift === null){
          swal({title:'Select  Shift',icon:'warning'});return false;
        }
        let targetCopy = [...targetQty]
        let empEfficiency = [...targetQty];
        let shortCopy = [...shortQty];
        let  totalTimeCopy = [...totalTimeWorking];
        idealTime.map((data, idx)=>{
            targetCopy[idx] = isNaN((totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)) ? 0 : ((totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)) ;
            shortCopy[idx] = isNaN(( (totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)- actualQty[idx]).toFixed(3)) ? 0 : (( (totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)- actualQty[idx]).toFixed(3));
            empEfficiency[idx] = isNaN(100 * ( (parseFloat(  actualQty[idx]) / (totalTimeWorking[idx]*3600/ idealTime[idx]) )).toFixed(2)) ? 0 :
            (100 * ( (parseFloat( actualQty[idx]) / (totalTimeWorking[idx]*3600/ idealTime[idx]) )).toFixed(2));
            totalTimeCopy[idx] = parseFloat(totalTimeWorking[idx])*3600;
          });
        
       
       console.log(selectedProduct, selectedStep, idealTime, totalTimeWorking, targetCopy, actualQty, shortCopy, empEfficiency);
       await axios.post(`${API_URL}/api/save-daily-production-entry`,{invoiceNo, selectedShift: shift_id, selectedDate, machines, selectedEmployee, selectedProduct, selectedStep, idealTime, totalTimeWorking : totalTimeCopy, targetQty: targetCopy, actualQty, shortQty: shortCopy,empEfficiency },{headers:{'auth-token':authInfo.token}}).then(res=>{
            if(!res.data.error){
              // let smg = 'Production  ';
              // if(pathSpliter(location.pathname,3) != undefined){
              //   smg = 'Production updated '
              // }
              // swal({
              //   title:`${smg} successfully. Do you want to view details?`,
              //   icon:'success',
              //   buttons:true
              // }).then((confirm)=>{
              //   if(confirm){
              //      history.push(`/production/machine-cycle-time-invoice/${res.data.message.productionId}`) 
              //      saveActionSet(false)
              //   }else{
              //     window.location.reload();
              //   }
              // })
              swal({title:'Success Save',icon:'success'})
            }
            else{
              swal({title:'Your Network problem....',icon:'warning'})
            }
      });
      }     
         

      return( 
        <div className={classes.root}>
        <Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                marginBottom: '2px'}}>Daily Production Entry</h4>
         <Grid container spacing={3} > 
                    <Grid item  xs={12} sm={2}>
                        <TextField label="invoice no" variant="outlined"  size="small"  className={classes.fullWidth} value={invoiceNo} onChange={(e)=>setInvoiceNo(e.target.value)} name="invoice_no" disabled />
                    </Grid>  
                    <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
                        <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
                        <KeyboardDateTimePicker
                        style={{ width: '100%',marginTop: '' }}
                        value={selectedDate}
                        onChange={handleDateChangeSet}
                        label="Daily Production Entry date  time"
                        format="yyyy/MM/dd hh:mm a"
                        />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item  xs={12}   sm={3} > 
                      <Select
                      value={selectedShift}
                      onChange={(shift)=>{
                          if(shift == null){
                              return false;
                          }
                          selectedShiftSet(shift);
                          shift_id_set(shift.value);
                      }}
                      options={shifts}

                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                       <button onClick={()=>window.open(`/administration/products-manage`,'_blank')} > New Product Entry </button> 
                    </Grid>
        </Grid>
        </Paper>

      <Grid container spacing={3} >
         <Grid item xs={12} sm={12} >
         <Paper className={classes.paper}> 
            {/* <Grid item xs={12} sm={12}>
                      <p>Time in 
                         <label>
                       <input type="radio" value="mins" checked={selectedOption === 'mins'} onChange={handleChange} />
                          Minutes
                        </label>
                        <label>
                        <input type="radio" value="secs" checked={selectedOption === 'secs'} onChange={handleChange} />
                          Seconds
                        </label>
                        </p>
            </Grid>  */}

              <TableContainer >
                {/**mct table starts */}
                <Table className={classes.table} style={{borderCollapse:'separate', borderSpacing:"0px 14px"}} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Step No</TableCell>
                    <TableCell align="center">Usage</TableCell>
                    <TableCell align="center">Machine</TableCell>
                    <TableCell align="center">Operator Name</TableCell>
                    <TableCell align="center">Product</TableCell>
                    <TableCell align="center">Operation</TableCell>
                    <TableCell align="center">Cycle Time</TableCell>
                    
                    <TableCell align="center">Total Time</TableCell>
                    <TableCell align="center">Target Qty</TableCell>
                    <TableCell align="center">Actual Qty</TableCell>
                    <TableCell align="center">Short Qty</TableCell>
                    <TableCell align="center">Emp Efficiency</TableCell>
                  </TableRow>
                </TableHead>
              <TableBody>
              
                {
                  machines.map((data,idx)=>{
                    return (
                      <TableRow>
                              
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</TableCell>
                          <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center', padding:"4px"}}>{data.machine_usage_name}</TableCell>
                          <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center', padding:"4px"}}>{data.machine_names_name}</TableCell>
                          {/* emp select */}
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                                  <Autocomplete 
                                      size="small"     
                                      autoHighlight
                                      openOnFocus={true}
                                      style={{width:'100%',height:'20px',marginTop:"-20px"}}
                                      options={employees}
                                      value={selectedEmployee[idx]}
                                      getOptionLabel={(option) => option.employee_name}
                                      onChange={(event,selectedObj)=>{
                                          let empCopy = [...selectedEmployee];
                                          empCopy[idx] = selectedObj
                                          selectedEmployeeSet(empCopy);
                                      }}
                                      renderInput={(params) => <TextField 
                                          {...params} 
                                          label="Operator Incharge By " 
                                          variant="outlined"
                                          />}           
                                      />
                          </TableCell>

                          {/* product select */}
                          <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                                  <Autocomplete 
                                      size="small"     
                                      autoHighlight
                                      openOnFocus={true}
                                      style={{width:'100%',height:'20px',marginTop:"-20px"}}
                                      options={products}
                                      value={selectedProduct[idx]}
                                      loading={true}
                                      getOptionLabel={(option) => option.prod_name}
                                      onChange={(event,selectedObj)=>{
                                          let productCopy = [...selectedProduct];
                                          productCopy[idx] = selectedObj
                                          setSelectedProduct(productCopy);
                                          
                                         
                                          // let timeCopy = [...idealTime];
                                          // timeCopy[idx] =  calculateTime(idx);
                                          // setIdealTime(timeCopy);
                                      }}
                                      renderInput={(params) => <TextField 
                                          {...params} 
                                          label="Product" 
                                          variant="outlined"
                                          />}           
                                      />
                        </TableCell>

                        {/* step select */}
                        <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                                 
                                  <Autocomplete 
                                      size="small"     
                                      autoHighlight
                                      openOnFocus={true}
                                      style={{width:'100%',height:'20px',marginTop:"-20px"}}
                                      options={stepsPrdWise && stepsPrdWise[idx] ? stepsPrdWise[idx] : []}
                                      value={selectedStep[idx]}
                                      loading={true}
                                      getOptionLabel={(option) => option && option.step_name_names ? option.step_name_names : ''}
                                      onChange={(event,selectedObj)=>{
                                          let stepCopy = [...selectedStep];
                                          stepCopy[idx] = selectedObj
                                          setSelectedStep(stepCopy);
                                      }}
                                      renderInput={(params) => <TextField 
                                          {...params} 
                                          label="Operation" 
                                          variant="outlined"
                                          />}           
                                  />
                                  
                        </TableCell>

                        {/* ideal cycle time*/}  
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                          { fmtHMSS((idealTime[idx] != null ? idealTime[idx] : 0).toString()) }
                      </TableCell>  

                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                            <TextField label="total time(in hrs)" type="number" 
                                  value={(totalTimeWorking[idx])} 
                                  onChange={(e)=>{
                                      let timeCopy = [...totalTimeWorking];
                                      timeCopy[idx] = (e.target.value);
                                      setTotalTimeWorking(timeCopy);
                                                              
                                      let qtyCopy = [...targetQty];
                                      qtyCopy[idx] = (totalTimeWorking[idx]/ idealTime[idx]).toFixed(3);
                                      setTargetQty(qtyCopy);
                                  }} 
                                  name="total_time_working" 
                                  size="small" 
                                  variant="outlined"
                                  className={classes.inputField}
                            />
                    </TableCell>

                    <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                        
                          { isNaN((totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)) ? 0 : ((totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)) }
                      </TableCell>
                                            
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                        <TextField label="actual qty" type="number" 
                              value={actualQty[idx]} 
                              onChange={(e)=>{
                                  let aqtyCopy = [...actualQty];
                                  aqtyCopy[idx] = e.target.value;
                                  setActualQty(aqtyCopy);
                                                        
                                  let shortCopy = [...shortQty];
                                  shortCopy[idx] = (targetQty[idx] - (actualQty[idx])).toFixed(3);
                                  setShortQty(shortCopy);
                              }} 
                              name="actual_qty" 
                              size="small" 
                              variant="outlined"
                              className={classes.inputField}
                        />
                      </TableCell>

                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                          { isNaN(( (totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)- actualQty[idx]).toFixed(3)) ? 0 : (( (totalTimeWorking[idx]*3600/ idealTime[idx]).toFixed(3)- actualQty[idx]).toFixed(3))}
                      </TableCell>
                      
                      <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                        { isNaN(100 * ( (( actualQty[idx]) / (totalTimeWorking[idx]*3600/ idealTime[idx]) )).toFixed(2))? 0 :
                         (100 * ( ((  actualQty[idx]) / (totalTimeWorking[idx]*3600/ idealTime[idx]) )).toFixed(2))} %
                      </TableCell>

                      </TableRow>
                    )
                    })
                }
                


                  {
                    // cycleTimeList.map((detail,ind) =>(
                          
                    //             detail.cycleTime.map((mct,id) =>(
                    //                  mct.data.map((data, idx)=>{
                    //                     return(
                    //                     // <TableRow>
                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>{idx+parseFloat(1)}</TableCell>
                    //                     //     <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center', padding:"4px"}}>{data.machine_names_name}</TableCell>
                    //                     //     <TableCell rowSpan={1} colSpan={1} style={{textAlign:'center'}}>{data.step_name_names}</TableCell>
                    //                     //     <TableCell colSpan={1} style={{textAlign:'center'}}>{( (parseFloat(data.load_time) + parseFloat(data.unload_time) + parseFloat(data.process_time))/ (  parseFloat(divFactor)) ).toFixed(3)}</TableCell>
                                            
                    //                     //       {/* employee select */}
                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                    //                     //     <Autocomplete 
                    //                     //         size="small"     
                    //                     //         autoHighlight
                    //                     //         openOnFocus={true}
                    //                     //         style={{width:'100%',height:'20px'}}
                    //                     //         options={employees}
                    //                     //         value={selectedEmployee[idx]}
                    //                     //         getOptionLabel={(option) => option.employee_name}
                    //                     //         onChange={(event,selectedObj)=>{
                    //                     //             let empCopy = [...selectedEmployee];
                    //                     //             empCopy[idx] = selectedObj
                    //                     //             selectedEmployeeSet(empCopy);
                    //                     //         }}
                    //                     //         renderInput={(params) => <TextField 
                    //                     //             {...params} 
                    //                     //             label="Operator Incharge By " 
                    //                     //             variant="outlined"
                    //                     //             />}           
                    //                     //         />
                    //                     //     </TableCell>

                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                    //                     //       <TextField label="total time" type="number" 
                    //                     //             value={totalTimeWorking[idx]} 
                    //                     //             onChange={(e)=>{
                    //                     //                 let timeCopy = [...totalTimeWorking];
                    //                     //                 timeCopy[idx] = e.target.value;
                    //                     //                 setTotalTimeWorking(timeCopy);
                                                        
                    //                     //                 let qtyCopy = [...targetQty];
                    //                     //                 qtyCopy[idx] = (totalTimeWorking[idx]/ (( (parseFloat(data.load_time) + parseFloat(data.unload_time) + parseFloat(data.process_time))/ (  parseFloat(divFactor)) ))).toFixed(3);
                    //                     //                 setTargetQty(qtyCopy);
                    //                     //             }} 
                    //                     //             name="total_time_working" 
                    //                     //             size="small" 
                    //                     //             variant="outlined"
                    //                     //             className={classes.inputField}
                    //                     //       />
                    //                     //     </TableCell>

                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                    //                     //        {/* {(totalTimeWorking[idx]/ (( (parseFloat(data.load_time) + parseFloat(data.unload_time) + parseFloat(data.process_time))/ (  parseFloat(divFactor)) ))).toFixed(3)} */}
                    //                     //         {targetQty[idx]}
                    //                     //     </TableCell>
                                            
                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                    //                     //       <TextField label="actual qty" type="number" 
                    //                     //             value={actualQty[idx]} 
                    //                     //             onChange={(e)=>{
                    //                     //                 let aqtyCopy = [...actualQty];
                    //                     //                 aqtyCopy[idx] = e.target.value;
                    //                     //                 setActualQty(aqtyCopy);
                                                        
                    //                     //                 let shortCopy = [...shortQty];
                    //                     //                 shortCopy[idx] = (targetQty[idx] - (actualQty[idx])).toFixed(3);
                    //                     //                 setShortQty(shortCopy);
                    //                     //             }} 
                    //                     //             name="actual_qty" 
                    //                     //             size="small" 
                    //                     //             variant="outlined"
                    //                     //             className={classes.inputField}
                    //                     //       />
                    //                     //     </TableCell>

                    //                     //     <TableCell rowSpan={1} colSpan={1}  style={{textAlign:'center'}}>
                    //                     //         {(targetQty[idx]- actualQty[idx]).toFixed(3)}
                    //                     //     </TableCell>


                    //                     //   </TableRow>
                
                    //                     )
                    //                 })        
                    //             ))
                            
                      
                    //  ))
                  }
                </TableBody>
                </Table>
                </TableContainer>
                <Button 
                onClick={saveEntry}
                style={{marginTop: '5px',marginLeft: 'auto',fontSize:'12px'}} 
                      variant="contained"
                      color="primary"
                      size="small"
                      disabled = {saveAction ? true: false}
                      className={classes.button}
                      startIcon={<SaveIcon/>}
                  >
                  Save Entry
              </Button>
        </Paper>
        </Grid>
        </Grid>
      </div>
      )
}


const useStyles = makeStyles((theme) => ({
    
   
  }));
  const mapStateToPops = (state)=>{
    return {
      
      authInfo:state.authInfoReducer
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(DailyMachineCycleTimeTable);