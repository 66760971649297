import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {pathSpliter,checkAuthBranchWare,dateTimeFormat,checkIntNum,currentDateTime,getDateTimeFromISODT, fmtHMSS} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from 'sweetalert';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { Redirect,generatePath  } from 'react-router'
import {useHistory} from 'react-router-dom'


import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {APP_URL,API_URL} from '../../config.json';

import {BrowserRouter as Router,Route,Link} from 'react-router-dom'


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Select from 'react-select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import socketIOClient from "socket.io-client";

import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const PurchaseEntry = ({location,currentRoute,currentRouteSet,authInfo})=>{

    const classes = useStyles();
    const [selectedDate, handleDateChangeSet] = useState(currentDateTime);
    const [saveAction, saveActionSet] = useState(false);
    const [materialStock, materialStockSet] = useState(0);
    const history = useHistory();



    let [purchaseData,setPurchaseData] = useState({
              invoice_no:'',
              emp_id:0,
              created_isodt:currentDateTime,
              material_cost:0,
              labour_cost:0,
              others_cost:0,
              total_cost:0,
              production_id:0,
              actual_time: 0,
              workers: 0,
              total_scrap:0,
            })
    let [shifts,shiftsSet] = useState([{label:'Day Shift',value:'day_shift'},
    {label:'Night Shift',value:'night_shift'}])
    let [selectedShift,selectedShiftSet] = useState(null)
    let [formAction,formActionSet] = useState('create');
    let [employees,employeesSet] = useState([]);
    let [usedCart,usedCartSet] = useState([]);
    let [toolCart, toolCartSet] = useState([]);
    let [productCart,productCartSet] = useState([]);
    let [subTotalProd,subTotalProdSet] = useState(0);
    let [material_cost,material_cost_set] = useState(0);
    let [labour_cost,labour_cost_set] = useState(0);
    let [others_cost,others_cost_set] = useState(0);
    let [totalCost,totalCostSet] = useState(0);
    let [supplier_purchase_invoice_id, supplier_purchase_invoice_id_set] = useState(0);
    //machine cycle time 
    let [cycleTimeId, cycleTimeIdSet] = useState([]);
    let [selectedCycleTime, selectedCycleTimeSet] = useState(null);

    //  useEffect(()=>{
    //   if(cycleTimeId)
    //    cycleTimeListSet(cycleTimeId.cycleTime);
    //  },[productCart])

    let [cartTotal,cartTotalSet] = useState(0);
    let [cartProductTotal,cartProductTotalSet] = useState(0);
   
    let [selectedEmployee,selectedEmployeeSet] = useState(null)
    let [selectedSupplier,selectedSupplierSet] = useState(null)
    let [suppliers,suppliersSet] = useState([]);
    let [supplier_purchase_rate, set_supplier_purchase_rate] = useState(0);
    let [client_delivery, client_delivery_set] = useState(false)
    let [selectedMaterial,selectedMaterialSet] = useState(null)
    let [selectedTemplate, selectedTemplateSet] = useState(null);
    let [selectedProduct,selectedProductSet] = useState(null)

    let [materials,setMaterials] = useState([])
    let [products,setProducts] = useState([])

    let employeeRef = React.useRef(null)
    let purchaseDateRef = React.useRef(null)
    let supplierRef = React.useRef(null)
    let purchaseRateRef = React.useRef(null)
    let purchaseRateProdRef = React.useRef(null)
    let materialRef = React.useRef(null)
    let productRef = React.useRef(null)
    let quantityRef = React.useRef(null)
    let quantityProdRef = React.useRef(null)
    let totalRef = React.useRef(null)
    let purchaseToCartRef = React.useRef(null)
    let productToCartRef = React.useRef(null)
    let productRateRef = React.useRef(null)
    let saveProductionRef = React.useRef(null)
    let [fileData, setFileData] = useState(null);
    let [supplierData,setSupplierData] = useState({
      invoice_no:'',
      pur_emp_id:0,
      pur_created_isodt:currentDateTime,
      pur_supplier_id:0,
      pur_subtotal_amount:0,
      pur_vat_amount:0,
      pur_vat_percent:0,
      pur_discount_amount:0,
      pur_discount_percent:0,
      pur_total_amount:0,
      pur_paid_amount:0,
      pur_due_amount:0,
      pur_transport_cost:0,
      supplier_address:'',
      supplier_mobile_no:'',
      supplier_name:'',
      pur_note:'',
      pur_previous_due:0,
      pur_supplier_type:''
    });
    let [is_display_invoice, is_display_invoice_set] = useState(false);


    let materialCostRef = React.useRef(null)
    let labourCostRef = React.useRef(null)
    let othersCostRef = React.useRef(null)
    let totalCostRef = React.useRef(null)
    let saveRef = React.useRef(null)
   let [totalScrap, setTotalScrap] = useState('');
    const [selectedOption, setSelectedOption] = useState('secs');
    const [divFactor, setDivFactor] = useState(1.0);
    let [selectedSupplierPayMethod,selectedSupplierPayMethodSet] = useState({pay_method_id:'cash',pay_method:'Cash'},{pay_method_id:'bank',pay_method:'Bank'})
    let [selectedSupplierPayAcc,selectedSupplierPayAccSet] = useState(null)
    let [supplierPayAccs,supplierPayAccsSet] = useState([])

    let supplierPayMethods = [{pay_method_id:'cash',pay_method:'cash'},{pay_method_id:'bank',pay_method:'bank'}]

    const handleChange = (e) => {
      if(e.target.value === 'mins')
      {
        setDivFactor(60.0);
      }else{
        setDivFactor(1.0);
      }
      setSelectedOption(e.target.value);
  } 

   useEffect(()=>{
      let total = 0;
      usedCart.map((row,index)=>{
        total += parseFloat(row.scrap_wt);
      });
      setTotalScrap(total);
   },[usedCart])


    const handleFromInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }
    
    const materialHandle = (e)=>{
      const {name,value} = e.target;
      setMaterial({...material,[name]:value}) 

    }

    const productHandle = (e)=>{
      const {name,value} = e.target;
      setProduct({...product,[name]:value}) 
    }

    const handleTotalInput = (e)=>{
      const {name,value} = e.target;
      setPurchaseData({...purchaseData,[name]:value}) 
    }

    let [templateName, setTemplateName] = useState('');
    let [scrapWt, setScrapWt] = useState(0);
    let [is_template, is_template_set] = useState(false);
    let [is_subContract, is_subContract_set] = useState(false);
    let [is_used_material_display, is_used_material_display_set] = useState(true);
   const [purchase_material_names, purchase_material_names_set] = useState([])
   const [selectedTool, selectedToolSet] = useState(null)
   const [tools, setTools] = useState([])
    

    useEffect(()=>{
      let totalBill = (parseFloat(purchaseData.material_cost)+parseFloat(purchaseData.labour_cost)+
      parseFloat(purchaseData.others_cost))
      totalCostSet(totalBill)
    },[handleTotalInput]);

   useEffect(()=>{
    // if(productCart.length >  0)
    // { getMachineCycleTimeIDList();}
    if(productCart.length != 0)
    {getMachineCycleTimeIDList();}
    cycleTimeIdSet([]);
    selectedCycleTimeSet(null);
   },[productCart, selectedOption])

    useEffect(()=>{
      let total = material.material_purchase_rate * material.material_qty;
      cartTotalSet(total);
    },[materialHandle])

    useEffect(()=>{
      let total = product.prod_purchase_rate * product.prod_qty;
      cartProductTotalSet(total);
    },[productHandle])

    let [material,setMaterial] = useState({
      material_id : 0,
      material_name:'',
      material_purchase_rate: 0,
      material_qty: '',
      material_total: 0,
      scrap_wt:'',
      material_fault:0,
      material_rejected:0,
      });

      let [tool, setTool] = useState({
        tool_id:0,
        tool_qty: 0,
        tool_name:'',
      })

      let [toolStock, setToolStock] = useState(0)
      let [product,setProduct] = useState({
        prod_id : 0,
        prod_name:'',
        prod_purchase_rate: 0,
        prod_qty: '',
        prod_total: 0,
        prod_sale_rate:0,
        });
     
    const purchaseToCart = (()=>{
          if(selectedMaterial==null){
            swal({title:'Select  material',icon:'warning'});return false;
          }
          // if(material.material_purchase_rate < 0.1){
          //   swal({title:'Purchase rate is invalid.',icon:'warning'});return false;
          // }
          // if(material.material_qty < 0.1){
          //   swal({title:'Quantity is invalid.',icon:'warning'});return false;
          // }
    
          material.material_total = cartTotal;
          let checkExit =   usedCart.findIndex((ele)=>{
              if(ele.material_id==selectedMaterial.material_id){ 
                return true 
              }else{
                return false
              }
                  
          });
          if(checkExit>-1){
            swal({title:"This material already Exist.",icon:'warning'})
            return false;
          }

          if(material.material_qty > materialStock){
            swal({title:"Stock Unavailable",icon:'warning'})
            return false;
          }
          usedCartSet([...usedCart,material]);
          setMaterial({...material,material_purchase_rate:'',material_id:0,material_name:'',material_qty:'',scrap_wt:'',materila_fault:0,material_rejected:0}) 

          selectedMaterialSet(null);
          materialStockSet(0)
          materialRef.current.focus();
    })

    const purchaseToCartForTool = (()=>{
      if(selectedTool==null){
        swal({title:"Selected tool",icon:'warning'});return false;
      }
    
      let checkExit = toolCart.findIndex((ele)=>{
          if(ele.tool_id==selectedTool.tool_id){ 
            return true 
          }else{
            return false
          }    
      });

      if(checkExit>-1){
        swal({title:"This tool already Exist.",icon:'warning'})
        return false;
      }

      if(tool.tool_qty > toolStock){
        swal({title:"Stock Unavailable",icon:'warning'})
        return false;
      }
      toolCartSet([...toolCart,tool]);
      setTool({...tool,tool_name:'',tool_id:0,tool_qty:''}) 

      selectedToolSet(null);
      setToolStock(0)
})


    const productToCart = (()=>{
      if(selectedProduct==null){
        swal({title:'Select  product',icon:'warning'});return false;
      }
      // if(product.prod_purchase_rate < 1){
      //   swal({title:'Purchase rate is invalid.',icon:'warning'});return false;
      // }
      // if(product.prod_qty < 1){
      //   swal({title:'Quantity is invalid.',icon:'warning'});return false;
      // }

      product.prod_total = cartProductTotal;
      let checkExit =   productCart.findIndex((ele)=>{
          if(ele.prod_id==selectedProduct.prod_id){ 
            return true 
          }else{
            return false
          }
              
      });
      if(checkExit>-1){
        swal({title:"This product already Exist.",icon:'warning'})
        return false;
      }
      productCartSet([...productCart,product]);
      setProduct({...product,prod_purchase_rate:'',prod_id:0,prod_name:'',prod_qty:'',prod_total:''}) 

      selectedProductSet(null);
      productRef.current.focus();
})

    useEffect(()=>{
      let total =  usedCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.material_purchase_rate*curr.material_qty);
    },0)
    material_cost_set(total)
    },[usedCart]) 

    

    useEffect(()=>{
      let total =  productCart.reduce((prev,curr)=>{
        return prev+parseFloat(curr.prod_purchase_rate*curr.prod_qty);
    },0)
    subTotalProdSet(total)
    },[productCart]) 
   



    useEffect(()=>{
      setPurchaseData({...purchaseData,material_cost:material_cost})

    },[material_cost])

    useEffect( ()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
     
      getEmployees();
      getIndividualMaterials();
      getSuppliers();
      if(pathSpliter(location.pathname,3) != undefined){
        formActionSet('update')
         axios.post(`${API_URL}/api/get-production-with-details`,{productionId:parseFloat(pathSpliter(location.pathname,3))},{headers:{'auth-token':authInfo.token}}).then(res=>{
          
          let production = res.data[0];
          let materialCart = res.data[0].materials;
          let productCart = res.data[0].products;
          let toolCart = res.data[0].tools;

            selectedEmployeeSet({
              employee_id: production.employee_id,
              employee_name: production.employee_name
            });

            let shiftName = ``
            if(production.shift=='day_shift'){
              shiftName = `Day Shift`
            }
            if(production.shift=='night_shift'){
              shiftName = `Night Shift`
            }

            selectedShiftSet({
              label:shiftName,
              value:production.shift,
            })
  
            is_template_set(res.data[0].is_template);
            setTemplateName(res.data[0].templateName);
 
            setPurchaseData({...purchaseData,
              invoice_no:production.production_invoice,
              labour_cost:production.labour_cost,
              material_cost:production.material_cost,
              others_cost:production.others_cost,
              total_cost:production.total_cost,
              production_id:production.production_id,
              production_note: production.production_note,
              material_used_note: production.material_used_note,
              shift_id:production.shift,
              emp_id:production.production_by,
              actual_time: production.actual_time,
              workers: production.workers,
              total_scrap: production.total_scrap,
              })

              material_cost_set(production.material_cost)
              labour_cost_set(production.labour_cost)
              others_cost_set(production.others_cost)
              totalCostSet(production.total_cost)
              is_subContract_set(production.is_subContract);
              supplier_purchase_invoice_id_set(production.supplier_purchase_invoice_id);
              set_supplier_purchase_rate(production.supplier_purchase_rate)

              selectedSupplierSet({
                supplier_id: production.supplier_id,
                supplier_name: production.supplier_type =='general'?'General Supplier':production.supplier_name,
                supplier_type: production.supplier_type,
                display_text: production.supplier_name
              })
              set_supplier_purchase_rate(production.supplier_purchase_rate);
              is_display_invoice_set(production.supplier_invoice_display);
              client_delivery_set(production.client_deliver);


              handleDateChangeSet(production.created_isodt)


              // usedCartSet([...usedCart,material]);
              productCartSet([...productCart,product]);
             
           let usedCartGet =  materialCart.map((ele)=>{ 
              let material = {
                material_id:ele.material_id,
                material_name:ele.material_name,
                material_purchase_rate:ele.material_rate,
                material_qty:ele.material_qty,
                material_total:ele.material_total,
                scrapWt: ele.scrapWt,
                material_fault: ele.material_fault,
                material_rejected: ele.material_rejected,
              }
              return material;
            });

            usedCartSet(usedCartGet)


            let productCartGet =  productCart.map((ele)=>{ 
              let product = {
                prod_id:ele.prod_id,
                prod_name:ele.prod_name,
                prod_purchase_rate:ele.prod_rate,
                prod_qty:ele.prod_qty,
                prod_total:ele.prod_total
              }
              return product;
            });

            productCartSet(productCartGet)
      
            let toolCartGet = toolCart.map((ele)=>{
              let tool = {
                tool_id: ele.tool_id,
                tool_name: ele.purchase_material_name,
                tool_qty: ele.tool_production_qty,
              }
              return tool;
            });

            toolCartSet(toolCartGet)
        })

       
      }else{
        getPurchaseInvoice();
      }
      getPurchaseMaterialName();
      getIndividualProducts()
    },[]) 

    // useEffect(()=>{
    //    let produceQty = parseFloat(material.material_qty) - (parseFloat(material.material_fault) + parseFloat( material.material_rejected));
    //    setProduct({...product, prod_qty: produceQty});
    // },[material.material_fault, material.material_rejected, material.material_qty])

       useEffect(()=>{
        console.log(supplier_purchase_invoice_id)
       },[supplier_purchase_invoice_id]);

    let saveProduction = async ()=>{
         
      
        if(usedCart.length==0){
          swal({title:'Sorry... Your Used Material cart is Empty.',icon:'warning'}) 
          return false;
        }
        

        if(productCart.length==0){
          swal({title:'Sorry... Your production product cart is Empty.',icon:'warning'}) 
          return false;
        }
        if(selectedEmployee==null){
          swal({title:'Select Employee',icon:'warning'}) 
          return false;
        }
        if(selectedShift==null){
          swal({title:'Select Shift',icon:'warning'}) 
          return false;
        }
         
        if(is_subContract && selectedSupplier === null ){
          swal({title:'Select Supplier',icon:'warning'}) 
          return false;
        }

        if(is_subContract && supplier_purchase_rate === 0 ){
          swal({title:'Purchase Rate should be greater than 0',icon:'warning'}) 
          return false;
        }

        
       let purchase =  {...purchaseData,
        total_cost:totalCost,
        created_isodt:selectedDate,
        total_scrap: totalScrap,
        }

      
      
       
      
        saveActionSet(true)
        let url = `/save-production`;
        let url2 = `/save-purchase`;
        if(pathSpliter(location.pathname,3) != undefined){
            url =  `/update-production`;
            url2 = `/update-purchase`;
        }
        let supp_invoice_pur_id = 0;
        
          if(is_subContract){
          
          let supplier_total_amount = 0;
          let purchaseCart =  JSON.parse(JSON.stringify(productCart));

          purchaseCart = purchaseCart.map((ele, idx)=>{
            ele.prod_purchase_rate = supplier_purchase_rate;
            ele.prod_total = ele.prod_qty * supplier_purchase_rate;
            supplier_total_amount += parseFloat(ele.prod_total);
            ele.prod_sale_rate = 0;
            return ele;
          });

          
          let supplierInfo = {
            ...supplierData,
            invoice_no: purchaseData.invoice_no,
            pur_emp_id: selectedEmployee.employee_id,
            pur_created_isodt: selectedDate,
            pur_supplier_type :  selectedSupplier.supplier_type == undefined ? 'general':selectedSupplier.supplier_type,
            pur_pay_method : selectedSupplierPayMethod !=null? selectedSupplierPayMethod.pay_method_id:'cash',
            pur_bank_id :   selectedSupplierPayMethod==null || selectedSupplierPayMethod.pay_method_id =='cash' ? 0 :selectedSupplierPayAcc.bank_acc_id,
            supplier_address:selectedSupplier.supplier_address,
            supplier_mobile_no: selectedSupplier.supplier_mobile_no,
            supplier_name:selectedSupplier.supplier_name,
            pur_total_amount: supplier_total_amount,
            pur_subtotal_amount: supplier_total_amount,
            is_subContract,
            pur_id :  supplier_purchase_invoice_id,
            pur_supplier_id: selectedSupplier != null ? selectedSupplier.supplier_id : 0,

            }

          const formData = new FormData();
          formData.append('fileData', fileData);
          formData.append('purchase', JSON.stringify(supplierInfo));
          formData.append('purchaseCart',JSON.stringify(purchaseCart));
          formData.append('toolCart', JSON.stringify(toolCart))
          await axios.post(`${API_URL}/api${url2}`,
          formData,
          {
            headers:{
              'Content-Type': 'multipart/form-data',
              'auth-token':authInfo.token
            },
            // onUploadProgress: progressEvent => {
            //   setProgress(
            //     parseInt(
            //       Math.round((progressEvent.loaded * 100) / progressEvent.total)
            //     )
            //   );
    
            //   // Clear percentage
            //   setTimeout(() => setProgress(0), 10000);
            // }
          }
          ).then(res=>{
            if(!res.data.error){
              let smg = 'Purchase ';
              if(pathSpliter(location.pathname,3) != undefined){
                smg = 'Purchase updated '
              }
              supp_invoice_pur_id = res.data.message.purchaseId;
              supplier_purchase_invoice_id_set(res.data.message.purchaseId);
              swal({
                title:`${smg} successfully.`,
                icon:'success',
                buttons:true
              });
               
            }
            else{
              swal({title:`Your Network problem....${res.data.message}`,icon:'warning'})
            }
          })
        }
        
        let supplierDetails = {
          selectedSupplier,
          supplier_purchase_rate,
          client_delivery,
          is_subContract,
          is_display_invoice,
          supplier_purchase_invoice_id : supp_invoice_pur_id,
         
        }
        await axios.post(`${API_URL}/api${url}`,{purchase,usedCart,productCart,toolCart,templateName,is_template,supplierDetails, is_used_material_display},{headers:{'auth-token':authInfo.token}}).then(res=>{

          if(!res.data.error){
            let smg = 'Production  ';
            if(pathSpliter(location.pathname,3) != undefined){
              smg = 'Production updated '
            }
            swal({
              title:`${smg} successfully. Do you want to view invoice?`,
              icon:'success',
              buttons:true
            }).then((confirm)=>{
              if(confirm){
                history.push(`/production/production-invoice/${res.data.message.productionId}`) 
                saveActionSet(false)
              }else{
                window.location.reload();
              }
            })
          }
          else{
            swal({title:'Your Network problem....',icon:'warning'})
          }
        });

      
    }
  

    
    /// Methods 

    const getPurchaseMaterialName = async ()=>{
      await axios.post(`${API_URL}/api/get-purchase-material-names`,{'select-type':'a'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        purchase_material_names_set(res.data.message);
      })
    }

    const getToolStock = async (id)=>{
      console.log(id)
      await axios.post(`${API_URL}/api/get-tool-stock`,{'select-type':'a',id},{headers:{'auth-token':authInfo.token}}).then(res=>{
        setToolStock(res.data.message[0].stock);
      })
    }

    const getPurchaseInvoice = async ()=>{ 
          await axios.get(`${API_URL}/api/get-production-invoice`,{headers:{'auth-token':authInfo.token}}).then(res=>{
                setPurchaseData({...purchaseData,invoice_no:res.data.message})
          })
    }

    const getSuppliers = async ()=>{
      await axios.post(`${API_URL}/api/get-suppliers-subcontract`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        res.data.message.unshift({supplier_id:0,supplier_address:'',supplier_mobile_no:'',supplier_name:'General Supplier',display_text:'General Supplier'});
        suppliersSet(res.data.message);
        console.log(res.data.message, 'supplier info');
      })
    }
 

    const getIndividualMaterials = async()=>{
          await axios.post(`${API_URL}/api/get-materials`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
            setMaterials(res.data.message);
          })
    }

    
    const getIndividualProducts = async()=>{
      await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        setProducts(res.data.message);
      })
    }

     const getMachineCycleTimeIDList = async()=>{
      await axios.post(`${API_URL}/api/get-machine-cycle-time-product`,{productCart,'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
        cycleTimeIdSet(res.data);
        console.log(res.data, 'MCT in front');
      });
      
     }

    const getEmployees = async ()=>{
      await axios.post(`${API_URL}/api/get-employees`,{'select-type': "active"},{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data.message)
    })
    }

    useEffect(()=>{
      if(selectedMaterial != null && selectedMaterial.material_id != null){
        axios.post(`${API_URL}/api/get-material-current-stock`,{materialId: selectedMaterial.material_id},{headers:{'auth-token':authInfo.token}}).then(res=>{
          materialStockSet(res.data.stock)
      })
      }else{
        materialStockSet(0)
      }
    },[selectedMaterial])

    const  toolCartRemove = (row,index)=>{
      let hardCopy =  [...toolCart];
          hardCopy.splice(index, 1);
          toolCartSet(hardCopy)
    }

   
    const  usedCartRemove = (row,index)=>{
      let hardCopy =  [...usedCart];
          hardCopy.splice(index, 1);
          usedCartSet(hardCopy)
    }

    const  productCartRemove = (row,index)=>{
      let hardCopy =  [...productCart];
          hardCopy.splice(index, 1);
          productCartSet(hardCopy)
    }


    console.log(purchaseData, usedCart, productCart, templateName, "Prod template");

    

      return(
          <div className={classes.root}>
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
    marginBottom: '2px'}}>Production Entry</h4>
<Grid container spacing={3} > 
          <Grid item  xs={12} sm={2}>
            <TextField label="invoice no" variant="outlined"  size="small"  className={classes.fullWidth} value={purchaseData.invoice_no} onChange={handleFromInput} name="invoice_no" disabled />
          </Grid> 
          
          <Grid item xs={12} sm={3}> 
            <FormControlLabel
                control={
                  <Checkbox
                  
                    checked={is_template}
                    onChange={(event)=>{
                      is_template_set(event.target.checked);
                      
                    }}
                    name="checkedI"
                  />
                }
                label="Is Template"
                style={{color: '#0f7e77'}}
            />
            </Grid> 
          {
            is_template && (
              <>
              <Grid item xs={12} sm={3}>
              <TextField style={{marginRight:'5px'}}  
                type="text" label="Template Name"  size="small" value={templateName} onChange={(e)=>setTemplateName(e.target.value)} name="template_name" variant="outlined" className={classes.inputField} />
               </Grid>
               <Grid item xs={12} sm={3}>
               <FormControlLabel
                control={
                  <Checkbox
                  
                    checked={is_used_material_display}
                    onChange={(event)=>{
                      is_used_material_display_set(event.target.checked);
                      
                    }}
                    name="checkedI"
                  />
                }
                label="Display Used Material in Invoice and Template"
                style={{color: '#0f7e77'}}
              />
              </Grid>
              </>
            )
          }

          <Grid item xs={12} sm={3}> 
            <FormControlLabel
                control={
                  <Checkbox
                  
                    checked={is_subContract}
                    onChange={(event)=>{
                      is_subContract_set(event.target.checked); 
                    }}
                    name="checkedI"
                  />
                }
                label="Is SubContract"
                style={{color: '#0f7e77'}}
            />
            </Grid> 
        
               
          <Grid item  xs={12}  sm={3} style={{marginBottom: '-9px'}} > 
            <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
          
            <KeyboardDateTimePicker
            inputRef={purchaseDateRef}
            onKeyDown={(e)=>{
              if(e.key==='Enter'){
                supplierRef.current.focus()
              }
            }}
            style={{ width: '100%',marginTop: '' }}
            value={selectedDate}
            onChange={handleDateChangeSet}
            label="Material Purchase date  time"
            format="yyyy/MM/dd hh:mm a"
               />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
        </Paper>
       <Grid container spacing={3} >
          <Grid item xs={12} sm={9} >
              <Paper className={classes.paper}> 
              <Grid container spacing={3} >
              
              <Grid item xs={12} sm={6} className={classes.plusLinkDiv} style={{border:' 10px solid #e0f7fa',
        padding: '10px',borderRadius: '20px'}}>
                    <Link className={classes.plusLink} to="/production/material-entry" >+</Link>  
                    <p style={{fontWeight: 'bold',margin: '0',padding: '0',marginBottom: '5px',
        marginTop: '-10px',textAlign:'left'}}>Used Material Entry</p>
                  <Autocomplete 
                  autoHighlight
                  openOnFocus={true}
                  value={selectedMaterial}
                  style={{width:'100%',height:'20px'}}
                  options={materials}
                  size="small"
                  onChange={(e,obj)=>{

                    selectedMaterialSet(obj)
                    if(obj==null)return false
                    setMaterial({...material,material_purchase_rate:parseFloat(obj.material_purchase_rate).toFixed(2),material_id:obj.material_id,material_name:obj.material_name}) 
                      quantityRef.current.focus()
                    
                  }}
                  getOptionLabel={(option) => option.material_name}
                  renderInput={(params) => <TextField 
                    
                    inputRef={materialRef}
                    onKeyDown={event => {
                      if (event.key === "Enter") {
                          purchaseRateRef.current.focus()
                      }
                    }} 
                    {...params} 
                    label="choose a material" 
                    variant="outlined"
                    
                />}
                    
              />
            <br/>
                    <Grid container >
                          <Grid item xs={12} sm={5}>
                          <TextField style={{marginRight:'5px'}}  
                                inputRef={purchaseRateRef}
                                onKeyDown={event => {
                                  if (event.key === "Enter") {
                                      quantityRef.current.focus()
                                  }
                                }} disabled  type="number" label="Purchase Avarage Rate"  size="small" value={material.material_purchase_rate} onChange={materialHandle} name="material_purchase_rate" variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="quantity"  type="number"
                            inputRef={quantityRef}
                            onKeyDown={event => {
                              if (event.key === "Enter") {
                                purchaseToCartRef.current.focus()
                              }
                            }}
                            value={material.material_qty}
                          variant="outlined"  size="small"   onChange={materialHandle} name="material_qty" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="total" type="number" disabled value={cartTotal}  onChange={materialHandle} name="material_total"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="Damaged(Fault)" type="number" value={material.material_fault}  onChange={materialHandle} name="material_fault"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="Rejected" type="number"  value={material.material_rejected}  onChange={materialHandle} name="material_rejected"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid>
                        
                          <Grid item xs={12} sm={3} style={{display: 'grid',fontWeight: 'bold',alignContent: 'center',fontSize:'13px'}} >
                            Stock :   {parseFloat(materialStock).toFixed(2)} {selectedMaterial!=null?selectedMaterial.prod_unit_name:'unit name'}
                        </Grid>

                          <Grid item xs={12} sm={5} style={{display: 'grid',fontWeight: 'bold',alignContent: 'center',fontSize:'13px'}} >
                              <Button 
                                  buttonRef={purchaseToCartRef}
                                  onClick={purchaseToCart}

                                  style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<SaveIcon/>}
                                    >
                                  Add  To Cart 
                                  </Button>
                          </Grid>
                          

                    </Grid>

                    

                    <Grid container >                  
                          {/* <Grid item xs={12} sm={4} >
                          <TextField label="Scrap Wt"  type="number"
                    
                            value={material.scrap_wt}
                          variant="outlined"  size="small"   onChange={materialHandle} name="scrap_wt" className={classes.inputField} />
                          </Grid> */}
    
                    </Grid> 
              </Grid> 
                  
                  
                    <Grid item xs={12} sm={6} className={classes.plusLinkDiv} style={{border:' 10px solid #e0f7fa',
        padding: '10px',borderRadius: '20px'}}>
                    <Link className={classes.plusLink} to="/administration/products-manage" >+</Link>  
                    <p style={{fontWeight: 'bold',margin: '0',padding: '0',marginBottom: '5px',
        marginTop: '-10px',textAlign:'left'}}>Production Product Entry</p>
                  <Autocomplete 
                  autoHighlight
                  openOnFocus={true}
                  value={selectedProduct}
                  style={{width:'100%',height:'20px'}}
                  options={products}
                  size="small"
                  onChange={(e,obj)=>{

                    selectedProductSet(obj)
                    if(obj==null)return false
                    setProduct({...product,prod_purchase_rate:parseFloat(obj.prod_purchase_rate).toFixed(2),
                      prod_id:obj.prod_id,prod_name:obj.prod_name}) 
                      quantityProdRef.current.focus()
                    
                  }}
                  getOptionLabel={(option) => option.prod_name}
                  renderInput={(params) => <TextField 
                    
                    inputRef={productRef}
                    onKeyDown={event => {
                      if (event.key === "Enter") {
                          purchaseRateProdRef.current.focus()
                      }
                    }} 
                    {...params} 
                    label="choose a product" 
                    variant="outlined"
                    
                    />}
                    
              />
            <br/>
                    <Grid container >
                          <Grid item xs={12} sm={6}>
                          <TextField style={{marginRight:'5px'}}  
                                inputRef={purchaseRateProdRef}
                                onKeyDown={event => {
                                  if (event.key === "Enter") {
                                    quantityProdRef.current.focus()
                                  }
                                }}  type="number" label="Production Finish rate"  size="small" value={product.prod_purchase_rate} onChange={productHandle} name="prod_purchase_rate" variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={5} >
                          <TextField label="quantity"  type="number"
                            inputRef={quantityProdRef}
                            onKeyDown={event => {
                              if (event.key === "Enter") {
                                productToCartRef.current.focus()
                              }
                            }}
                            value={product.prod_qty}
                          variant="outlined"  size="small"   onChange={productHandle} name="prod_qty" className={classes.inputField} />
                          </Grid>
                    </Grid>


                    <Grid container >
                          
                        
                          <Grid item xs={12} sm={6} >
                          <TextField label="total" type="number" disabled value={cartProductTotal}  onChange={productHandle} name="product_total"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Button 
                          buttonRef={productToCartRef}
                          onClick={productToCart}

                          style={{marginTop: '5px',marginLeft: 'auto',fontSize:'12px'}} 
                                variant="contained"
                                color="primary"
                                size="small"
                                className={classes.button}
                                startIcon={<SaveIcon/>}
                            >
                          Add  To Cart 
                          </Button>
                    </Grid> 
                    </Grid>

              </Grid>
              </Paper> 
              <Grid container>
                
                <Grid item xs={6}  style={{marginTop:'5px',border:' 10px solid #e0f7fa',
        padding: '1px',borderRadius: '20px'}} >
                <Paper className={classes.paper}  >
                      <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
        marginBottom: '3px'}}>Used Materials </h4>
                      <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow >
                <TableCell align="left">SL</TableCell>
                <TableCell align="left">Material</TableCell>
                <TableCell align="center"> Faulty</TableCell>
                <TableCell align="center">Rejected</TableCell>
                <TableCell align="center"> Rate</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="center">In Production</TableCell>
                {/* <TableCell align="center">Scrap</TableCell> */}
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { 
              usedCart.map((row,index) => ( 
                <TableRow key={row.material_id}>
                  <TableCell  align="left">{parseFloat(index)+1}</TableCell>
                  <TableCell align="left">{row.material_name}</TableCell> 
                  <TableCell align="center">{row.material_fault}</TableCell>
                  <TableCell align="center">{row.material_rejected}</TableCell>
                  <TableCell align="center">{format(parseFloat(row.material_purchase_rate).toFixed(2))}</TableCell> 
                  <TableCell align="center">{row.material_qty}</TableCell>
                  <TableCell align="center">{parseFloat(row.material_qty) - (parseFloat(row.material_fault) + parseFloat(row.material_rejected))}</TableCell>
                  {/* <TableCell align="center">{row.scrap_wt}</TableCell> */}
                  <TableCell align="right">{format(parseFloat(row.material_total).toFixed(2))}</TableCell>
                  <TableCell align="right" >
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{usedCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
                </TableRow>
              ))}

            {usedCart.length!=0?
            <TableRow colSpan={4}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{fontWeight:'bold'}} align="right">Total : {format(parseFloat(material_cost).toFixed(2))}</TableCell>
            <TableCell></TableCell>
            </TableRow>
            :''}


            </TableBody>
          </Table>
        </TableContainer>
         

        <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
          {usedCart.length!=0?
              <TableRow >
                <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="material_used_note" value={purchaseData.material_used_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Material Used Note..." rowsMin={3} placeholder="Material Used Note..." />
                </TableCell>

                <TableCell colSpan={2}></TableCell>

              
              
              </TableRow>
              :''}
          </Table>
        </TableContainer>
               </Paper>


        {/* tools feature */}
        <br /> 
        <Paper className={classes.paper}>
        <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                            marginBottom: '3px'}}>Tools Entry</h4>
             <Grid item xs={12} sm={12} className={classes.plusLinkDiv}>
             
                  <Autocomplete 
                  autoHighlight
                  openOnFocus={true}
                  value={selectedTool}
                  style={{width:'100%',height:'20px'}}
                  options={purchase_material_names}
                  size="small"
                  loading={purchase_material_names.length==0?true:false}
                  onChange={(e,obj)=>{

                    selectedToolSet(obj)
                    if(obj==null)return false
                    getToolStock(obj.purchase_material_id)
                    setTool({...tool,tool_id:obj.purchase_material_id,tool_name:obj.purchase_material_name})                                                                                                                   
                    
                  }}
                  getOptionLabel={(option) => option.purchase_material_name}
                  renderInput={(params) => <TextField 
                    
                    inputRef={materialRef}
                    onKeyDown={event => {
                      if (event.key === "Enter") {
                          purchaseRateRef.current.focus()
                      }
                    }} 
                    {...params} 
                    label="choose a tools" 
                    variant="outlined"
                    
                />}
                    
              />
            <br/>
                    <Grid container >
                          {/* <Grid item xs={12} sm={5}>
                          <TextField style={{marginRight:'5px'}}  
                                inputRef={purchaseRateRef}
                                onKeyDown={event => {
                                  if (event.key === "Enter") {
                                      quantityRef.current.focus()
                                  }
                                }} disabled  type="number" label="Purchase Avarage Rate"  size="small" value={material.material_purchase_rate} onChange={materialHandle} name="material_purchase_rate" variant="outlined" className={classes.inputField} />
                          </Grid> */}
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="quantity"  type="number"
                            inputRef={quantityRef}
                            onKeyDown={event => {
                              if (event.key === "Enter") {
                                purchaseToCartRef.current.focus()
                              }
                            }}
                            value={tool.tool_qty}
                          variant="outlined"  size="small"   onChange={(e)=>{setTool({...tool, tool_qty: e.target.value})}} name="tool_qty" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          {/* <Grid item xs={12} sm={4} >
                          <TextField label="total" type="number" disabled value={cartTotal}  onChange={materialHandle} name="material_total"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid> */}
                          {/* <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="Damaged(Fault)" type="number" value={material.material_fault}  onChange={materialHandle} name="material_fault"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid>
                          <Grid item sm={1} >
                          </Grid>
                          <Grid item xs={12} sm={4} >
                          <TextField label="Rejected" type="number"  value={material.material_rejected}  onChange={materialHandle} name="material_rejected"  size="small"  variant="outlined" className={classes.inputField} />
                          </Grid> */}
                        
                          <Grid item xs={12} sm={3} style={{display: 'grid',fontWeight: 'bold',alignContent: 'center',fontSize:'13px'}} >
                            Stock :   {parseFloat(toolStock).toFixed(2)}
                          </Grid>

                          <Grid item xs={12} sm={5} style={{display: 'grid',fontWeight: 'bold',alignContent: 'center',fontSize:'13px'}} >
                              <Button 
                                  buttonRef={purchaseToCartRef}
                                  onClick={purchaseToCartForTool}

                                  style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<SaveIcon/>}
                                    >
                                  Add  To Cart 
                                  </Button>
                          </Grid>
                    </Grid>
                </Grid>
                </Paper>
                  <br />
                  <Paper className={classes.paper}  >
                      <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
        marginBottom: '3px'}}>Used Tools </h4>
                      <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow >
                <TableCell align="left">SL</TableCell>
                <TableCell align="left">Tool</TableCell>
                <TableCell align="center"> Qty</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { 
              toolCart.map((row,index) => ( 
                <TableRow key={row.tool_id}>
                  <TableCell  align="left">{parseFloat(index)+1}</TableCell>
                  <TableCell align="left">{row.tool_name}</TableCell> 
                  <TableCell align="center">{row.tool_qty}</TableCell>
                  <TableCell align="right" >
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{toolCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
                </TableRow>
              ))}

            {/* {toolCart.length!=0?
            <TableRow colSpan={3}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{fontWeight:'bold'}} align="right">Total : {format(parseFloat(material_cost).toFixed(2))}</TableCell>
            <TableCell></TableCell>
            </TableRow>
            :''} */}


            </TableBody>
          </Table>
        </TableContainer>
         

        <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
          {usedCart.length!=0?
              <TableRow >
                <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="material_used_note" value={purchaseData.material_used_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Material Used Note..." rowsMin={3} placeholder="Material Used Note..." />
                </TableCell>

                <TableCell colSpan={2}></TableCell>

              
              
              </TableRow>
              :''}
          </Table>
        </TableContainer>
               </Paper> 
           

        {/* tools feature end */}


          {/* subcontract feature */}
            <br /> 
          { is_subContract && ( <Paper className={classes.paper} >
                            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
                            marginBottom: '3px'}}>SubContract Entry</h4>
                <Grid item xs={12} sm={12} className={classes.plusLinkDiv}>
                    <Link className={classes.plusLink} to="/purchase/suppliers-manage" >+</Link>                 
                    <Autocomplete 
                    autoHighlight
                    size="small"
                  openOnFocus={true}
                  value={selectedSupplier}
                  style={{width:'100%'}}
                  options={suppliers} 
                  onChange={async (event,selectedObj)=>{
                    if(selectedObj==null) return false;

                    await axios.post(`${API_URL}/api/get-supplier-due`,{supplierId:selectedObj.supplier_id, supplierFor:'Purchase'},{headers:{'auth-token':authInfo.token}}).then(res=>{
                      if(purchaseData.pur_supplier_id == 0){
                        setSupplierData({...supplierData,pur_previous_due:0,pur_supplier_type:'general'}) 
                        return false;
                      }

                      setSupplierData({...supplierData,pur_previous_due:res.data[0].dueAmount,pur_supplier_type:'general'}) 
                    })

                    selectedSupplierSet(selectedObj)
                  }}
                  getOptionLabel={(option) => option.display_text}
                  renderInput={(params) => <TextField 
                    {...params} 
                    label="Choose a Supplier " 
                    variant="outlined"
                    />}
                />
                    <Grid item xs={1} />
                    <TextField type="number" label="Purchase Rate"  size="small"  variant="outlined" name="supplier_purchase_rate" className={classes.inputField} value={supplier_purchase_rate} onChange={(e)=>{set_supplier_purchase_rate(e.target.value); setSupplierData({...supplierData, pur_total_amount: e.target.value})}} />

                              <FormControlLabel
                                control={
                                  <Checkbox
                                  
                                    checked={client_delivery}
                                    onChange={(event)=>{
                                      client_delivery_set(event.target.checked); 
                                    }}
                                    name="checkedI"
                                  />
                                }
                                label="Delivered to Client"
                                style={{color: '#0f7e77'}}
                            />
                        <FormControlLabel
                            control={
                              <Checkbox
                                checked={is_display_invoice}
                                onChange={(event)=>{
                                  is_display_invoice_set(event.target.checked);
                                  
                                }}
                                name="checkedI"
                              />
                            }
                            label="Display in Invoice"
                            style={{color: '#0f7e77'}}
                        />
                    </Grid>
          </Paper>
          )}


        </Grid>

              
              
                {/*  */}

    
                <Grid item xs={6}  style={{marginTop:'5px',border:' 10px solid #e0f7fa',
        padding: '1px',borderRadius: '20px'}} >
                      <Paper className={classes.paper} >
                      <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
        marginBottom: '3px'}}>Production Products</h4>
                      <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">SL</TableCell>
                <TableCell align="left">Product</TableCell>
                <TableCell align="center">Rate</TableCell>
                <TableCell align="center">Qty</TableCell>
                <TableCell align="right">Total</TableCell>
                <TableCell align="right">Remove</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { 
              productCart.map((row,index) => ( 
                <TableRow key={row.prod_id}>
                  <TableCell  align="left">{parseFloat(index)+1}</TableCell>
                  <TableCell align="left">{row.prod_name}</TableCell> 
                  <TableCell align="center">{format(parseFloat(row.prod_purchase_rate).toFixed(2))}</TableCell> 
                  <TableCell align="center">{row.prod_qty}</TableCell>
                  <TableCell align="right">{format(parseFloat(row.prod_total).toFixed(2))}</TableCell>
                  <TableCell align="right" >
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#FF0202',marginLeft:'2px'}} onClick={()=>{productCartRemove(row,index)}}></ RemoveCircleIcon></TableCell>
                </TableRow>
              ))}

            {productCart.length!=0?
            <TableRow colSpan={4}>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell style={{fontWeight:'bold'}} align="right">Total : {format(parseFloat(subTotalProd).toFixed(2))}</TableCell>
            <TableCell></TableCell>
            </TableRow>
            :''}


            </TableBody>
          </Table>
        </TableContainer>


        <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
          {productCart.length!=0?
              <TableRow >
                <TableCell style={{width: '325px'}}><TextareaAutosize onChange={handleTotalInput} name="production_note" value={purchaseData.production_note} style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Product Production  Note..." rowsMin={3} placeholder="Product Production  Note..." />
                </TableCell>

                <TableCell colSpan={2}></TableCell>

              
              
              </TableRow>
              :''}
          </Table>
        </TableContainer>

              
                      </Paper>


                {/** */}     
    {/* machine cycle time table */}
    <br />
    <Paper className={classes.paper} >
                      <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
        marginBottom: '3px'}}>Machine Cycle Time</h4>

    <Grid item  xs={12}  sm={12}  style={{marginBottom:'25px',marginTop:'4px'}}> 
              <Autocomplete 
              size="small"

              autoHighlight
                  openOnFocus={true}
                  style={{width:'100%',height:'20px'}}
                  options={cycleTimeId}
                  value={selectedCycleTime}
                  getOptionLabel={(option) => option.prod_name}
                  onChange={(event,selectedObj)=>{
                    selectedCycleTimeSet(selectedObj);
                    
                  }} 
                  renderInput={(params) => <TextField 
                  
                  
                    
                    {...params} 
                    label="Select Machine Cycle Time " 
                    variant="outlined"
                  
                    />} 
                    
              />

              </Grid>
              
              {/* <Grid item xs={12} sm={12}>
                          <p>Time in 
                            <label>
                          <input type="radio" value="mins" checked={selectedOption === 'mins'} onChange={handleChange} />
                              Minutes
                            </label>
                            <label>
                            <input type="radio" value="secs" checked={selectedOption === 'secs'} onChange={handleChange} />
                              Seconds
                            </label>
                            </p>
              </Grid>   */}


    <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">SL</TableCell>
                <TableCell align="center">Ideal Load Time</TableCell>
                <TableCell align="center">Ideal Unload Time</TableCell>
                <TableCell align="center">Ideal Process Time</TableCell>
                <TableCell align="center">Ideal Total Time</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              selectedCycleTime != null  && selectedCycleTime.cycleTime.length > 0 ?  
              selectedCycleTime.cycleTime.map((data,index)=>(
                
              <TableRow key={data.machine_cycle_prod_id}>
              <TableCell  align="left">{ parseFloat(index)+1}</TableCell>
              <TableCell align="center">{fmtHMSS(((parseFloat(data.total_load_time)*parseFloat(selectedCycleTime.prod_qty) / (  parseFloat(divFactor))).toFixed(3)).toString())}</TableCell> 
              <TableCell align="center">{fmtHMSS(((parseFloat(data.total_unload_time)*parseFloat(selectedCycleTime.prod_qty) / (parseFloat(divFactor))).toFixed(3)).toString())}</TableCell> 
              <TableCell align="center">{fmtHMSS(((parseFloat(data.total_process_time)*parseFloat(selectedCycleTime.prod_qty) / (  parseFloat(divFactor))).toFixed(3)).toString())}</TableCell> 
              <TableCell align="center">{fmtHMSS(((parseFloat(data.total_time)*parseFloat(selectedCycleTime.prod_qty) / (  parseFloat(divFactor))).toFixed(3)).toString())}</TableCell> 
              </TableRow>
              ))
                
                :
                <></>
            }
            </TableBody>
          </Table>
        </TableContainer>

        </Paper>
        

        {/* <br />
    <Paper className={classes.paper} >
                      <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
        marginBottom: '3px'}}>Machine Cycle Time Details</h4>
    <TableContainer >
          <Table className={classes.table} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell align="left">SL</TableCell>
                <TableRow>
                <TableCell align="center">Machine Name</TableCell>
                <TableCell align="center">Step Name</TableCell>
                </TableRow>
              </TableRow>
            </TableHead>
            <TableBody>
            {
              selectedCycleTime != null  && selectedCycleTime.cycleTime.length > 0 ?  
              selectedCycleTime.cycleTime.map((data,index)=>(

              <TableRow key={data.machine_cycle_prod_id}>
              <TableCell  align="left">{parseFloat(index)+1}</TableCell>
                <TableRow>
                  <TableCell></TableCell>
                </TableRow>
              </TableRow>
              ))
                
                :
                <></>
            }
            </TableBody>
          </Table>
        </TableContainer>

        </Paper> */}
        

        {/**machine cycle time table ends */}

                </Grid>
              
              </Grid>
          </Grid>
          <Grid item xs={12} sm={3} >
          
            <Paper className={classes.paper}>
            <h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
      marginBottom: '3px'}}>Production & Amount Details</h4>


            <Grid container style={{paddingRight:'1px'}}>

            <Grid item  xs={12}  sm={12}  style={{marginBottom:'25px',marginTop:'4px'}}> 
            <Autocomplete 
            size="small"
                
            autoHighlight
                openOnFocus={true}
                style={{width:'100%',height:'20px'}}
                options={employees}
                value={selectedEmployee}
                getOptionLabel={(option) => option.employee_name}
                onChange={(event,selectedObj)=>{
                  purchaseData.emp_id = selectedObj!=null?selectedObj.employee_id:0
                  selectedEmployeeSet(selectedObj)
                }}
                renderInput={(params) => <TextField 
                
                  inputRef={employeeRef}
                  onKeyDown={event => {
                    if (event.key === "Enter") {
                        purchaseDateRef.current.focus()
                    }
                  }} 
                
                  {...params} 
                  label="Production / Incharge By " 
                  variant="outlined"
                
                  />} 
                  
            />

            </Grid>

                  <Grid item  xs={12}   sm={12} > 
                        <Select
                        
                        value={selectedShift}
                        onChange={(shift)=>{
                            if(shift == null){
                                return false;
                            }
                            selectedShiftSet(shift);
                            purchaseData.shift_id = shift.value
                        }}
                        options={shifts}
                      
                    />
          </Grid>
                      



                      
                        
                      
                      
            </Grid>

          
            <Grid container style={{paddingRight:'1px'}}>
                      
                        <Grid item xs={12} sm={12} >
                        <TextField type="number" onChange={handleTotalInput} label="Material Cost"  size="small"  variant="outlined" className={classes.inputField} 
                        disabled
                        value={purchaseData.material_cost}
                        name="material_cost"
                        inputRef={materialCostRef} />
                        </Grid>    
                          
            </Grid>
            <Grid container style={{paddingRight:'1px'}}>
                      
                        <Grid item xs={12} sm={12} >
                        <TextField type="number" onChange={handleTotalInput}  label="Labour Cost"  size="small"  variant="outlined" className={classes.inputField} 
                      
                        value={purchaseData.labour_cost}
                        name="labour_cost"
                        inputRef={labourCostRef} />
                        </Grid>    
                          
            </Grid>

            <Grid container style={{paddingRight:'1px'}}>
                      
                        <Grid item xs={12} sm={12} >
                        <TextField type="number" onChange={handleTotalInput}  label="Others Cost"  size="small"  variant="outlined" className={classes.inputField} 
                        value={purchaseData.others_cost}
                        name="others_cost"
                        inputRef={othersCostRef} />
                        </Grid>    
                          
            </Grid>


            <Grid container style={{paddingRight:'1px'}}>
            <Grid item xs={12} sm={12}>
                        <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Total" disabled variant="outlined" className={classes.inputField} 
                        value={totalCost}
                        onChange={handleTotalInput}
                        name="totalCost"
                        inputRef={totalCostRef} />
                        </Grid>
                        <Grid item xs={0} sm={1}>
                        </Grid>


            </Grid>

            {/* <Grid container style={{paddingRight:'1px'}}>
            <Grid item xs={12} sm={12}>
                        <TextField type="number" style={{marginRight:'5px'}}  size="small"  label="Total Scrap" disabled variant="outlined" className={classes.inputField} 
                        value={totalScrap}
                        onChange={handleTotalInput}
                        name="totalScrap"
                        />
                        </Grid>
                        <Grid item xs={0} sm={1}>
                        </Grid>
            </Grid> */}

            <Grid container style={{paddingRight:'1px'}}>
                      
                        <Grid item xs={12} sm={12} >
                        <TextField type="number" onChange={handleTotalInput}  label="Actual Time(in sec)"  size="small"  variant="outlined" className={classes.inputField} 
                        value={purchaseData.actual_time}
                        name="actual_time"
                        />
                        </Grid>    
                          
            </Grid>

            <Grid container style={{paddingRight:'1px'}}>
                      
                        <Grid item xs={12} sm={12} >
                        <TextField type="number" onChange={handleTotalInput}  label="No. of workers"  size="small"  variant="outlined" className={classes.inputField} 
                        value={purchaseData.workers}
                        name="workers"
                        />
                        </Grid>    
                          
            </Grid>


        
          




          

            <Grid container style={{paddingRight:'1px'}}>
                        <Grid item xs={12} sm={8}>
                        <Button style={{marginTop: '5px',fontSize:'14px',float:'left'}} 
                              variant="contained"
                              color="primary"
                              size="small"
                              buttonRef={saveRef} 
                              onClick={saveProduction} 
                              className={classes.button}
                              startIcon={<SaveIcon/>}
                              disabled={saveAction?true:false}
                          >
                        Production
                        </Button> 
                        </Grid>
                    
                        <Grid item xs={12} sm={4} >
                        <Button 
                        style={{marginTop: '5px',fontSize:'10px',float:'right'}} 
                              variant="contained"
                              color="primary"
                              size="small"
                              onClick={()=>window.location.reload()}
                              className={classes.button}
                              startIcon={<SaveIcon/>}
                          >
                          New
                        </Button>
                        </Grid>    
            </Grid>


            </Paper>
          </Grid>  
      </Grid>


    
          </div>
      )
}


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(PurchaseEntry);