import React,{useEffect,useState} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import {pathSpliter} from '../../../lib/functions';
import MCTPurchaseInvoice from './load_machine_cycle_time_invoice';
import {API_URL} from '../../../config.json';
import axios from 'axios';
const MCTInvoiceQuick = ({location,currentRouteSet,authInfo})=>{
    
    console.log(parseFloat(pathSpliter(location.pathname,3)),'pathspliter');

    let [institution,institutionSet] = useState(null);

    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getInstitution();
    },[]);

    let getInstitution = async ()=>{
      await  axios.get(`${API_URL}/api/get-institution`,{headers:{'auth-token':authInfo.token}}).then(res=>{
          institutionSet(res.data)
   })
  }
      return(
          <>
              <MCTPurchaseInvoice purchaseId={parseFloat(pathSpliter(location.pathname,3))} institution={institution} />
          </>
      )
}
  const mapStateToPops = (state)=>{
    return {
      currentRoute:state.currentRouteReducer,
      authInfo:state.authInfoReducer
    }
}

export default connect(mapStateToPops,{currentRouteSet})(MCTInvoiceQuick);