import React, { useState,useEffect } from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
import {API_URL} from '../../../config.json';
import axios from 'axios';
import TableGenerator from './TableGenerator';
let format = commaNumber.bindWith(',', '.')
const InvoiceBody = ({purchaseData, authInfo})=>{
    const classes = useStyles();
    // Invoice body
  
        /// end 

        // console.log(purchase)
         let [purchase,purchaseSet] = useState([])
          let [materials,materialsSet] = useState([])
          let [products,productsSet] = useState([]);
          let [tools, toolsSet] = useState([])
          let [is_used_material_display, is_used_material_display_set] = useState(1);
 
        useEffect( ()=>{
            purchaseSet(purchaseData)
            if(purchaseData.materials!=undefined && purchaseData.products!=undefined){
              materialsSet(purchaseData.materials)
              productsSet(purchaseData.products)
              toolsSet(purchaseData.tools)
              is_used_material_display_set(purchaseData.is_used_material_display);
            }
             
            },[purchaseData]);

            
         

      return(
          <>
        <table className={'invoice-table'}  style={{width:'49%',float:'left'}}> 
        <thead>
              
              <td>SL</td>
              <td style={{textAlign:'left'}}>Product</td>
              <td>Qty</td>
              <td>Production Rate</td>
              <td>Total</td>
           
        </thead>
        <tbody>
      

          {
            products.map((detail,ind) =>(
                 <tr>
                     <td>{ind+parseFloat(1)}</td>
                     <td style={{textAlign:'left'}}>{detail.prod_name}</td>
                     <td>{format(parseFloat(detail.prod_qty))} {detail.prod_unit_name}</td>
                     <td>{format(parseFloat(detail.prod_rate).toFixed(2))}</td>
                     <td style={{textAlign:'right'}}>{format(parseFloat(detail.prod_total).toFixed(2))}</td>
                 </tr>
             ))
          } 
            

            
        </tbody>
        </table>
        <br /> <br/>

       {
         is_used_material_display ?  (
          <table className={'invoice-table'} style={{width:'49%',float:'right',marginLeft:'1%'}}> 
     
          <thead>
              
              <td>SL</td>
              <td style={{textAlign:'left'}}>Material</td>
              <td>Qty</td>
              <td>Purchase Rate</td>
              <td>Fault</td>
              <td>Rejected</td>
              <td>Total</td>
           
        </thead>
        <tbody>
      

          {
            materials.map((detail,ind) =>(
                 <tr>
                     <td>{ind+parseFloat(1)}</td>
                     <td style={{textAlign:'left'}}>{detail.material_name}</td>
                     <td>{format(parseFloat(detail.material_qty))} {detail.prod_unit_name}</td>
                     <td>{format(parseFloat(detail.material_rate).toFixed(2))}</td>
                     <td>{format(parseFloat(detail.material_fault).toFixed(2))}</td>
                     <td>{format(parseFloat(detail.material_rejected).toFixed(2))}</td>
                     <td style={{textAlign:'right'}}>{format(parseFloat(detail.material_total).toFixed(2))}</td>
                 </tr>
             ))
          } 
            

            
        </tbody>
        </table>
         )
         : (
          <>
          </>
         )
       }

        <br />
        <table className={'invoice-table'}  style={{width:'49%',float:'left'}}>
            <thead>
              <td>SL</td>
              <td style={{textAlign:'left'}}>Tools</td>
              <td>Qty</td>
            </thead>
            <tbody>
              {
                tools && tools.map((ele, ind)=>(
                  <tr>
                    <td>{ind+parseFloat(1)}</td>
                    <td>{ele.purchase_material_name}</td>
                    <td>{format(parseFloat(ele.tool_production_qty))}</td>
                  </tr>
                ))
              }
            </tbody>
        </table>
       


          </>
      )
}


const useStyles = makeStyles((theme) => ({
    
   
  }));
  const mapStateToPops = (state)=>{
    return {
    
    }
}

// export default InvoiceBody;

export default connect(mapStateToPops,{currentRouteSet})(InvoiceBody);